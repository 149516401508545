<template>
  <div>
    <br>
    <strong>Endpoints:</strong>
    <div v-if="this.selectedServer.endpointType === 'Gruppe'" style="display: flex; justify-content: space-between; align-items: center; padding: 2px;">
      <span style="text-align: left; width: 212px;">
        <span v-html="'Endpoints'"></span>&nbsp;
      </span>
      <a-input
        type="number"
        :class="['inputNumberField']"
        v-model:value="Endpoints"
        :min="1"
        :max="9999"
      ></a-input>
    </div>
    <div v-if="this.selectedServer.endpointType === 'Einzeln'" style="display: flex; justify-content: space-between; align-items: center; padding: 2px;">
      <span style="text-align: left; width: 212px;">
        <span v-html="'Endpoints'"></span>&nbsp;
      </span>
      1
    </div>
    <div>
      <div style="display: flex; justify-content: space-between; align-items: center; padding: 2px;">
        <span style="text-align: left; width: 212px; display: flex; flex-direction: row;">
          <span v-html="'Backup-Speicher in GB'"></span>&nbsp;
          <div style="display: flex; flex-direction: row; justify-content: center;">
            <a-popover :title="'Backup-Speicher:'">
              <template #content>
                <span>{{'Der Backup-Speicher bezieht sich auf den gewünschten Speicher für'}}</span><br>
                <span>{{'alle darüber ausgewählten Endpoints.'}}</span>
              </template>
              <ExclamationCircleOutlined />
            </a-popover>
          </div>
        </span>
        <a-input
          type="number"
          :class="['inputNumberField']"
          v-model:value="backupStorage"
          :min="0"
          :max="99999"
        ></a-input>
      </div>
    </div>
  </div>
</template>
  
  <script>
  import { mapGetters, mapMutations } from "vuex";
  import ConfigFormField from "./ConfigFormField.vue";
  import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
  
  
  export default {
    name: "CyberCloudOsList",
    props:{
      serverType: {
        type: String,
        required: true,
      }
    },
    components: {
      ConfigFormField,
      ExclamationCircleOutlined
    },
    data() {
      return {
        storageType: false,
      };
    },
    computed: {
      ...mapGetters(["selectedServer"]),
      priceList() {
        return this.$store.getters.getPriceList
      },
      Endpoints: {
        get() {
            return this.selectedServer.configuration["Cyber_Cloud_Endpoint"]
        },
        set(value) {
            if (value > 999) {
                this.updateConfig("Cyber_Cloud_Endpoint", 999);
            } else if (value < 1) {
                this.updateConfig("Cyber_Cloud_Endpoint", 1);
            } else {
                this.updateConfig("Cyber_Cloud_Endpoint",  parseInt(value));
            }
            
            
        }
      },
      backupStorage: {
        get() {
            return this.selectedServer.configuration["AcronisRBS"]
        },
        set(value) {
            if (value > 99999) {
                this.updateConfig("AcronisRBS", 99999);
            } else {
                this.updateConfig("AcronisRBS",  parseInt(value));
            }
            
        }
      },
    },
    methods: {
      ...mapMutations(["setSelectedConfiguration"]),
      updateConfig(attribute, value) {
        let server = this.selectedServer;
        server.configuration[attribute] = value;
        this.setSelectedConfiguration(server.configuration);
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .inputNumberField {
    width: 60px;
  }
  /* .ant-radio-group {
    width: 100%;
    border-radius: 50px;
    background-color: rgb(233, 231, 231);
    margin-bottom: 5px;
  } */
  .ant-radio-button-wrapper{
    width: 100%;
    border-radius: 50px;
    background-color: rgb(233, 231, 231);
    margin-bottom: 5px;
  }
  .ant-radio-button-wrapper:hover{
    background-color: rgb(207, 205, 205);
  }
  .ant-radio-button {
    width: 100%;
    background-color: rgb(233, 231, 231);
    margin-bottom: 5px;
  }
  .clickable {
    cursor: pointer;
  
    &:hover {
      color: #d02c2a;
    }
  }
  .os-buttons {
    width: 100%;
    .btn {
      border-radius: 50rem;
      width: 100%;
      margin: 0.25rem 0 !important;
      text-align: left;
      &.active {
        z-index: 0;
      }
      &,
      & > div {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
  }
  
  .os-field {
    background-color: #fff;
    color: #000;
    margin-bottom: 10px;
  }
  </style>
  