<template>
    <div>
        <div  style="font-size: 16px; text-align: left; color:white;" v-if="$store.getters.getImportedConfigName">
        <div style="font-weight: bold">{{ 'Angebot in Bearbeitung: ' }}</div>
        <div>Angebot: {{ $store.getters.getImportedConfigName }}</div>
        <div v-if="$store.getters.getImportedConfigNumber">Angebotsnummer: {{ $store.getters.getImportedConfigNumber }}</div>
        <div v-else>Angebotsnummer: -</div>
        </div>
        <div class="kdz-sidebar-head px-2 py-4">
        <div>Kosten&shy;übersicht</div>
        <div style="display: flex; align-self: top; height: 100%;">
            <a-button
            
            @click="this.$emit('openCancelConfigModal')"
            class="exit-button"
            size="small"
            v-if="!isRoot"
            type="primary"
            >
            X
            </a-button>
        </div>
        </div>
        <div class="kdz-cost-list">
        <!-- loop over all 5 servertypes -->
        <template v-for="(type, indexServerTypes) in serverTypes" >
            <div v-if="checkServerType(type) || (type=='iaas' && ($store.getters.firewall || $store.getters.ip > 0)) || (type=='wpCloud' && $store.getters.firewall2)" class="serverTypeHeader">{{ serverTypeNames[serverTypes[indexServerTypes]] }}</div>
            <!-- special cases regarding ips and firewall for iaas -->
            <template v-if="type== 'iaas'">
            <!-- iaas firewall active and ips selected -->
                <template v-if="$store.getters.firewall && $store.getters.ip > 0">
                <div
                style="display: flex; justify-content: space-between; background-color: inherit;"
                :class="['kdz-last-element', 'kdz-cost-border']"
                >
                    <a-popover placement="left" :title="bill[0].bill[0].hoverLabel + ' = ' + bill[0].bill[0].singlePrice.toString().replace('.' , ',') + '€ '" v-if="this.windowWidth>790" >
                    <template #content>
                        <span>{{bill[0].bill[0].UsageForm + 'Netto' + (bill[0].bill[0].discount > 0 ? ', inkl. ' + bill[0].bill[0].discount + '% Rabatt)' : ')')}}</span>
                    </template>
                    <span class="hoverLabel">{{ bill[0].bill[0].label }}</span>
                    </a-popover>
                    <a-popover placement="top" :title="bill[0].bill[0].hoverLabel + ' = ' + bill[0].bill[0].singlePrice.toString().replace('.' , ',') + '€ '" v-if="this.windowWidth<=790" >
                    <template #content>
                        <span>{{bill[0].bill[0].UsageForm + 'Netto' + (bill[0].bill[0].discount > 0 ? ', inkl. ' + bill[0].bill[0].discount + '% Rabatt)' : ')')}}</span>
                    </template>
                    <span class="hoverLabel">{{ bill[0].bill[0].label }}</span>
                    </a-popover>
                    <span style="width: 30%; display: flex; justify-content: right;" cols="5" class="text-right" align-self="center">
                    <i>{{ bill[0].bill[0].price.toString().replace('.' , ',') }}</i> &euro;&nbsp;{{ '(' + bill[0].bill[0].discount + '%)' }}
                    </span>
                </div>
                <div
                style="display: flex; justify-content: space-between; background-color: inherit;"
                :class="['kdz-last-element', 'kdz-cost-border']"
                >
                    <a-popover placement="left" :title="bill[1].bill[0].hoverLabel + ' = ' + ($store.getters.firewall2 ? (bill[1].bill[0].singlePrice / 2).toFixed(3).toString().replace('.' , ',') : bill[1].bill[0].singlePrice.toString().replace('.' , ',')) + '€ '" v-if="this.windowWidth>790" >
                    <template #content>
                        <span>{{bill[1].bill[0].UsageForm + 'Netto' + (bill[1].bill[0].discount > 0 ? ', inkl. ' + bill[1].bill[0].discount + '% Rabatt)' : ')')}}</span>
                    </template>
                    <span class="hoverLabel">{{ bill[1].bill[0].label.replace('x2' , 'x1') }}</span>
                    </a-popover>
                    <a-popover placement="top" :title="bill[1].bill[0].hoverLabel + ' = ' + ($store.getters.firewall2 ? (bill[1].bill[0].singlePrice / 2).toFixed(3).toString().replace('.' , ',') : bill[1].bill[0].singlePrice.toString().replace('.' , ',')) + '€ '" v-if="this.windowWidth<=790" >
                    <template #content>
                        <span>{{bill[1].bill[0].UsageForm + 'Netto' + (bill[1].bill[0].discount > 0 ? ', inkl. ' + bill[1].bill[0].discount + '% Rabatt)' : ')')}}</span>
                    </template>
                    <span class="hoverLabel">{{ bill[1].bill[0].label.replace('x2' , 'x1') }}</span>
                    </a-popover>
                    <span style="width: 30%; display: flex; justify-content: right;" cols="5" class="text-right" align-self="center">
                    <i>{{ $store.getters.firewall2 ? (bill[1].bill[0].price / 2).toFixed(3).toString().replace('.' , ',') : bill[1].bill[0].price.toString().replace('.' , ',') }}</i> &euro;&nbsp;{{ '(' + bill[1].bill[0].discount + '%)' }}
                    </span>
                </div>
                </template>
                <!-- iaas either firewall or ips are slected -->
                <template v-else-if="$store.getters.ip > 0 || $store.getters.firewall">
                <div
                style="display: flex; justify-content: space-between; background-color: inherit;"
                :class="['kdz-last-element', 'kdz-cost-border']"
                >
                    <a-popover placement="left" :title="bill[0].bill[0].hoverLabel + ' = ' + bill[0].bill[0].singlePrice.toString().replace('.' , ',') + '€ '" v-if="this.windowWidth>790" >
                    <template #content>
                        <span>{{bill[0].bill[0].UsageForm + 'Netto' + (bill[0].bill[0].discount > 0 ? ', inkl. ' + bill[0].bill[0].discount + '% Rabatt)' : ')')}}</span>
                    </template>
                    <span class="hoverLabel">{{ $store.getters.ip > 0 ? bill[0].bill[0].label : (bill[0].bill[0].label.replace('x2' , 'x1')) }}</span>
                    </a-popover>
                    <a-popover placement="top" :title="bill[0].bill[0].hoverLabel + ' = ' + bill[0].bill[0].singlePrice.toString().replace('.' , ',') + '€ '" v-if="this.windowWidth<=790" >
                    <template #content>
                        <span>{{bill[0].bill[0].UsageForm + 'Netto' + (bill[0].bill[0].discount > 0 ? ', inkl. ' + bill[0].bill[0].discount + '% Rabatt)' : ')')}}</span>
                    </template>
                    <span class="hoverLabel">{{ $store.getters.ip > 0 ? bill[0].bill[0].label : (bill[0].bill[0].label.replace('x2' , 'x1')) }}</span>
                    </a-popover>
                    <span style="width: 30%; display: flex; justify-content: right;" cols="5" class="text-right" align-self="center">
                    <i>{{ $store.getters.ip > 0 ? bill[0].bill[0].price.toString().replace('.' , ',') : ($store.getters.firewall2 ? (bill[0].bill[0].price / 2).toFixed(3).toString().replace('.' , ',') : bill[0].bill[0].price.toString().replace('.' , ','))}}</i> &euro;&nbsp;{{ '(' + bill[0].bill[0].discount + '%)' }}
                    </span>
                </div>
                </template>
            </template>
            <!-- special case regarding firewall wpCLoud -->
            <template v-if="type== 'wpCloud'">
                <!-- wpcloud firewall active and ips selected -->
                <template v-if="$store.getters.firewall2 && $store.getters.ip > 0">
                <div
                style="display: flex; justify-content: space-between; background-color: inherit;"
                :class="['kdz-last-element', 'kdz-cost-border']"
                >
                    <a-popover placement="left" :title="bill[1].bill[0].hoverLabel + ' = ' + ($store.getters.firewall ? (bill[1].bill[0].singlePrice / 2).toFixed(3).toString().replace('.' , ',') : bill[1].bill[0].singlePrice.toString().replace('.' , ',')) + '€ '" v-if="this.windowWidth>790" >
                    <template #content>
                        <span>{{bill[1].bill[0].UsageForm + 'Netto' + (bill[1].bill[0].discount > 0 ? ', inkl. ' + bill[1].bill[0].discount + '% Rabatt)' : ')')}}</span>
                    </template>
                    <span class="hoverLabel">{{ bill[1].bill[0].label.replace('x2' , 'x1') }}</span>
                    </a-popover>
                    <a-popover placement="top" :title="bill[1].bill[0].hoverLabel + ' = ' + ($store.getters.firewall ? (bill[1].bill[0].singlePrice / 2).toFixed(3).toString().replace('.' , ',') : bill[1].bill[0].singlePrice.toString().replace('.' , ',')) + '€ '" v-if="this.windowWidth<=790" >
                    <template #content>
                        <span>{{bill[1].bill[0].UsageForm + 'Netto' + (bill[1].bill[0].discount > 0 ? ', inkl. ' + bill[1].bill[0].discount + '% Rabatt)' : ')')}}</span>
                    </template>
                    <span class="hoverLabel">{{ bill[1].bill[0].label.replace('x2' , 'x1') }}</span>
                    </a-popover>
                    <span style="width: 30%; display: flex; justify-content: right;" cols="5" class="text-right" align-self="center">
                    <i>{{ $store.getters.firewall ? (bill[1].bill[0].price / 2).toFixed(3).toString().replace('.' , ',') : bill[1].bill[0].price.toString().replace('.' , ',') }}</i> &euro;&nbsp;{{ '(' + bill[1].bill[0].discount + '%)' }}
                    </span>
                </div>
                </template>
                <!-- wpCloud firewall active and ips unselected -->
                <template v-else-if="$store.getters.firewall2">
                <div
                style="display: flex; justify-content: space-between; background-color: inherit;"
                :class="['kdz-last-element', 'kdz-cost-border']"
                >
                    <a-popover placement="left" :title="bill[0].bill[0].hoverLabel + ' = ' + ($store.getters.firewall ? (bill[0].bill[0].singlePrice / 2).toFixed(3).toString().replace('.' , ',') : bill[0].bill[0].singlePrice.toString().replace('.' , ',')) + '€ '" v-if="this.windowWidth>790" >
                    <template #content>
                        <span>{{bill[0].bill[0].UsageForm + 'Netto' + (bill[0].bill[0].discount > 0 ? ', inkl. ' + bill[0].bill[0].discount + '% Rabatt)' : ')')}}</span>
                    </template>
                    <span class="hoverLabel">{{ bill[0].bill[0].label.replace('x2' , 'x1') }}</span>
                    </a-popover>
                    <a-popover placement="top" :title="bill[0].bill[0].hoverLabel + ' = ' + ($store.getters.firewall ? (bill[0].bill[0].singlePrice / 2).toFixed(3).toString().replace('.' , ',') : bill[0].bill[0].singlePrice.toString().replace('.' , ',')) + '€ '" v-if="this.windowWidth<=790" >
                    <template #content>
                        <span>{{bill[0].bill[0].UsageForm + 'Netto' + (bill[0].bill[0].discount > 0 ? ', inkl. ' + bill[0].bill[0].discount + '% Rabatt)' : ')')}}</span>
                    </template>
                    <span class="hoverLabel">{{ bill[0].bill[0].label.replace('x2' , 'x1') }}</span>
                    </a-popover>
                    <span style="width: 30%; display: flex; justify-content: right;" cols="5" class="text-right" align-self="center">
                    <i>{{ $store.getters.firewall ? (bill[0].bill[0].price / 2).toFixed(3).toString().replace('.' , ',') : bill[0].bill[0].price.toString().replace('.' , ',') }}</i> &euro;&nbsp;{{ '(' + bill[0].bill[0].discount + '%)' }}
                    </span>
                </div>
                </template>
            </template>
            <template v-for="(pos, index) in bill" :key="index + pos.serverName">
            <!-- sort after the servertypes, special case for containerIaaS -->
            <template v-if="pos.type === type || (pos.type=='containerIaaS' && type == 'iaas')">
                <!-- serverHeader unfolded-->
                <template v-if="showCosts.includes(pos.id) || this.selectedServer.id == pos.id">
                <div class="serverHeader" style="display: flex; cursor: pointer;" @click="showCosts.splice(showCosts.indexOf(pos.id), 1)">
                    <strong v-html="pos.serverName.includes('()') ? pos.serverName.replace(' <small>()</small>','') : pos.serverName"></strong>
                    <div style="text-align: right; flex-grow: 4;">
                    <UpOutlined />
                    </div>
                </div>
                </template>
                <!-- serverHeader folded -->
                <template v-else>
                <div :class="['bottomBorderFolded', 'serverHeader']" style="display: flex; cursor: pointer;" @click="showCosts.push(pos.id)">
                    <strong v-html="pos.serverName.includes('()') ? pos.serverName.replace(' <small>()</small>','') : pos.serverName"></strong>
                    <div style="text-align: right; flex-grow: 4;">
                    <DownOutlined />
                    </div>
                </div>
                </template>
                <!-- invitems unfolded-->
                <template v-for="(invItem, ind) in pos.bill">
                <template v-if="showCosts.includes(pos.id) || this.selectedServer.id == pos.id">
                    <div
                    style="display: flex; justify-content: space-between; background-color: inherit;"
                    :key="ind + '_' + index + pos.serverName"
                    v-if="invItem.type !== 'network'"
                    :class="[{ 'kdz-last-element': (ind === pos.bill.length - 1)  },{ 'kdz-last-element': (ind === pos.bill.length - 2 && pos.type === 'containerIaaS')  }, 'kdz-cost-border']"
                    >
                    <!-- Regarding the classes: Because of the network type skip and the last element of containerIaaS are networks, we need a special case -->
                    <a-popover placement="left" :title="invItem.hoverLabel + ' = ' + invItem.singlePrice.toString().replace('.' , ',') + '€ '" v-if="this.windowWidth>790" >
                        <template #content>
                        <span>{{invItem.UsageForm + 'Netto' + (invItem.discount > 0 ? ', inkl. ' + invItem.discount + '% Rabatt)' : ')')}}</span>
                        </template>
                        <span class="hoverLabel">{{ invItem.label }}</span>
                    </a-popover>
                    <a-popover placement="top" :title="invItem.hoverLabel + ' = ' + invItem.singlePrice.toString().replace('.' , ',') + '€ '" v-if="this.windowWidth<=790" >
                        <template #content>
                        <span>{{invItem.UsageForm + 'Netto' + (invItem.discount > 0 ? ', inkl. ' + invItem.discount + '% Rabatt)' : ')')}}</span>
                        </template>
                        <span class="hoverLabel">{{ invItem.label }}</span>
                    </a-popover>
                    <span style="width: 30%; display: flex; justify-content: right;" cols="5" class="text-right" align-self="center">
                        <i>{{ invItem.price.toString().replace('.' , ',') }}</i> &euro;&nbsp;{{ '(' + invItem.discount + '%)' }}
                    </span>
                    </div>
                </template>
                </template>
                <!-- invtitem -->
            </template>
            <!-- servetype -->
            </template>
        </template>
        </div>

        <div class="kdz-cost-sum text-right">
        <span>Partner Status</span>
        <a-select
            id="houseInput"
            v-model:value="houseSelect"
            style="width: 200px; margin-bottom: 10px;"
        >
            <a-select-option v-for="option in houseOptions" :key="option.partnerValue">
            {{ option.text}}
            </a-select-option>
        </a-select>
        <span> Gesamtkosten / Monat<br/></span>
        <span> alle Preise exkl. Mwst.<br/></span>
        <span>
            <strong>Summe: {{ summary[1].toString().replace("." , ",") }} € </strong> <br />
            - {{ partnerDiscount }} % Rabatt* <tooltip-label :myColor="'white'" :tooltip="'Partner-Rabatte beziehen sich auf folgende Resourcen: vCPU, RAM, IP-Adressen, GPU, TSPlus, SSD, HDD und Next-Generation-Firewall.'"/>
        </span>
        <a-col class="big">{{ summary[0].toString().replace("." , ",") }} €</a-col>
        <template v-if="isCloudOrIaas">
            <br/>
            <span>
            Kosten pro Stunde <br />
            (mntl. 730h &#8960;) <span v-if="discount > 0 && houseSelect">mit Rabatt</span>
            </span>
            <a-col class="big"> {{ costPerHour.toString().replace('.' , ',') }} €</a-col>
        </template>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { DownOutlined, UpOutlined } from '@ant-design/icons-vue'
import TooltipLabel from '@/components/label/TooltipLabel.vue'
import { ref, reactive } from 'vue'


export default {
    name:'EK',
    components: {
        DownOutlined,
        UpOutlined,
        TooltipLabel
    },
    props: {

    },
    data () {
        return {
            serverTypes: ["container", "cloudServer", "iaas", "wpCloud", "cybercloudendpoint"],
            serverTypeNames: { 
                container: "Container Deployments",
                cloudServer: "Cloud-Server",
                iaas: "Infrastructure as a Service",
                wpCloud: "Online Office",
                cybercloudendpoint: "Cyber Cloud Endpoint"
            },
            showCosts: [],
            windowWidth: window.innerHeight, 
            partnerValue: ref('Standard'),
            houseOptions: ref([
                {
                partnerValue: null,
                text: "Standard",
                },
                {
                partnerValue: "Holz",
                text: "Reseller (Holz)",
                },
                {
                partnerValue: "Bronze",
                text: "Reseller (Bronze)",
                },
                {
                partnerValue: "Silber",
                text: "Reseller (Silber)",
                },
                {
                partnerValue: "Gold",
                text: "Reseller (Gold)",
                },
                {
                partnerValue: "Platin",
                text: "Reseller (Platin)",
                },
            ]),
        }
    },
    mounted () {

    },
    computed: {
        ...mapGetters([
        'bill',
        'selectedServer',
        'serverList',
        'discount',
        'discountType',
        'isSidebarOpen',
        'partnerDiscount'
        ]),
        isRoot() {
            return this.$route.path.endsWith("/");
        },
        houseSelect: {
            get() {
                return this.discountType;
            },
            set(value) {
                var selectedDiscount
                this.setDiscountType(value);
                switch (value) {
                case 'Holz': selectedDiscount = 20
                    break
                case 'Bronze': selectedDiscount = 30
                    break
                case 'Silber': selectedDiscount = 35
                    break
                case 'Gold': selectedDiscount = 40
                    break
                case 'Platin': selectedDiscount = 47
                    break
                default: selectedDiscount = 0
                }
                this.$store.commit("setPartnerDiscount", selectedDiscount)
                if (this.serverList.length > 0) {
                for (var server of this.serverList) {
                    if (Object.keys(server.configuration).length > 0) {
                    var before = server.configuration.vCPU
                    server.configuration.vCPU = 1
                    server.configuration.vCPU = before
                    }
                }
                }
            },
        },
        isCloudOrIaas() {
            return (
                this.$store.getters['selectedServer'].type === "iaas" ||
                this.$store.getters['selectedServer'].type === "cloudServer" ||
                (this.$store.getters['serverList'].length > 0 &&
                (this.$store.getters['serverList'][0].type === "cloudServer" ||
                this.$store.getters['serverList'][0].type === "iaas"))
            );
        },
        summary() {
            let sum = 0.0
            let originalSum = 0.0
            this.bill.forEach((el) => {
                for (let pos of el.bill) {
                sum += Number(pos.price)
                if (pos.originalPrice !== undefined) {
                    originalSum += Number(pos.originalPrice)
                }
                }
            });
            return [sum.toFixed(2), originalSum.toFixed(2)];
        },
        costPerHour() {
            return ((this.summary[0]) / 730).toFixed(2);
        },
    },
    methods : {
        ...mapMutations(["setSidebarVisibility", "clearServer", "setDiscountType"]),
        checkServerType(type){
            for (let i = 0; i < this.bill.length; i++) {
                if (this.bill[i].type != undefined && (this.bill[i].type ==type || (this.bill[i].type === "containerIaaS" && type === "iaas"))) {
                return true
                }
            }
            return false
        },
    }
}
</script>