<template>
    <a-spin :spinning="loading">
        <a-modal
        style="height: 65em;"
        v-model:open="openModal"
        :title="'Benutzerkonto erstellen'"
        :closable="false"
        :maskClosable="false"
        cancelText="Abbrechen"
        @cancel="closeModal"
        @ok="handleSubmit"
        destroyOnClose
        :confirmLoading="loading"
        centered>
            <a-form
            :ref="formRef"
            :model="form"
            :rules="rules"
            :loading="loading"
            layout="vertical"
            @finish="handleSubmit">
            <!-- WP Implementation -->
            <a-form-item ref="account" name="account">
                <template #label>
                <TooltipLabel :title="'Name des Benutzerkontos'" :tooltip="'Name des eigentlichen Benutzerkontos, welches vom System benutzt wird. Keine Sonderzeichen erlaubt um Requestfehler zu verhindern.'"/>
                </template>
                <a-input v-model:value="form.account" :placeholder="'Name des Benutzerkontos'" />
            </a-form-item>
            <a-form-item ref="username" name="username">
                <template #label>
                <tooltip-label :title="'Firmenname'" :tooltip="'Firmenname, welcher in den Rechnungen angezeigt wird. Alle Sonderzeichen sind erlaubt, da dies nur ein reiner Anzeige-Name und sonst nicht für das System relevant ist.'"/>
                </template>
                <a-input
                v-model:value="form.username"
                placeholder="Name der Firma" />
            </a-form-item>
            <a-row :gutter="12">
                <a-col :md="24" :lg="12">
                <a-form-item ref="password" name="password">
                    <template #label>
                    <tooltip-label :title="'Passwort'" :tooltip="'Mindestanforderungen: \n- Mindestens 8 Zeichen\n- Mindestens 1 Großbuchstaben\n- Mindestens 1 Kleinbuchstaben\n- Mindestens 1 Zahl\n- Mindestens 1 Sonderzeichen : \n\t!?@#$%^&*()\-_=+{};:,<.>\n- Keine Buchstabe darf mehr als zweimal hintereinander stehen!'"/>
                    </template>
                    <a-input-password
                    v-model:value="form.password"
                    :placeholder="'Passwort'"/>
                </a-form-item>
                </a-col>
                <a-col :md="24" :lg="12">
                <a-form-item ref="confirmpassword" name="confirmpassword">
                    <template #label>
                    <TooltipLabel :title="'Passwort bestätigen'" :tooltip="'Bitte wiederholen Sie das Passwort'"/>
                    </template>
                    <a-input-password
                    v-model:value="form.confirmpassword"
                    :placeholder="'Passwort bestätigen'"/>
                </a-form-item>
                </a-col>
            </a-row>
            <!-- WP Implementation -->
            <!-- Customer NR -->
            <a-row :gutter="12">
                <a-col :md="24" :lg="12">
                <a-form-item ref="customerID" name="customerID">
                    <template #label>
                    <tooltip-label :title="'Kundennummer'" :tooltip="'Bitte geben Sie eine Kundennummer an.\nErlaubte Zeichen: \nZahlen und -_'"/>
                    </template>
                    <a-input
                    v-model:value="form.customerID"
                    :placeholder="'Kundennummer'" />
                </a-form-item>
                </a-col>
                <!-- Contract NR -->
                <a-col :md="24" :lg="12">
                <a-form-item ref="contractNumber" name="contractNumber">
                    <template #label>
                    <tooltip-label :title="'Vertragsnummer'" :tooltip="'Eine gültige Vertragsnummer besteht aus Zahlen, Buchstaben und -_.'"/>
                    </template>
                    <a-input
                    v-model:value="form.contractNumber"
                    :placeholder="'Vertragsnummer'"/>
                </a-form-item>
                </a-col>
            </a-row>
            <!-- Street + Nr -->
            <a-row :gutter="12">
                <a-col :md="24" :lg="17">
                <a-form-item ref="street" name="street">
                    <template #label>
                    <tooltip-label :title="'Straße + Hausnummer'" :tooltip="'Bitte geben Sie eine gültige Adresse an.\nErlaubte Zeichen: \nBuchstaben, Zahlen, \nLeerzeichen und -'"/>
                    </template>
                    <a-input
                    v-model:value="form.street"
                    :placeholder="'Straße + Hausnummer'"/>
                </a-form-item>
                </a-col>
                <!-- Postal Code -->
                <a-col :md="24" :lg="7">
                <a-form-item ref="postalcode" name="postalcode">
                    <template #label>
                    <tooltip-label :title="'PLZ'" :tooltip="'Bitte geben Sie eine Postleitzahl an.\nPostleitzahlen haben maximal 8 Ziffern.'"/>
                    </template>
                    <a-input
                    v-model:value="form.postalcode"
                    :placeholder="'PLZ'"/>
                </a-form-item>
                </a-col>
            </a-row>
            <!-- City -->
            <a-row :gutter="12">
                <a-col :md="24" :lg="12">
                <a-form-item ref="city" name="city">
                    <template #label>
                    <tooltip-label :title="'Stadt'" :tooltip="'Bitte geben Sie einen Stadtnamen an.\nErlaubte Zeichen: \nBuchstaben, Zahlen, \nLeerzeichen und -'"/>
                    </template>
                    <a-input
                    v-model:value="form.city"
                    :placeholder="'Stadt'"/>
                </a-form-item>
                </a-col>
                <!-- Country -->
                <a-col :md="24" :lg="12">
                <a-form-item ref="country" name="country">
                    <template #label>
                    <tooltip-label :title="'Land'" :tooltip="'Bitte geben Sie ein gültiges Land an.\nErlaubte Zeichen: \nBuchstaben, Zahlen, \nLeerzeichen und -'"/>
                    </template>
                    <a-input
                    v-model:value="form.country"
                    :placeholder="'Land'"/>
                </a-form-item>
                </a-col>
            </a-row>
            <!-- Email -->
            <a-form-item ref="email" name="email">
                <template #label>
                <tooltip-label :title="'E-Mail'" :tooltip="'Bitte geben Sie eine gültige E-Mail an.\nErlaubte Zeichen: \nBuchstaben, Zahlen, -_@.'"/>
                </template>
                <a-input
                v-model:value="form.email"
                :placeholder="'E-Mail'" />
            </a-form-item>
            <a-row :gutter="12">
                <a-col :md="24" :lg="12">
                <a-form-item ref="firstname" name="firstname">
                    <template #label>
                    <tooltip-label :title="'Vorname'" :tooltip="'Bitte geben Sie den Vorname des Nutzers an.'"/>
                    </template>
                    <a-input
                    v-model:value="form.firstname"
                    :placeholder="'Vorname'" />
                </a-form-item>
                </a-col>
                <a-col :md="24" :lg="12">
                <a-form-item ref="lastname" name="lastname">
                    <template #label>
                    <tooltip-label :title="'Nachname'" :tooltip="'Bitte geben Sie den Nachname des Nutzers an.'"/>
                    </template>
                    <a-input
                    v-model:value="form.lastname"
                    :placeholder="'Nachname'" />
                </a-form-item>
                </a-col>
            </a-row>
            <a-switch style="margin-left: 5px; margin-right: 5px;" v-model:checked="createDomainBool" size="small" @change="setCreateDomain"></a-switch>Neue Domain erstellen?
            <a-form-item ref="newDomainName" name="newDomainName" v-if="createDomainBool">
                <template #label>
                <tooltip-label :title="'Domain'" :tooltip="'Erstellen Sie eine neue Domain oder wählen Sie aus bereits vorhandenen Domains aus. Das Benutzerkonto wird unter der angegeben Domain erstellt!'"/>
                </template>
                <div>
                <a-input v-model:value="form.newDomainName" :placeholder="'Name der neuen Domain'" />
                </div>
            </a-form-item>
            <a-form-item ref="domainid" name="domainid" v-else>
                <template #label>
                <tooltip-label :title="'Domain'" :tooltip="'Erstellen Sie eine neue Domain oder wählen Sie aus bereits vorhandenen Domains aus. Das Benutzerkonto wird unter der angegeben Domain erstellt!'"/>
                </template>
                <div>
                <a-select
                v-model:value="form.domainid"
                :placeholder="'Domain auswählen'"
                showSearch
                optionFilterProp="label"
                :filterOption="(input, option) => {
                    return  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }" >
                <a-select-option v-for="domain in domainsList" :key="domain.ID" :label="domain.Path || domain.Name">
                    <span>
                    <BlockOutlined style="margin-right: 5px"/>
                    {{ domain.Path || domain.Name  }}
                    </span>
                </a-select-option>
                </a-select>
                </div>
            </a-form-item>
        </a-form>
        </a-modal>
    </a-spin>
</template>

<script>
import api from '@/apis/apis'
import { ref, reactive, toRaw } from 'vue'
import TooltipLabel from '@/components/TooltipLabel.vue'
import { notification } from 'ant-design-vue'
import { BlockOutlined } from '@ant-design/icons-vue'

export default {
    name: 'CreateAccount',
    components: {
      TooltipLabel,
      notification,
      BlockOutlined
    },
    props: {
      createAccountModal: {
        type: Boolean,
        required: true
      },
      accountId: {
        type: String,
        required: true
      },
    },
    data () {
        return {
            emailRegex: /^[^@]+@[^@]{2,}\.[^@]{2,}$/,
            passwordRegexUpperCase: /^(?=.*?[A-Z]).*$/, //minimum 1 Upper Case Letter
            passwordRegexLowerCase: /^(?=.*?[a-z]).*$/, //minimum 1 Lower Case Letter
            passwordRegexDigit: /^(?=.*?\d).*$/, //minimum 1 Digit
            passwordRegexSpecialCharacter: /^(?=.*?[!?@#$%^&*()\-_=+{};:,<.>]).*$/, //minimum 1 Special Character
            passwordRegexRepeatCharacter: /^(?:(.)(?!\1{2}))+$/, //not more than 2 repeated Letters
            passwordRegexWrongSpecials: /[`´€µ°²³§[\]'"|,/ß~]/, // special Characters that are not allowed
            passwordRegexLength: /^([A-Za-z0-9!?@#$%^&*()\-_=+{};:,<.>]{8,})$/, //minimum 8 Chars
            customerIdRegex: /^[0-9]*$/, //only digits
            contractNumberRegex: /^[\u00C0-\u017Fa-zA-Z0-9_.-]*$/, //only letters, digits, -_.
            cityNameRegex: /^[\u00C0-\u017Fa-zA-Z0-9- ]*$/,
            specialChars: /^[\u00C0-\u017Fa-zA-Z0-9_.-]*$/,
            specialCharsMail: /[-_@.]/,
            createDomainBool: true,
            openModal: false,
            loading:false,
            domainsList: []
        }
    },
    watch: {
        createAccountModal: function(newVal) {
        if ( newVal === true) {
            this.listDomainsForReseller()
        }
        this.openModal = newVal
      },
    },
    created () {
        this.initForm()
    },
    mounted () {

    },
    methods: {
        initForm() {
            this.formRef = ref()
            this.form = reactive({
                domainid: undefined,
                newDomainName: undefined,
                account: undefined,
                city: undefined,
                postalcode: undefined,
                street: undefined,
                country: undefined,
                customerID: undefined,
                contractNumber: undefined
            })
            this.rules = reactive({
                roleid: [{ required: true, message: 'Bitte eine Option auswählen' }],
                account: [
                { required: true, message: '' },
                { validator: this.validateAccountName }
                ],
                newDomainName: [{ required: true, message: 'Bitte geben Sie einen Domainnamen an.' }],
                domainid: [{ required: true, message: 'Bitte wählen Sie eine Domain aus.' }],
                username: [
                { required: true, message: 'Bitte geben Sie den Namen der Firma an.' },
                { validator: this.validateCompanyName }
                ], //Firmname
                password: [
                { required: true, message: '' },
                { validator: this.validatePassword }
                ],
                confirmpassword: [
                { required: true, message: '' },
                { validator: this.validateConfirmPassword }
                ],
                city: [
                { required: true, message: 'Bitte geben Sie eine Stadt an.' },
                { validator: this.validateCity }
                ],
                postalcode: [
                { required: true, message: 'Bitte geben Sie eine Postleitzahl an.'},
                { validator: this.validatePlz }
                ],
                street: [
                { required: true, message: 'Bitte geben Sie eine Adresse an.' },
                { validator: this.validateStreet }
                ],
                country: [
                { required: true, message: 'Bitte geben Sie ein Land an.' },
                { validator: this.validateCountry }
                ],
                customerID: [
                { required: true, message: 'Bitte geben Sie eine Kundennummer an.' },
                { validator: this.validateCustomerID }
                ],
                email: [
                { required: true, message: '' },
                { validator: this.validateEmail }
                ],
                firstname: [{ required: true, message: 'Bitte geben Sie einen Vornamen an.' }],
                lastname: [{ required: true, message: 'Bitte geben Sie einen Nachnamen an.' }],
                domain: [{ required: true, message: 'Bitte eine Option auswählen' }],
                contractNumber: [
                { required: false, message: 'Eine gültige Vertragsnummer besteht aus Zahlen, Buchstaben und -_.' },
                { validator: this.validateContractNumber }
                ]
            })
        },
        handleSubmit (e) {
            e.preventDefault()
            if (this.loading) return
            this.formRef.value.validate().then(async () => {
                const values = toRaw(this.form)
                var newDomainUuid = undefined
                this.loading = true
                var domainCreatedSuccessfully = true
                // WP Implementation
                if (this.createDomainBool && values.newDomainName !== undefined) {
                let createDomainParams = new FormData()
                createDomainParams.append('accountuuid', this.accountId)
                createDomainParams.append('name', values.newDomainName)
                await api.createDomain(createDomainParams).then(response => {
                    newDomainUuid = response.data.id
                    this.$notification.success({
                    message: 'Erfolg',
                    description: 'Die Domain wurde erfolgreich erstellt.',
                    duration: 6
                    })
                }).catch(error => {
                    this.$notification.error({
                    message: 'Fehler beim erstellen der Domain',
                    description:
                        'Die Domain konnte nicht erstellt werden. Ohne valide Domain kann das Benutzerkonto nicht erstellt werden, bitte versuchen Sie es erneut. (' + error.response.data.split('):')[1] + ')',
                    duration: 0
                    })
                    domainCreatedSuccessfully = false
                })
                }
                if (!domainCreatedSuccessfully) {
                return
                }
                var params = new FormData()
                // Need the reseller uuid for creating the quota usages prices for the subaccounts
                params.append('reselleruuid', this.accountId)
                params.append('username', values.account)
                params.append('password', values.password)
                params.append('email', values.email)
                params.append('firstname', values.firstname)
                params.append('lastname', values.lastname)
                params.append('customer', values.customerID)
                params.append('country', values.country)
                params.append('streetName', values.street)
                params.append('plz', values.postalcode)
                params.append('city', values.city)
                params.append('customerName', values.username)
                params.append('shortName', values.account.replace(/\s+/g, ''))
                params.append('domainuuid', newDomainUuid === undefined ? values.domainid : newDomainUuid)
                if (values.contractNumber == undefined || values.contractNumber == "" ) {
                params.append('contractNumber', "")
                }else{
                params.append('contractNumber', values.contractNumber)
                }
                await api.createAccount(params).then(async response => {
                var createdAccUUID = response.data.id
                // create the AcronisUserTenant with the information from the createAccount API
                var createAcronisUserParams = new FormData()
                createAcronisUserParams.append('tenant_companyname', values.username)
                createAcronisUserParams.append('tenant_address', values.street + ', ' + values.postalcode + ' ' + values.city + ', ' + values.country)
                createAcronisUserParams.append('tenant_email', values.email)
                createAcronisUserParams.append('tenant_user_login', createdAccUUID)
                createAcronisUserParams.append('tenant_user_password', values.password)
                createAcronisUserParams.append('tenant_user_email', values.email)
                createAcronisUserParams.append('tenant_user_firstname', values.firstname)
                createAcronisUserParams.append('tenant_user_lastname', values.lastname)
                await api.createAcronisTenant(createAcronisUserParams).then(response => {
                    if (response.data === 'ok') {
                    const message = 'Acronis User wurde erfolgreich erstellt'
                    this.$notification.success({
                        message: 'Erfolg',
                        description: message,
                        duration: 4
                    })
                    } else {
                    const message = 'Das Acronis Benutzerkonto konnte nicht erstellt werden.'
                    this.$notification.error({
                    message: message,
                    description:
                        'Das Acronis Benutzerkonto stellt alle mit Acronis in Verbindung stehenden Funktionen zur Verfügung, welche somit für das erstelle Benutzerkonto nicht verwendet werden können! Bitte löschen Sie das soeben erstellte Benutzerkonto und versuchen Sie ein Neues zu erstellen. Falls dieses Problem weiterhin besteht, kontaktieren Sie unseren Support.',
                    duration: 0
                    })
                    }

                }).catch(error => {
                    const message = 'Das Acronis Benutzerkonto konnte nicht erstellt werden.'
                    this.$notification.error({
                    message: message,
                    description:
                        'Das Acronis Benutzerkonto stellt alle mit Acronis in Verbindung stehenden Funktionen zur Verfügung, welche somit für das erstelle Benutzerkonto nicht verwendet werden können! Bitte löschen Sie das soeben erstellte Benutzerkonto und versuchen Sie ein Neues zu erstellen. Falls dieses Problem weiterhin besteht, kontaktieren Sie unseren Support.',
                    duration: 0
                    })
                    console.log(error)
                })
                this.$notification.success({
                    message: 'Erfolg',
                    description: 'Das Benutzerkonto wurde erfolgreich erstellt.',
                    duration: 4
                })
                this.closeModal()
                this.loading = false
                this.$emit('reload-accounts')
                }).catch(error => {
                this.closeModal()
                this.loading = false
                this.$notification.error({
                message: 'Fehler beim Erstellen des Benutzerkontos.',
                description:
                    'Bei der Erstellung des Benutzerkontos ist ein Fehler aufgetreten, bitte versuchen Sie es erneut. (' + error.response.data.split('):')[1] + ')',
                duration: 0
                })
                })
            }).catch(error => {
                console.log(error)
                this.loading = false
            })
        },
        closeModal () {
            this.form.domainid = undefined
            this.form.newDomainName = undefined
            this.form.domainid = undefined
            this.form.account = undefined
            this.form.city = undefined
            this.form.street = undefined
            this.form.postalcode = undefined
            this.form.country = undefined
            this.form.customerID = undefined
            this.form.username = undefined
            this.form.email = undefined
            this.form.confirmpassword = undefined
            this.form.password = undefined
            this.form.firstname = undefined
            this.form.lastname = undefined
            this.form.domain = undefined
            this.form.contractNumber = undefined
            this.createDomainBool = false
            this.$emit('close-modal')
        },
        listDomainsForReseller(){
            api.listDomains(this.accountId).then(response =>{
                this.domainsList = response.data
            }).catch(error => {
                console.log("error domain",error)
            })
        },
        setCreateDomain () {
        this.form.domainid = undefined
        },
        validateAccountName (rule, value) {
        var errorMessage = 'Der Benutzername erfüllt nicht alle Anforderungen!'
        var validated = true;
        if (!this.specialChars.test(value)) {
            validated = false;
            errorMessage += "\nSonderzeichen sind nicht erlaubt!"
        }
        if (value==undefined || value.length < 4 ) {
            validated = false;
            errorMessage += "\nDer Benutzername muss mindestens 4 Zeichen lang sein!"
        }
        if (validated){
            return Promise.resolve()
        } else {
            return Promise.reject(errorMessage)
        }
        },
        validateCompanyName (rule, value) {
        // const errorMessage = this.$t('message.validate.special.characters')
        var errorMessage = 'Der Firmenname erfüllt nicht alle Anforderungen!'
        var validated = true
        if (value === undefined || value.length < 4) {
            validated = false
            errorMessage += '\nDer Firmenname muss mindestens 4 Zeichen lang sein!'
        }
        if (validated) {
            return Promise.resolve()
        } else {
            return Promise.reject(errorMessage)
        }
        },
        validateEmail (rule, value) {
        const errorMessage = 'Bitte geben Sie eine gültige E-Mail an. \nBeispiel: Beispiel@Email1.com\nNur Zahlen, Buchstaben und -_@. sind erlaubt!'
        if (this.emailRegex.test(value) && this.specialCharsMail.test(value)) {
            return Promise.resolve()
        }
        return Promise.reject(errorMessage)
        },
        validateCustomerID(rule, value) {
        const errorMessage = 'Bitte geben Sie eine gültige Kundennummer an.\nNur Zahlen sind erlaubt!'
        if (value!=undefined && this.customerIdRegex.test(value)) {
            return Promise.resolve()
        }
        return Promise.reject(errorMessage)
        },
        validateContractNumber(rule, value){
        const errorMessage = 'Eine gültige Vertragsnummer besteht aus Zahlen, Buchstaben und -_.'
        if (this.contractNumberRegex.test(value)) {
            return Promise.resolve()
        }
        return Promise.reject(errorMessage)
        },
        validateCity(rule, value) {
        const errorMessage = 'Bitte geben Sie einen gültigen Stadtnamen an.\nNur Zahlen, Buchstaben, Leezeichen und - sind erlaubt!'
        if (value!=undefined && this.cityNameRegex.test(value)) {
            return Promise.resolve()
        }
        return Promise.reject(errorMessage)
        },
        validateStreet(rule, value) {
        const errorMessage = 'Bitte geben Sie eine gültige Adresse an.\nNur Zahlen, Buchstaben, Leezeichen und - sind erlaubt!'
        if (value!=undefined && this.cityNameRegex.test(value)) {
            return Promise.resolve()
        }
        return Promise.reject(errorMessage)
        },
        validateCountry(rule, value) {
        const errorMessage = 'Bitte geben Sie ein gültiges Land an.\nNur Zahlen, Buchstaben, Leezeichen und - sind erlaubt!'
        if (value!=undefined && this.cityNameRegex.test(value)) {
            return Promise.resolve()
        }
        return Promise.reject(errorMessage)
        },
        async validatePassword (rule, value) {
        var errorMessage = 'Ihr Passwort erfüllt nicht alle Anforderungen!'
        var validated = true;
        if (!this.passwordRegexLength.test(value)) {
            validated = false;
            errorMessage += "\nMindestens 8 Zeichen"
        }
        if (!this.passwordRegexUpperCase.test(value)) {
            validated = false;
            errorMessage += "\nMindestens 1 Großbuchstaben"
        }
        if (!this.passwordRegexLowerCase.test(value)) {
            validated = false;
            errorMessage += "\nMindestens 1 Kleinbuchstaben"
        }
        if (!this.passwordRegexDigit.test(value)) {
            validated = false;
            errorMessage += "\nMindestens 1 Zahl"
        }
        if (!this.passwordRegexSpecialCharacter.test(value)) {
            validated = false;
            errorMessage += "\nMindestens 1 Sonderzeichen"
        }
        if (this.passwordRegexWrongSpecials.test(value)) {
            validated = false;
            errorMessage += "\nFalsche Sonderzeichen!"
        }
        if (!this.passwordRegexRepeatCharacter.test(value)) {
            validated = false;
            errorMessage += "\nKein Buchstabe darf sich nicht mehr als zweimal wiederholen!"
        }
        if(value==undefined || this.form.account==undefined || value.toLowerCase().includes(this.form.account.toLowerCase())){
            validated = false;
            errorMessage += "\nDas Passwort darf den Benutzername nicht beinhalten!"
        }
        if (validated){
            return Promise.resolve()
        } else {
            return Promise.reject(errorMessage)
        }
        },
        validatePlz (rule, value) {
            const errorMessage = 'Die maximale Länge der Postleitzahl beträgt 8!'
            if (value!=undefined && value.length <= 8) {
            return Promise.resolve()
            }
            return Promise.reject(errorMessage)
        },
    }

}
</script>