<template>
  <div class="iaas-container">
      <div class="router-container">
        <img v-if="firewall" class="firewall-shield" src='../assets/active-ngf.svg'/>
        <img v-else class="firewall-shield" src='../assets/inactive-ngf.svg'/>
        <div class="connection horizontal"></div>
        <div class="connection vertical"></div>
        <img v-if="routerVersion >= 768" class="router" src='../assets/router.svg'/>
        <img v-else class="router" src='../assets/router-mobil.svg'/>
        <div class="NgfAndIps">
          <div id="switch" style="margin-bottom: 10px; display: flex; flex-direction: row;">
            <div >
              <a-switch v-model:checked="firewallStatus"></a-switch>
            </div>
            <div style=" display: flex; flex-direction: row; flex-wrap: wrap;">
              <span style="margin-left: 5px;">Next-Generation-Firewall {{ firewall ? "ausschalten" : "einschalten" }}&nbsp;
                <a-popover title="Hinweis"
                  icon="info-circle"
                  id="hint">
                    <template #content>
                      <p style="font-size: 10px; width: 200px;">{{ 'Die Next-Generation-Firewall ist ein mehrstufiges Sicherheitssystem zum Schutz vor modernen Cyberattacken' }}</p>
                    </template>
                    <ExclamationCircleOutlined />
                </a-popover>
              </span>
            </div>
          </div>
          <div id="ips">
            <a-input
              style="width: 70px; margin-right: 5px;"
              type="number"
              width="200"
              number
              id="ipAddressNumber"
              v-model:value="ipAddress"
              :min="1"
              :max="200000"
            ></a-input>
            <span v-html="'Öffentliche IP-Adressen'"></span>&nbsp;
            <a-popover title="Hinweis"
              icon="info-circle"
              id="hint">
              <template #content>
                <p style="font-size: 10px; width: 200px;">{{ 'Die erste IP-Adresse ist kostenlos. Jede weitere IP-Adresse kostet 10,00€' }}</p>
              </template>
              <ExclamationCircleOutlined />
            </a-popover>
          </div>
        </div>
      </div>
      
    <IaaSNetwork
      v-for="(net, i) in networks"
      :network="net"
      :key="i"
      :last="i === networks.length - 1"
      v-on:deleteNetworkModal="deleteNetworkModal"
    />
    <div class="add-network-container">
      <div class="kdz-bg-grey clickable createNetwork" @click="addNetwork">
        <div class="connection hor-next-net"></div>
        <div class="connection vert-next-net"></div>
          <img
            style="padding-left: 5px;"
            class="buttonImage"
            width="20"
            src='../assets/button_square.svg'/>&nbsp;
        <span class="createNewNetwork"> Neues Netzwerk erstellen </span>
      </div>
      <div class="noticeText">
        Hinweis: Die Kostenrechnung basiert auf der tatsächlichen Laufzeit in Stunden: Festplatten und Lizenzen werden während der gesamten VM-Existenz abgerechnet, selbst im ausgeschalteten Zustand, während CPU und RAM nur für die aktive Betriebszeit berechnet werden.
      </div>
    </div>
    <a-modal
        :title="'Netzwerk löschen?'"
        v-model:open="deleteNetworkModalBool"
        :closable="true"
        @cancel="deleteNetworkModalBool = false"
        @ok="deleteNetwork()"
        okText="Ok"
        cancelText="Abbrechen"
        centered>
        Wollen Sie das Netzwerk wirklich löschen? Alle konfigurierten Server in diesem Netzwerk werden ebenfalls gelöscht!
      </a-modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import IaaSNetwork from "@/components/server/IaaSNetwork.vue";
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'

export default {
  name: "InfraAsServiceView",
  components: {
    IaaSNetwork,
    ExclamationCircleOutlined
  },
  data() {
    return {
      deleteNetworkModalBool: false,
      deleteThisNetwork: 0
    };
  },
  mounted () {
    this.$store.commit("setSidebarVisibility", false);
    this.ipAddress = this.ipAddress === 0 ? 1 : this.ipAddress
    this.fetchConfiguredNetworks()
  },
  async beforeRouteLeave (to, from) {
    if (to.path === '/cloudServer' || to.path === '/collaboration' || to.path === '/cybercloudendpoint' || to.path === '/container') {
      if (this.$store.getters.isSidebarOpen) {
        const confirm = window.confirm('Wollen Sie die momentane Konfiguration wirklich abbrechen? Nicht gespeicherte Daten gehen verloren!')
        if (!confirm) {
          return false
        }
      }
      this.$store.commit("resetServer");
      this.$store.commit("setSidebarVisibility", false);
      // remove the IP object from Bill Array if no other iaas server is configured
      if (this.$store.getters.bill.filter(x => x.type === 'iaas').length === 0) {
        this.$store.commit("setIp", 0);
      }
      
  }
  },
  computed: {
    ...mapGetters(["firewall", "ip", "networkList"]),
    networks: {
      get () {
        return this.networkList
      },
      set(value) {
        this.pushNetworkList(value)
      }
    },
    ipAddress: {
      get() {
        return this.ip;
      },
      set(value) {
        this.setIp(value);
      },
    },
    firewallStatus: {
      get() {
        return this.firewall;
      },
      set(value) {
        this.setFirewall(value);
      },
    },
    routerVersion() {
      return (
        window.screen.width
      );
    },
    firewallShield() {
      return (this.firewall ? "/active-ngf.svg" : "/inactive-ngf.svg")
    },
  },
  methods: {
    ...mapMutations(["deleteNetworkServer", "setFirewall", "setIp", "setSidebarVisibility", "resetServer", "pushNetworkList"]),
    addNetwork() {
      let last = this.networks[this.networks.length - 1];
      last++;
      this.networks = last
    },
    deleteNetwork() {
      var tempNetworks = this.networks
      const index = tempNetworks.indexOf(this.deleteThisNetwork);
      if (index > -1) {
        tempNetworks.splice(index, 1); // 2nd parameter means remove one item only
      }
      this.$store.commit('setNetworkList', tempNetworks);
      this.deleteNetworkServer(this.deleteThisNetwork);
      this.setSidebarVisibility(false);
      this.resetServer();
      if (this.networks.length === 0) {
        this.networks = 1;
      }
      this.deleteNetworkModalBool = false
    },
    deleteNetworkModal(networkId) {
      this.deleteNetworkModalBool = true
      this.deleteThisNetwork = networkId
    },
    cancelNetworkModal() {
      this.deleteThisNetwork = 0
      this.deleteNetworkModalBool = false
    },
    async fetchConfiguredNetworks () {
      var networkServer = this.$store.getters.serverList.filter(x => x.type === 'iaas')
      if (networkServer.length > 1) {
        for (var netServer of networkServer) {
          const netNr = netServer.configuration.network
          if (!this.networks.includes(netServer.configuration.network)) {
            this.networks = netNr
          }
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.clickable {
    cursor: pointer;
  
    &:hover {
      color: #d02c2a;
    }
  }
.createNetwork {
  display: flex;
  width: 60%;
  align-items: center;
  justify-content: center;
}
.kdz-bg-grey {
  background-color: rgba(135, 135, 135, 0.11);
  position: relative;
  border-radius: 10px;
  margin-right: 20px;
}
.NgfAndIps {
  text-align: left;
}
.iaas-container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.router {
  width: 100%;
  position: relative;
  z-index: 2;
  left: -25px;
  &-container {
    padding: 0 1rem;
  }
}

.firewall-shield {
  position: absolute;
  z-index: 3;
  width: 35px;
  top: 0;
  left: 35%;
}

.add-network-container {
  display: flex;
  width: 800px;
  text-align: left;
  margin: 1rem 0.25rem;
}

.runtime-hint {
  background-color: rgba(135, 135, 135, 0.11);
  border-radius: 10px;
  margin: 0 3rem;
  padding: 10px;
}

 @media (min-width: 1161px) {
  .router {
    width: 40%;
    &-container {
      padding: 0 3rem;
      display: flex;
      flex-direction: row;
    }
  }
  .firewall-shield {
    top: 45px;
  }

  .add-network-container {
    margin: 1rem 3rem;
  }

  .connection {
    position: absolute;
    background-color: #f39200;
    z-index: 1;
    &.horizontal {
      top: 44px;
      left: 6px;
      width: 40px;
      height: 10px;
    }
    &.vertical {
      top: 54px;
      left: 6px;
      width: 10px;
      height: 136px;
    }
    &.hor-next-net {
      top: 28px;
      left: -54px;
      width: 55px;
      height: 10px;
    }
    &.vert-next-net {
      top: -16px;
      left: -54px;
      width: 10px;
      height: 54px;
    }
  }
} 

@media (max-width: 1200px) {
  .router {
    width: 40%;
    &-container {
      padding: 0 3rem;
      display: flex;
      flex-direction: row;
    }
  }
  .firewall-shield {
    top: 40px;
  }
  .NgfAndIps {
    text-align: left;
    width: 300px;
    font-size: 90%;
  }

  .add-network-container {
    margin: 1rem 3rem;
    width: 560px;
    height: 50px;
  }

  .connection {
    position: absolute;
    background-color: #f39200;
    z-index: 1;
    &.horizontal {
      top: 44px;
      left: 6px;
      width: 40px;
      height: 10px;
    }
    &.vertical {
      top: 54px;
      left: 6px;
      width: 10px;
      height: 136px;
    }
    &.hor-next-net {
      top: 23px;
      left: -54px;
      width: 55px;
      height: 10px;
    }
    &.vert-next-net {
      top: -21px;
      left: -54px;
      width: 10px;
      height: 54px;
    }
  }
  .createNewNetwork {
    font-size: 90%;
    width: 150px;
    height: 50px;
    display: flex;
    align-items: center;
  }
  .noticeText {
    font-size: 80%;
  }
  .buttonImage {
    margin-left: 5px;
  }
}
@media (max-width: 930px) {
  .NgfAndIps {
    text-align: left;
    width: 180%;
    font-size: 90%;
  }

  .router {
    width: 105px;
    &-container {
      padding: 0 0 0 3rem;
      display: flex;
      flex-direction: row;
    }
  }
  .firewall-shield {
    left: 130px;
    width: 24px;
  }
  .add-network-container {
    width: 445px;
    margin: 0 1rem;
  }
  .connection {
    &.hor-next-net {
      width: 22px;
      left: -22px;
    }
    &.vert-next-net {
      left: -22px;
    }
  }
}

@media (max-width: 790px) {
.iaas-container {
    margin-bottom: 35px;
  }
}

@media (max-width: 490px) {
  .add-network-container {
    width: 360px;
  }
  .add-network-container {
   flex-direction: column;
    margin: 0 1rem;
  }
  .iaas-container {
    margin-bottom: 50px;
  }
  .noticeText {
    font-size: 80%;
    position: relative;
    left: -15px;
  }
  .router {
    top: -3px;
    left: -47px;
  }
  .firewall-shield {
    left: 100px;
    width: 20px;
    top: 50px;
  }
}
</style>