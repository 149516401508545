<template>
  <div class="kdz-bg-grey">
    <div :class="['kdz-height']">
      <img :class="[
        'kdz-config-img',
        'server',
        { 'kdz-small': isSmallScreen },
      ]" src='../../assets/server_single.svg' v-if="!isConfigurationEmpty"/>
      <div>
        <transition-group name="fade" tag="div" v-if="!isSmallScreen" class="mainHeight">
          <div class="cpu-wrapper fade-in" v-if="configuration.vCPU > 0" :key="'vCPU'">
            <img class="kdz-config-img cpu" src='../../assets/cpu_core.png'/>
            <div class="bar-horizontal-cpu grey-bar"></div>
            <div class="bar-vertical-cpu grey-bar"></div>
          </div>
          <img class="kdz-config-img fade-in firewall" :key="'firewall'" v-if="!isConfigurationEmpty" :src="
          (configuration.NextGenerationFirewall ? getImgUrl('/active-ngf.svg') : getImgUrl('/inactive-ngf.svg'))
          "/>
          <div class="ram-wrapper fade-in" v-if="configuration.MEMORY > 0" :key="'MEMORY'">
            <img class="kdz-config-img ram" src='../../assets/ram.png'/>
            <div class="bar-vertical-ram grey-bar"></div>
            <div class="bar-horizontal-ram grey-bar"></div>
          </div>
          <div class="ssd-wrapper fade-in" v-if="configuration.SSD > 0" :key="'SSD'">
            <img class="kdz-config-img ssd" src='../../assets/ssd.png'/>
            <div class="bar-horizontal-ssd grey-bar"></div>
          </div>
          <div class="hdd-wrapper fade-in" v-if="configuration.HDD > 0" :key="'HDD'">
            <img class="kdz-config-img hdd" src='../../assets/hdd.png'/>
            <div class="bar-vertical-hdd grey-bar"></div>
            <div class="bar-horizontal-hdd grey-bar"></div>
          </div>
          <div class="os-wrapper" v-if="configuration.os" :key="'os'">
            <img class="kdz-config-img os" :src="getImgUrl(osIcon)"/>
          </div>
          <div class="office-wrapper" :key="'office'">
            <img class="kdz-config-img office" src='../../assets/msoffice.svg' v-if="configuration.MS_Office_2021_1_User > 0"/>
            <img class="kdz-config-img office" src='../../assets/libre.svg' v-if="configuration.libre > 0"/>
          </div>
        </transition-group>
      </div>
      <div v-if="isConfigurationEmpty">
        <img class="server-cloud" src='../../assets/create_server.png' :key="'cross'"/>

        <div id="addNewCloudServerBtn" class="panel panel-cloud clickable text-center" key="" @click="createServer">
            <div class="clickable">
              <img width="20" src='../../assets/add_cross.svg'/>
            </div>
            <span>Neuen Cloud Server erstellen</span>
        </div>
      </div>
      <div v-if="!isConfigurationEmpty" id="editDeleteServer" class="editCloudServer">
          <div>
            <span class="kdz-server-title" style="word-break: break-word;">{{ name }}</span>
          </div>
        <div class="clickable flexBetween" @click="editServer">
            <img src='../../assets/button_square.svg' width="20" alt="" style="margin-right: 3px; margin-left: 3px; "/>
          <div style="word-break: break-word;"> {{ name }} bearbeiten</div>
        </div>
        <div class="clickable flexBetween" @click="deleteServerModal = true">
            <img src='../../assets/trash.svg' width="20" alt="" style="margin-right: 3px; margin-left: 3px;"/>
          <span style="word-break: break-word;"> {{ name }} löschen</span>
        </div>
        <div class="clickable flexBetween" @click="addNewServer()" v-if="isSidebarOpen === false && this.canCreateServer === true">
            <img src='../../assets/button_square.svg' width="20" alt="" style="margin-right: 3px; margin-left: 3px;"/>
          <span> Cloud Server erstellen</span>
        </div>
      </div>
    </div>
      
      <div style="font-size: 14px;">
        Hinweis: Die Kostenrechnung basiert auf der tatsächlichen Laufzeit in Stunden: Festplatten und Lizenzen werden während der gesamten VM-Existenz abgerechnet, selbst im ausgeschalteten Zustand, während CPU und RAM nur für die aktive Betriebszeit berechnet werden.
      </div>
      <a-modal
        :title="'Server löschen?'"
        v-model:open="deleteServerModal"
        :closable="true"
        @cancel="deleteServerModal = false"
        @ok="deleteConfig()"
        okText="Ok"
        cancelText="Abbrechen"
        centered>
        Wollen Sie den Server wirklich löschen?
      </a-modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { dialog } from "@/mixins/mixins.js";

export default {
  name: "CloudServer",
  props: {
    serverId: {
      type: Number,
      required: true,
    },
    isLast: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canCreateServer:{
      type: Boolean,
    }
  },
  data() {
    return {
      deleteServerModal: false,
      screenWidth: 1920,
    };
  },
  mixins: [dialog],
  computed: {
    ...mapGetters(["serverById", "selectedServerId", "isSidebarOpen", "getPriceList"]),
    osIcon() {
      let iconUrl = '/' + this.getPriceList.get(this.configuration.os).image
      if (this.configuration.MSSQL_Standard_User_License > 0 || this.configuration.MSSQL_Standard_Core_License > 0 ) {
        iconUrl = '/microsoft-sql-server.png'
      }
      if (this.configuration.MS_Exchange > 0) {
        iconUrl = '/exchange-new.svg'
      }
      
      return iconUrl;
    },
    priceList() {
      return this.getPriceList
    },
    server() {
      return this.serverById(this.serverId);
    },
    configuration() {
      return this.server.configuration;
    },
    name() {
      return this.server.name;
    },
    isConfigurationEmpty() {
      return Object.keys(this.server.configuration).length === 0;
    },
    isVisible() {
      return (
        this.selectedServerId === this.server.id || this.isConfigurationEmpty
      );
    },
    isSmallScreen() {
      return this.screenWidth <= 992;
    },
  },
  methods: {
    ...mapMutations([
      "setSidebarVisibility",
      "setSelectedServer",
      "deleteServer"
    ]),
    addNewServer () {
      this.$emit('addServer')
      this.$emit('changeCreateServerButton')
      setTimeout(() => {
        document.getElementById('addNewCloudServerBtn') !== null ? document.getElementById('addNewCloudServerBtn').scrollIntoView({ behavior: "smooth" }) : false
      }, "200")
    },
    createServer() {
      for (var item of this.priceList) {
        this.server.configuration[item[0]] = 0
      }
      this.server.configuration.os = ""
      this.editServer();
    },
    editServer() {
      this.$emit('changeCreateServerButton')
      this.setSelectedServer(this.server);
      this.setSidebarVisibility(true);
      document.getElementById('productSelectionContainer') !== null ? document.getElementById('productSelectionContainer').scrollIntoView({ behavior: "smooth" }) : false
      //globalThis.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    },
    deleteConfig() {
      this.deleteServer(this.server.id)
      this.setSidebarVisibility(false);
      this.deleteServerModal = false
    },
    changeWidth() {
      this.screenWidth = window.screen.width;
    },
    getImgUrl(pic) {
      return require('../../assets'+pic)
    }
  },
  mounted() {
    this.changeWidth();
    window.addEventListener("resize", this.changeWidth);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.changeWidth);
  },
};
</script>

<style lang="scss" scoped>

.editDeleteCreateServer {
  display: flex; 
  flex-direction: column;  
  font-size: 16px; 
  width: 150px;
}
.mainCloudServer {
  height: 500px;
}
.flexBetween {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.fade-in { animation: fadeIn 1s; }

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
.fade-leave-active {
  transition: opacity 1s;
}

.fade-leave-to {
  opacity: 0;
}
.grey-bar {
  background-color: #4d4d4d;
  position: absolute;
  z-index: -2;
}
.kdz-height {
  display:flex;
  justify-content: flex-end;
  min-height: 250px;
  height: auto;
}
.kdz-config-img {
  position: absolute;
  width: 150px;
}
.kdz-bg-grey {
  background-color: rgba(135, 135, 135, 0.11);
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  margin-bottom: 10px;
}
.panel {
  background: url("../../assets/panel.svg") no-repeat;
  position: absolute;
  width: 150px;
  height: 45px;
  padding: 30px 10px;
  text-align: center;
}
.kdz-server-title {
  font-size: 20px;
  font-weight: bold;
}

.server {
  top: 35%;
  width: 180px;
  left: 40%;
  z-index: 4;

  /* &.kdz-small {
    position: relative;
    width: 50px;
    left: 10%;
    top: 20%;
  } */
}

.panel-cloud {
  top: 25%;
  left: 50px;
  padding-top: 20px;
}

.add-server {
  width: 300px;
  top: 25%;
  left: 10px;
}

.server-cloud {
  top: 0;
  left: 5%;
  width: 300px;
}

.editCloudServer {
  display: flex; 
  flex-direction: column; 
  font-size: 16px; 
  width: 150px;
  height: 40px;
  margin-bottom: 10px;
}

.cpu {
  top: 10%;
  right: 15%;
  opacity: 1;
}

.ram {
  bottom: 10%;
  left: 5%;
}

.ssd {
  top: 20%;
  left: 8%;
  width: 100px;
}

.os {
  bottom: 15%;
  right: 30%;
  z-index: 6;
  width: 100px;
}

.bar-horizontal-ssd {
  width: 150px;
  height: 10px;
  top: 40%;
  left: 15%;
}

.bar-vertical-ssd {
  width: 10px;
  height: 50px;
  top: 31%;
  left: 15%;
}

.bar-horizontal-cpu {
  width: 130px;
  height: 10px;
  top: 43%;
  right: 26%;
}

.bar-vertical-cpu {
  width: 10px;
  height: 60px;
  top: 33%;
  right: 26%;
}

.bar-vertical-ram {
  width: 10px;
  height: 30px;
  bottom: 24%;
  left: 15%;
}

.bar-horizontal-ram {
  width: 150px;
  height: 10px;
  bottom: 23%;
  left: 15%;
}

.clickable {
  cursor: pointer;

  &:hover {
    color: #d02c2a;
  }
}

.firewall {
  z-index: 5;
  width: 50px;
  top: 38%;
  left: 37%;
}
.mainHeight {
  height: 500px;
}

@media (min-width: 1161px) {

  
  .server {
    top: 37%;
    left: 40%;
    z-index: 4;

    &.kdz-small {
      width: 50px;
      left: 10%;
    }
  }

  .panel-cloud {
    top: 30%;
    left: 45%;
  }

  .add-server {
    width: 200px;
    top: 25%;
    left: 60%;
  }

  .server-cloud {
    position: absolute;
    top: 13%;
    left: 18%;
    width: 370px;
  }

  .cpu {
    top: 10%;
    right: 28%;
  }

  .ram {
    bottom: 15%;
    left: 14%;
  }

  .ssd {
    top: 35%;
    left: 20%;
    width: 100px;
  }

  .hdd {
    top: 8%;
    left: 40%;
    width: 100px;
  }

  .os {
    bottom: 18%;
    right: 37.5%;
    width: 100px;
  }
  .office {
  bottom: 15%;
  right: 16%;
  width: 200px;
}

  .firewall {
    width: 50px;
    top: 38%;
    left: 37%;
  }

  .bar-horizontal-ssd {
    width: 150px;
    height: 10px;
    top: 45%;
    left: 27%;
  }

  .bar-vertical-hdd {
    width: 10px;
    height: 60px;
    top: 28%;
    left: 47%;
  }

  .bar-horizontal-cpu {
    width: 130px;
    height: 10px;
    top: 43%;
    right: 36%;
  }

  .bar-vertical-cpu {
    width: 10px;
    height: 60px;
    top: 33%;
    right: 36%;
  }

  .bar-vertical-ram {
    width: 10px;
    height: 40px;
    bottom: 23%;
    left: 25%;
  }

  .bar-horizontal-ram {
    width: 160px;
    height: 10px;
    bottom: 30%;
    left: 25%;
  }
}

@media (max-width: 1200px) {

  .server-cloud {
    position: absolute;
    top: 17%;
    left: 16%;
    width: 370px;
  }
  .panel-cloud {
    top: 35%;
    left: 54%;
  }
  .kdz-config-img {
    position: absolute;
    width: 120px;
  }
  .cpu {
    top: 12%;
    right: 30%;
  }

  .ram {
    bottom: 19%;
    left: 16%;
  }

  .ssd {
    top: 42%;
    left: 8%;
    width: 80px;
  }
  .hdd {
    top: 14%;
    left: 22%;
    width: 80px;
  }

  .os {
    bottom: 27%;
    right: 39%;
    z-index: 4;
    width: 80px;
  }

  .office {
    bottom: 24%;
    right: 15%;
    width: 160px;
  }
  .server {
    top: 37%;
    left: 35%;
    width: 144px;
  }
  .firewall {
    width: 40px;
    top: 38%;
    left: 33%;
  }

  .bar-horizontal-ssd {
    width: 150px;
    height: 10px;
    top: 50%;
    left: 20%;
  }

  .bar-vertical-hdd {
    width: 10px;
    height: 85px;
    top: 25%;
    left: 28%;
  }
  .bar-horizontal-hdd {
    width: 70px;
    height: 10px;
    top: 40%;
    left: 28%;
  }

  .bar-horizontal-cpu {
    width: 130px;
    height: 10px;
    top: 43%;
    right: 39%;
  }

  .bar-vertical-cpu {
    width: 10px;
    height: 60px;
    top: 33%;
    right: 39%;
  }

  .bar-vertical-ram {
    width: 10px;
    height: 40px;
    bottom: 27%;
    left: 25%;
  }

  .bar-horizontal-ram {
    width: 110px;
    height: 10px;
    bottom: 34%;
    left: 25%;
  }
}

@media (max-width: 930px) {

  .mainHeight {
    height: 300px;
  }
  .server-cloud {
    top: 20%;
    left: 16%;
    width: 300px;
  }
  .panel-cloud {
    top: 33%;
    left: 57%;
  }
  .kdz-height {
    display:flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 250px;
    height: 483px;
  }
  .editCloudServer {
    display: flex; 
    flex-direction: row; 
    align-items: center;
    font-size: 14px; 
    width: 450px;
  }

  .mainCloudServer {
    height: 400px;
  }
  .flexBetween {
    align-items: center;
    margin-bottom: 0;
  }

  .cpu {
    top: 8%;
    right: 10%;
  }

  .ram {
    bottom: 30%;
    left: 13%;
  }

  .ssd {
    top: 30%;
    left: 8%;
    width: 80px;
  }
  .hdd {
    top: 5%;
    left: 38%;
    width: 80px;
  }

  .os {
    bottom: 39%;
    right: 30.5%;
    z-index: 4;
    width: 80px;
  }

  .office {
    bottom: 36%;
    right: -2%;
    width: 160px;
  }
  .server {
    top: 25%;
    left: 35%;
    width: 144px;
  }
  .firewall {
    width: 40px;
    top: 27%;
    left: 33%;
  }

  .bar-horizontal-ssd {
    width: 150px;
    height: 10px;
    top: 39%;
    left: 20%;
  }

  .bar-vertical-hdd {
    width: 10px;
    height: 40px;
    top: 22%;
    left: 46%;
  }
  .bar-horizontal-hdd {
    width: 0px;
    height: 0px;
  }

  .bar-horizontal-cpu {
    width: 130px;
    height: 10px;
    top: 38%;
    right: 22%;
  }

  .bar-vertical-cpu {
    width: 10px;
    height: 60px;
    top: 28%;
    right: 22%;
  }

  .bar-vertical-ram {
    width: 10px;
    height: 40px;
    bottom: 36%;
    left: 25%;
  }

  .bar-horizontal-ram {
    width: 110px;
    height: 10px;
    bottom: 43%;
    left: 25%;
  }
  .kdz-server-title {
    font-size: 16px;
  }
}



</style>