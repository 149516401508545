<template>
    <a-spin :spinning="loading">
        <a-modal
        style="height: 50em;"
        v-model:open="showCreateUser"
        :title="'Benutzer erstellen'"
        :closable="false"
        :maskClosable="false"
        cancelText="Abbrechen"
        @cancel="closeModal"
        @ok="handleUserSubmit"
        destroyOnClose
        :confirmLoading="loading"
        centered>
        <a-form
            :ref="formRefCreateUser"
            :model="formCreateUser"
            :rules="rulesCreateUser"
            :loading="loading"
            layout="vertical"
            @finish="handleUserSubmit">
            <!-- WP Implementation -->
            <a-form-item ref="userName" name="userName">
            <template #label>
                <TooltipLabel :title="'Name des Benutzers'" :tooltip="'Name des eigentlichen Benutzer, welches vom System benutzt wird. Keine Sonderzeichen erlaubt um Requestfehler zu verhindern.'"/>
            </template>
            <a-input v-model:value="formCreateUser.userName" :placeholder="'Name des Benutzer'" />
            </a-form-item>
            <a-row :gutter="12">
            <a-col :md="24" :lg="12">
                <a-form-item ref="userPassword" name="userPassword">
                <template #label>
                    <tooltip-label :title="'Passwort'" :tooltip="'Mindestanforderungen: \n- Mindestens 8 Zeichen\n- Mindestens 1 Großbuchstaben\n- Mindestens 1 Kleinbuchstaben\n- Mindestens 1 Zahl\n- Mindestens 1 Sonderzeichen : \n\t!?@#$%^&*()\-_=+{};:,<.>\n- Keine Buchstabe darf mehr als zweimal hintereinander stehen!'"/>
                </template>
                <a-input-password v-model:value="formCreateUser.userPassword" :placeholder="'Passwort'"/>
                </a-form-item>
            </a-col>
            <a-col :md="24" :lg="12">
                <a-form-item ref="userConfirmPassword" name="userConfirmPassword">
                <template #label>
                    <TooltipLabel :title="'Passwort bestätigen'" :tooltip="'Bitte wiederholen Sie das Passwort.'"/>
                </template>
                <a-input-password v-model:value="formCreateUser.userConfirmPassword" :placeholder="'Passwort bestätigen'"/>
                </a-form-item>
            </a-col>
            </a-row>
            <!-- WP Implementation -->
            <!-- City -->
            <a-row :gutter="12">
            <!-- Email -->
            <a-col :md="24" :lg="12">
                <a-form-item ref="userEmail" name="userEmail">
                <template #label>
                    <tooltip-label :title="'E-Mail'" :tooltip="'Bitte geben Sie die E-Mail des Nutzers an.\nEs muss eine gültige Email sein.\nErlaubte Zeichen: \nBuchstaben, Zahlen, -_@.'"/>
                </template>
                <a-input
                    v-model:value="formCreateUser.userEmail"
                    :placeholder="'E-Mail'" />
                </a-form-item>
            </a-col>
            </a-row>
            <a-row :gutter="12">
            <a-col :md="24" :lg="12">
                <a-form-item ref="userFirstname" name="userFirstname">
                <template #label>
                    <tooltip-label :title="'Vorname'" :tooltip="'Bitte geben Sie den Vornamen des Nutzers an.'"/>
                </template>
                <a-input
                    v-model:value="formCreateUser.userFirstname"
                    :placeholder="'Vorname'" />
                </a-form-item>
            </a-col>
            <a-col :md="24" :lg="12">
                <a-form-item ref="userLastname" name="userLastname">
                <template #label>
                    <tooltip-label :title="'Nachname'" :tooltip="'Bitte geben Sie den Nachnamen des Nutzers an.'"/>
                </template>
                <a-input
                    v-model:value="formCreateUser.userLastname"
                    :placeholder="'Nachname'" />
                </a-form-item>
            </a-col>
            </a-row>
        </a-form>
        </a-modal>
    </a-spin>
</template>

<script>
import api from '@/apis/apis'
import { ref, reactive, toRaw } from 'vue'
import TooltipLabel from '@/components/TooltipLabel.vue'
import { notification } from 'ant-design-vue'

export default {
    name:'CreateUser',
    components: {
        TooltipLabel,
        notification,
    },
    props: {
        createUserModal: {
            type: Boolean,
            default: false
        },
        openAccount: {
            type: String,
            default: ""
        },
        openDomainUuid: {
            type: String,
            default: ""
        }
    },
    data () {
        return {
            emailRegex: /^[^@]+@[^@]{2,}\.[^@]{2,}$/,
            passwordRegexUpperCase: /^(?=.*?[A-Z]).*$/, //minimum 1 Upper Case Letter
            passwordRegexLowerCase: /^(?=.*?[a-z]).*$/, //minimum 1 Lower Case Letter
            passwordRegexDigit: /^(?=.*?\d).*$/, //minimum 1 Digit
            passwordRegexSpecialCharacter: /^(?=.*?[!?@#$%^&*()\-_=+{};:,<.>]).*$/, //minimum 1 Special Character
            passwordRegexRepeatCharacter: /^(?:(.)(?!\1{2}))+$/, //not more than 2 repeated Letters
            passwordRegexWrongSpecials: /[`´€µ°²³§[\]'"|,/ß~]/, // special Characters that are not allowed
            passwordRegexLength: /^([A-Za-z0-9!?@#$%^&*()\-_=+{};:,<.>]{8,})$/, //minimum 8 Chars
            specialChars: /^[\u00C0-\u017Fa-zA-Z0-9_.-]*$/,
            specialCharsMail: /[-_@.]/,
            loading:false,
            showCreateUser: false
        }
    },
    watch: {
        createUserModal: function(newVal) {
        if ( newVal === true) {
            this.$emit('reload-users',this.openAccount, this.openDomainUuid)
        }
        this.showCreateUser = newVal
      },
    },
    created () {
        this.initForm()
    },
    methods: {
        initForm () {
            this.formRefCreateUser = ref()
            this.formCreateUser =  reactive({
                userName: undefined,
                userEmail: undefined,
                userPassword: undefined,
                userConfirmPassword: undefined,
                userFirstname: undefined,
                userLastname: undefined
            })
            this.rulesCreateUser = reactive({
                userName: [
                { required: true, message: 'Bitte geben Sie einen Benutzernamen an.' },
                { validator: this.validateAccountName }
                ],
                userEmail: [
                { required: true, message: '' },
                { validator: this.validateEmail }
                ],
                userFirstname: [{ required: true, message: 'Bitte geben Sie den Vornamen des Nutzers an.' }],
                userLastname: [{ required: true, message: 'Bitte geben Sie den Nachnamen des Nutzers an.' }],
                userPassword: [
                { required: true, message: '' },
                { validator: this.validateUserPassword }
                ],
                userConfirmPassword: [
                { required: true, message: '' },
                { validator: this.validateConfirmUserPassword }
                ],
            })
        },
        handleUserSubmit (e) {
            e.preventDefault()
            if (this.loading) return
            this.formRefCreateUser.value.validate().then(async () => {
                const values = toRaw(this.formCreateUser)
                // var newDomainUuid = undefined
                this.loading = true

                var params = new FormData()
                params.append('username', values.userName)
                params.append('password', values.userPassword)
                params.append('email', values.userEmail)
                params.append('firstname', values.userFirstname)
                params.append('lastname', values.userLastname)
                params.append('accountname', this.openAccount)
                params.append('domainuuid', this.openDomainUuid)

                await api.createUser(params).then(response => {
                this.closeModal()
                this.loading = false
                this.$notification.success({
                    message: 'Erfolg',
                    description: 'Der Benutzer wurde erfolgreich erstellt.',
                    duration: 6
                })
                this.$emit('reload-users',this.openAccount, this.openDomainUuid)
                }).catch(error => {
                console.log(error)
                this.closeModal()
                this.loading = false
                this.$notification.error({
                    message: 'Fehler beim Erstellen des Benutzers.',
                    description:
                        'Beim Erstellen eines Benutzers ist ein Fehler aufgetreten, bitte versuchen Sie es erneut. (' + error.response.data.split('):')[1] + ')',
                    duration: 0
                })
                })
            }).catch(error => {
                console.log(error)
                // this.loading = false
            })
        },
        async listUsersForAccounts(accountNameRecord,domainIdRecord){
            let params = new FormData()
            params.append('accountName', accountNameRecord)
            params.append('domainuuid',domainIdRecord)
            await api.listUsers(params).then(response =>{
                this.userData = []
                this.userData = response.data
            }).catch(error => {
                this.userData = []
                console.log("error users: ",error)
            })
        },
        closeModal () {
            this.formCreateUser.userName = undefined
            this.formCreateUser.userEmail = undefined
            this.formCreateUser.userPassword = undefined
            this.formCreateUser.userConfirmPassword = undefined
            this.formCreateUser.userFirstname = undefined
            this.formCreateUser.userLastname = undefined
            this.$emit('close-modal')
        },
        validateAccountName (rule, value) {
            var errorMessage = 'Der Benutzername erfüllt nicht alle Anforderungen!'
            var validated = true;
            if (!this.specialChars.test(value)) {
                validated = false;
                errorMessage += "\nSonderzeichen sind nicht erlaubt!"
            }
            if (value==undefined || value.length < 4 ) {
                validated = false;
                errorMessage += "\nDer Benutzername muss mindestens 4 Zeichen lang sein!"
            }
            if (validated){
                return Promise.resolve()
            } else {
                return Promise.reject(errorMessage)
            }
        },
        validateEmail (rule, value) {
            const errorMessage = 'Bitte geben Sie eine gültige E-Mail an. \nBeispiel: Beispiel@Email1.com\nNur Zahlen, Buchstaben und -_@. sind erlaubt!'
            if (this.emailRegex.test(value) && this.specialCharsMail.test(value)) {
                return Promise.resolve()
            }
            return Promise.reject(errorMessage)
            },
        async validateUserPassword (rule, value) {
            var errorMessage = 'Ihr Passwort erfüllt nicht alle Anforderungen!'
            var validated = true;
            if (!this.passwordRegexLength.test(value)) {
                validated = false;
                errorMessage += "\nMindestens 8 Zeichen"
            }
            if (!this.passwordRegexUpperCase.test(value)) {
                validated = false;
                errorMessage += "\nMindestens 1 Großbuchstaben"
            }
            if (!this.passwordRegexLowerCase.test(value)) {
                validated = false;
                errorMessage += "\nMindestens 1 Kleinbuchstaben"
            }
            if (!this.passwordRegexDigit.test(value)) {
                validated = false;
                errorMessage += "\nMindestens 1 Zahl"
            }
            if (!this.passwordRegexSpecialCharacter.test(value)) {
                validated = false;
                errorMessage += "\nMindestens 1 Sonderzeichen"
            }
            if (this.passwordRegexWrongSpecials.test(value)) {
                validated = false;
                errorMessage += "\nFalsche Sonderzeichen!"
            }
            if (!this.passwordRegexRepeatCharacter.test(value)) {
                validated = false;
                errorMessage += "\nKeine Buchstabe darf sich nicht mehr als zweimal wiederholen!"
            }
            if(value==undefined || this.formCreateUser.userName==undefined || value.toLowerCase().includes(this.formCreateUser.userName.toLowerCase())){
                validated = false;
                errorMessage += "\nDas Passwort darf den Benutzername nicht beinhalten!"
            }
            if (validated){
                return Promise.resolve()
            } else {
                return Promise.reject(errorMessage)
            }
        },
        async validateConfirmUserPassword (rule, value) {
            if (!value || value.length === 0) {
                return Promise.resolve()
            } else if (rule.field === 'userConfirmPassword') {
                const messageConfirm = 'Die Passwortfelder stimmen nicht überein'
                const passwordVal = this.formCreateUser.userPassword
                if (passwordVal && passwordVal !== value) {
                return Promise.reject(messageConfirm)
                } else {
                return Promise.resolve()
                }
            } else {
                return Promise.resolve()
            }
        },
    }
}
</script>