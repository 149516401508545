<template>
    <a-spin :spinning="loading">
        <a-modal
            style="width: 400px;"
            v-model:open="openModal"
            :title="$route.path === '/customers'? 'Angebot duplizieren' :'Angebot speichern'"
            :closable="false"
            :maskClosable="false"
            cancelText="Abbrechen"
            @cancel="closeModal"
            @ok="handleSaveConfigSubmit"
            destroyOnClose
            :confirmLoading="loading"
            centered>
            <a-form
                :ref="formRefSave"
                :model="formSave"
                :rules="rulesSave"
                :loading="loading"
                layout="vertical"
                @finish="handleSaveConfigSubmit">
                <!-- WP Implementation -->
                <a-form-item ref="configName" name="configName">
                    <template #label>
                        <tooltip-label :title="'Angebotsname'" :tooltip="'Geben Sie der abzuspeichernden Konfiguration einen eindeutigen Namen, dieser wird im Angebot angezeigt. Die Maximallänge betragt 60 Zeichen, erlaubt sind nur Buchstaben, Zahlen, ._ und Leerzeichen!'"/>
                    </template>
                    <a-input
                        placeholder="Name des Angebots"
                        id="saveConfigNameInput"
                        v-model:value="formSave.configName"
                    >
                    </a-input>
                </a-form-item>
                <a-form-item ref="configNumber" name="configNumber">
                    <template #label>
                        <span>Angebotsnummer</span>
                    </template>
                    <a-input
                        placeholder="Angebotsnummer"
                        id="saveConfigNumberInput"
                        v-model:value="formSave.configNumber"
                        disabled
                    >
                    </a-input>
                </a-form-item>
                <a-form-item ref="selectedSubAccountForSave" name="selectedSubAccountForSave">
                    <template #label>
                        <tooltip-label :title="'Customer auswählen'" :tooltip="'Bitte wählen Sie den Customer aus, für den Sie das konfigurierte Angebot speichern möchten'"/>
                    </template>
                    <a-select
                        id="subAccountSelect"
                        @change="handleSelectChange"
                        placeholder="Customer auswählen"
                        v-model:value="formSave.selectedSubAccountForSave"
                    >
                        <a-select-option v-for="account in allAccounts" :key="account.id" >
                        {{ account.name}}
                        </a-select-option>
                    </a-select>
                </a-form-item>
            </a-form>
      </a-modal>
    </a-spin>
</template>

<script>
import { notification } from 'ant-design-vue'
import api from '@/apis/apis'
import { ref, reactive, toRaw } from 'vue'
import TooltipLabel from '@/components/TooltipLabel.vue'
import { mapMutations } from "vuex";

export default {
    name:'SaveConfig',
    components: {
      notification,
      TooltipLabel
    },
    props: {
        showSaveConfig: {
            type: Boolean,
            default: false
        },
        accountId: {
            type: String,
            default: ""
        },
        importObjectString: {
            type: String,
            default: ""
        },
        billObject: {
            type: Object,
            default: []
        },
        allAccounts : {
            type: Object,
            default: []
        },
    },
    data () {
        return {
            loading: false,
            configNumberRegex: /^[\u00C0-\u017Fa-zA-Z0-9_.]*$/,
            configNameRegex: /^[\u00C0-\u017Fa-zA-Z0-9_. ]*$/,
            openModal: false
        }
    },
    watch: {
        showSaveConfig: function(newVal) {
        if ( newVal === true) {
            if(this.$route.path !== '/customers') {
                this.formSave.selectedSubAccountForSave = this.$store.getters.getCustomerId
                this.formSave.configName = this.$store.getters.getImportedConfigName
                this.formSave.configNumber = this.$store.getters.getImportedConfigNumber
            }
        }
        this.openModal = newVal
      },
    },
    created () {
        this.initForm()
    },
    methods: {
        ...mapMutations(["setImportedConfigName", "setImportedConfigNumber", "setCustomerId"]),
        initForm () {
            this.formRefSave = ref()
            this.formSave = reactive({})
            this.rulesSave = reactive({
                configName: [{required: true, message: "Erforderlich"}, {validator: this.validateConfigName}],
                selectedSubAccountForSave: [{required: true, message: "Für welchen Account möchten sie das Angebot speichern?"}]
            })
        }, 
        handleSaveConfigSubmit (e) {
            e.preventDefault()
            if (this.loading) return
            const values = toRaw(this.formSave)
            if (this.$store.getters.getConfigImportId && this.$route.path !== '/customers') {
                this.formRefSave.value.validate().then(async () => {
                this.updateConfig()
                this.setCustomerId(values.selectedSubAccountForSave)
                this.setImportedConfigName(values.configName)
                }).catch(error => {
                console.log(error)
                this.loading = false
                })
            } else {
                this.formRefSave.value.validate().then(async () => {
                this.setCustomerId(values.selectedSubAccountForSave)
                this.setImportedConfigName(values.configName)
                var params = new FormData()
                params.append('object', JSON.stringify(this.billObject))
                params.append('importObject', this.importObjectString)
                params.append('resellerUuid', this.accountId)
                params.append('customerUuid', values.selectedSubAccountForSave)
                params.append('configName', values.configName)
                params.append('partnerStatus', this.$store.getters.discountType)
                await api.saveConfigurator(params).then(response =>{
                    if(this.$route.path !== '/customers') {
                        this.$store.commit('setConfigImportId', response.data.configId)
                    }  
                    this.setImportedConfigNumber(response.data.configNumber)
                    this.loading = false
                    this.configButton = true
                    this.$notification.success({
                    message: 'Erfolg!',
                    description: 'Die Konfiguration "' + values.configName + '" wurde erfolgreich gespeichert!',
                    duration: 5
                    })
                    this.closeModal()
                    if (this.$route.path === '/customers') {
                        this.$emit('loadConfigs',2)
                    } 
                }).catch(error => {
                    this.$notification.error({
                    message: 'Es ist ein Fehler aufgetreten!',
                    description: 'Die Konfiguration "' + values.configName + '" konnte nicht erfolgreich gespeichert werden. Bitte versuchen Sie es erneut!',
                    duration: 0
                    })
                    console.log("Error saving config",error)
                    this.loading = false
                    this.closeModal()
                    if (this.$route.path === '/customers') {
                        this.$emit('loadConfigs',2)
                    } 
                })
                }).catch(error => {
                    console.log(error)
                    this.loading = false
                    this.closeModal()
                })
            }
        },
        async updateConfig () {
            const values = toRaw(this.formSave)
            var updateParams = new FormData()
            updateParams.append('record', this.$store.getters.getConfigImportId)
            updateParams.append('object', JSON.stringify(this.$store.getters.bill))
            updateParams.append('importObject', JSON.stringify(this.$store.getters.serverList))
            updateParams.append('customerUuid', values.selectedSubAccountForSave)
            updateParams.append('configName', values.configName)
            updateParams.append('partnerStatus', this.$store.getters.discountType)
            await api.updateConfigurator(updateParams).then(response =>{
                this.loading = false
                this.configButton = true
                this.$notification.success({
                message: 'Erfolg!',
                description: 'Die Konfiguration wurde erfolgreich bearbeitet!',
                duration: 5
                })
                this.closeModal()
                if (this.$route.path === '/customers') {
                    this.$emit('loadConfigs',2)
                } 
            }).catch(error => {
                this.$notification.error({
                message: 'Es ist ein Fehler aufgetreten!',
                description: 'Die Konfiguration konnte nicht erfolgreich gespeichert werden. Bitte versuchen Sie es erneut!',
                duration: 0
                })
                console.log("Error saving config",error)
                this.loading = false
                this.closeModal()
                if (this.$route.path === '/customers') {
                    this.$emit('loadConfigs',2)
                } 
            })
        },
        handleSelectChange(value) {
            var subAccount = this.allAccounts.filter(account => account.id === value)[0]
            if (subAccount !== undefined) {
                this.$store.commit("setCustomerName", subAccount.name)
            }
        },
        closeModal () {
            this.$emit('close-modal')
            if(this.$route.path === "/customers"){
                this.formSave.configName = undefined
                this.formSave.configNumber = undefined
                this.formSave.selectedSubAccountForSave = undefined
            }
        },
        validateConfigName (rule, value) {
            const errorMessage = 'Eine gültiger Konfigurationsname besteht aus Buchstaben, Zahlen ._ und hat maximal 60 Zeichen.'
            if(value === undefined || value.length <= 60) {
                if (this.configNameRegex.test(value)) {
                return Promise.resolve()
                }
            }
            return Promise.reject(errorMessage)
        },
    }
}
</script>