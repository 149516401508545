<template>
  <a-spin :spinning="loading">
    <!-- Delete Modal -->
    <delete-account-and-user-form
      :resourceToDelete="resourceIdent"
      :deletionModal="deleteModalBoolean"
      :resourceName="resourceName"
      :resourceUuid="resourceUuid"
      :userDomainId="userDomainId"
      :accountName="accountName"
      :configuratorId="configuratorId"
      @close-modal="closeModal"
      @reload-user="listUsersForAccounts"
      @reload-accounts="listAccountsForReseller"
      @loadConfigs="loadConfigs"/>
    <!-- EDIT User Modal -->
    <EditUsers
      :edit-user-modal="showEditUser"
      :user-resource="userObj"
      @close-modal="closeModal"
      @reload-user="listUsersForAccounts"/>
    <!-- EDIT Account Modal -->
    <EditAccount
      :accountResource="accountObj"
      :editAccountModal="showEditAccount"
      @close-modal="closeModal"
      @reload-accounts="listAccountsForReseller"/>
    <CreateAccount
      :createAccountModal="showCreateAccount"
      :accountId="this.accountid"
      @close-modal="closeModal"
      @reload-accounts="listAccountsForReseller"/>
    <CreateUser
      :createUserModal="showCreateUser"
      :openAccount="this.openAccount"
      :openDomainUuid="this.openDomainUuid"
      @close-modal="closeModal"
      @reload-users="listUsersForAccounts"/>
    <SaveConfig
      :showSaveConfig="showSaveConfig"
      :importObjectString="this.copiedServerListString"
      :billObject="this.copiedBill"
      :accountId="this.accountid"
      :allAccounts="this.accountData"
      @close-modal="closeModal"
      @loadConfigs="loadConfigs"/>
    <!-- Change Password Modal -->
    <a-modal
      style="height: 50em;"
      v-model:open="showChangePassword"
      :title="'Passwort ändern'"
      :closable="false"
      :maskClosable="false"
      cancelText="Abbrechen"
      @cancel="closeModal"
      @ok="handleChangePasswordSubmit"
      destroyOnClose
      :confirmLoading="loading"
      centered
      >

      <a-form
      :ref="formRefChangePassword"
      :model="formChangePassword"
      :rules="rulesChangePassword"
      layout="vertical"
      @finish="handleChangePasswordSubmit">

      <a-form-item name="password" ref="password">
        <template #label>
          <tooltip-label title="Bitte das neue Passwort eingeben"/>
        </template>
        <a-input-password
          v-model:value="formChangePassword.password"
          placeholder="Bitte das neue Passwort eingeben"
          autocomplete="off"
          />
      </a-form-item> 
      <a-form-item name="confirmpassword" ref="confirmpassword">
        <template #label>
          <tooltip-label title="Passwort bestätigen"/>
        </template>
        <a-input-password
          v-model:value="formChangePassword.confirmpassword"
          placeholder="Bitte das Passwort wiederholen"
          autocomplete="off"
          />
        </a-form-item>
      </a-form>
    </a-modal>

  
    <div class="parentContainer">
      <div class="customerMenuContentContainer">
        <a-tabs v-model:activeKey="activeKey" type="card" @change="loadConfigs">
          <a-tab-pane :key="'1'" tab="Benutzerkonten">
            <div class="customerHeaderContainer">
              <div class="searchFieldCustomers">
                <a-tooltip placement="top">
                  <template #title>
                    {{ 'Benutzerkonto erstellen' }}
                  </template>
                  <a-button id="createAccountBtn" class="createAccountButton" @click="showCreateAccountModal"><PlusOutlined /></a-button>
                </a-tooltip>
                <div>
                <a-input-search class="searchFieldInputCustomers" v-model:value="searchInput" placeholder="Suchtext..." @change="handleSearch()" size="default" >
                  <template #enterButton>
                    <a-button class="resetButton" type="primary" @click="handleSearchReset()">X</a-button>
                  </template>
                </a-input-search>
                </div>
            </div>
            </div>
            <div class="customerTableContainer">
              <a-table class="customerTable" :loading="accountLoading" size="small" :showHeader="false" :columns="accountCols" :data-source="accountData" :rowKey="record => record.id" :expandedRowKeys="expandedKeys" :expandRowByClick="true" :expand-column-width="100" :rowClassName="parentRowClassName" @expand="fetchUsers" :pagination="{ onChange: () => {expandedKeys = []} }">
                <template v-slot:expandedRowRender="{record}">
                  <a-tabs v-model:activeKey="activeKey" type="card">
                    <a-tab-pane :key="'1'" tab="Benutzer">
                      <a-tooltip placement="top">
                        <template #title>
                          {{ 'Benutzer erstellen' }}
                        </template>
                        <a-button class="createUserButton" id="createUserBtn" @click="showCreateUserModal(openAccount,openDomainUuid)"><UserAddOutlined/>Benutzer erstellen</a-button>
                      </a-tooltip>
                      <a-table class="userTable" :loading="userLoading" size="small" :columns="userCols" :data-source="userData">
                        <template v-slot:bodyCell="{record, column}">
                          <template v-if="column.dataIndex === 'edit'">
                            <div>
                              <a-tooltip placement="left">
                                <template #title>
                                  {{ 'Benutzer bearbeiten' }}
                                </template>
                                <a-button @click="showEditUserModal(record.email, record.firstname, record.lastname, record.username, record.id, record.account, record.domainid)" class="tableButton editButton"><EditOutlined /></a-button>
                              </a-tooltip>
                              <a-tooltip placement="top">
                                <template #title>
                                  {{ 'Passwort ändern' }}
                                </template>
                                <a-button @click="showChangePasswordModal(record.id)" class="tableButton"><KeyOutlined /></a-button>
                              </a-tooltip>
                              <a-tooltip placement="right">
                                <template #title>
                                  {{ 'Benutzer löschen' }}
                                </template>
                                <a-button @click="showDeleteAccountOrUserModal($event, record.id, 'Benutzer', record.username, record.domainid, record.account, '')" type="primary" class="tableButton"><DeleteOutlined /></a-button>
                              </a-tooltip>
                            </div>
                          </template>
                        </template>
                      </a-table>
                    </a-tab-pane>
                  </a-tabs>
                </template>
                <template v-slot:bodyCell="{record, column}">
                  <template v-if="column.dataIndex === 'edit'">
                    <div>
                      <a-tooltip placement="left">
                        <template #title>
                          {{ 'Benutzerkonto bearbeiten' }}
                        </template>
                        <a-button @click="showEditAccountModal(record.domainid, record.id, record.name, $event)" class="tableButton editButton"><EditOutlined /></a-button>
                      </a-tooltip>
                      <a-tooltip placement="right">
                        <template #title>
                          {{ 'Benutzerkonto löschen' }}
                        </template>
                        <a-button @click="showDeleteAccountOrUserModal($event, record.id, 'Benutzerkonto', record.name, '', record.name, '')" type="primary" class="tableButton"><DeleteOutlined /></a-button>
                      </a-tooltip>
                    </div>
                  </template>
                </template>
              </a-table>
            </div>
          </a-tab-pane>
          <a-tab-pane :key="'2'" tab="Angebote">
            <div class="search-container">
              <a-input-search class="searchFieldInputOffers" v-model:value="searchInput" placeholder="Suchtext..." @change="handleOffersSearch()" size="default" style="padding-right: 16px; margin-top: 5px; width: 40.2%;" >
                <template #enterButton>
                  <a-button class="resetButton" type="primary" @click="handleSearchReset()">X</a-button>
                </template>
              </a-input-search>
            </div>
            <div class="customerTableContainer">
              <div class="customerTable offersTableContainer">
                <a-table :loading="accountLoading" class="configTable" size="small" :showHeader="true" :columns="configCols" :data-source="currentConfigList" :rowKey="record => record.record" :expandedRowKeys="expandedOfferKeys" :expandRowByClick="true" :rowClassName="parentRowClassNameOffer" @expand="expandOfferTable">
                  <template v-slot:expandedRowRender="{record}">
                    <a-table class="offerDetailTable" :loading="accountLoading" size="small" :columns="configDetailsCols" :data-source="openOffer" :pagination="false">
                      <template v-slot:bodyCell="{record, column}">
                        <template v-if="column.dataIndex === 'totalDiscountValue'">
                          <span>{{ record.totalDiscountValue.toFixed(2) + ' &#x20AC;' }}</span>
                        </template>
                        <template v-if="column.dataIndex === 'totalEK'">
                          <span>{{ record.totalEK.toFixed(2) + ' &#x20AC;' }}</span>
                        </template>
                        <template v-if="column.dataIndex === 'totalEKnoDiscount'">
                          <span>{{ record.totalEKnoDiscount.toFixed(2) + ' &#x20AC;' }}</span>
                        </template>
                        <template v-if="column.dataIndex === 'totalVK'">
                          <span>{{ record.totalVK.toFixed(2) + ' &#x20AC;' }}</span>
                        </template>
                        <template v-if="column.dataIndex === 'margin'">
                          <span>{{ record.margin.toFixed(2) + ' &#x20AC;' }}</span>
                        </template>
                      </template>
                    </a-table>
                    <span>*Alle genannten Preise verstehen sich als Netto</span>
                  </template>
                  <template v-slot:bodyCell="{record, column}">
                    <template v-if="column.dataIndex === 'configName'">
                      <span>{{ record.configName === undefined ? record.created : (record.configName === '' ? record.created : record.configName) }}</span>
                    </template>
                    <template v-if="column.dataIndex === 'customer'">
                      <span>{{ record.customer === '' ? record.created : record.customer }}</span>
                    </template>
                    <template v-if="column.dataIndex === 'configNumber'">
                      <span>{{ record.configNumber }}</span>
                    </template>
                    <template v-if="column.dataIndex === 'state' ">
                      <a-popover placement="left" :key="reloadList">
                        <template #content>
                          <span>Zum Status ändern hier klicken!</span>
                        </template>
                        <a-select
                          :defaultValue="record.state"
                          :bordered="false"
                          :showArrow="false"
                          :key="reloadList"
                          style="width: 140px;"
                          @click="stopProp"
                          @change="handleStatusChange(record.record, $event)">
                          <a-select-option
                            v-for="status in statusList"
                            :key="status.status">
                            <!-- <a-badge :color="status.color" :title="status.status" count=" " size="large" ></a-badge> -->
                            <a-tag style="cursor: pointer;" :color="status.color">
                              {{ status.status }}
                            <template #icon>
                              <check-circle-outlined v-if="status.status === 'Neu'" :spin="status.spin" />
                              <sync-outlined v-else-if="status.status === 'Wartend'" :spin="status.spin" />
                              <euro-outlined v-else-if="status.status === 'Beauftragt'" :spin="status.spin" />
                              <minus-circle-outlined v-else-if="status.status === 'Geschlossen'" :spin="status.spin" />
                            </template>
                            </a-tag>
                          </a-select-option>
                        </a-select>
                      </a-popover>
                    </template>

                    <template v-if="column.dataIndex === 'edit'">
                      <a-dropdown placement="topLeft" :dropdownAlign="{ offset: [-40, 4] }">
                        <a-button type="text" @click="stopProp">
                          <template #icon><MoreOutlined :style="{fontSize: '20px', color: '#9e9e9e', fontWeight: 'bold'}"/></template>
                        </a-button>
                        <template #overlay>
                          <a-menu
                            theme="dark">
                            <a-menu-item
                              :key='0'
                              @click="editOffer(record, $event)"
                            >
                            <EditOutlined style="margin-right: 9px;" />
                            <span>{{ 'Angebot bearbeiten' }}</span>
                            </a-menu-item>
                            <a-menu-item
                              :key='1'
                              @click="getOffer(record, $event)"
                            >
                            <FilePdfOutlined style="margin-right: 9px;" />
                            <span>{{ 'Angebot herunterladen' }}</span>
                            </a-menu-item>
                            <a-menu-item
                              :key='2'
                              @click="getDetailedOffer(record, $event)"
                            >
                            <FileExcelOutlined style="margin-right: 9px;" />
                            <span>{{ 'Angebotsübersicht' }}</span>
                            </a-menu-item>
                            <a-menu-item
                              :key='3'
                              @click="copyOffer(record, $event)"
                            >
                            <CopyOutlined style="margin-right: 9px;" />
                            <span>{{ 'Angebot duplizieren' }}</span>
                            </a-menu-item>
                            <a-menu-item
                              :key='4'
                              @click="showDeleteAccountOrUserModal($event, '', 'Angebot', record.configName, '', '',  record.record)"
                            >
                            <DeleteOutlined style="margin-right: 9px;" />
                            <span>{{ 'Angebot löschen' }}</span>
                            </a-menu-item>
                          </a-menu>
                        </template>
                      </a-dropdown>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
        </a-tab-pane>
      </a-tabs>
      </div>
    </div>
  </a-spin>
</template>

<script>

import api from '@/apis/apis'
import { ref, reactive, toRaw } from 'vue'
import { DeleteOutlined, EditOutlined, BlockOutlined, PlusOutlined, UserAddOutlined, ExportOutlined, FilePdfOutlined, FileExcelOutlined, CheckCircleOutlined,
  SyncOutlined, MinusCircleOutlined, EuroOutlined, KeyOutlined, CopyOutlined, MoreOutlined } from '@ant-design/icons-vue'
import TooltipLabel from '@/components/TooltipLabel.vue'
import DeleteAccountAndUserForm from '../components/forms/DeleteAccountAndUserForm.vue'
import EditUsers from '../components/forms/EditUsers.vue'
import EditAccount from '../components/forms/EditAccount.vue'
import { message, notification } from 'ant-design-vue'
import CreateAccount from '../components/forms/CreateAccount.vue'
import CreateUser from '../components/forms/CreateUser.vue'
import SaveConfig from '../components/forms/SaveConfig.vue'

export default {
  setup () {
    const activeKey  = ref('1')
    return {
      activeKey
    }
  },
  components: {
    DeleteOutlined,
    EditOutlined,
    KeyOutlined,
    ExportOutlined,
    TooltipLabel,
    PlusOutlined,
    DeleteAccountAndUserForm,
    EditUsers,
    EditAccount,
    CreateAccount,
    CreateUser,
    SaveConfig,
    UserAddOutlined,
    FilePdfOutlined,
    FileExcelOutlined,
    notification,
    CheckCircleOutlined,
    SyncOutlined,
    MinusCircleOutlined,
    EuroOutlined,
    CopyOutlined,
    MoreOutlined
  },
  name: 'Customers',
  data () {
    return {
      accountCols: [
        {
          key: 'name',
          dataIndex: 'name',
          width: '40%',
        },
        {
          key: 'edit',
          dataIndex: 'edit',
          align: 'right'
        }
      ],
      accountName: '',
      accountuuid: this.getCookie('account'),
      accountLoading: false,
      disabledLoading: false,
      accountData: [],
      accountObj: {
        accountname: undefined,
        domainuuid: undefined,
        accountuuid: undefined
      },
      createAccountModal: false,
      currentConfigList: [],
      deleteModalBoolean: false,
      emailRegex: /^[^@]+@[^@]{2,}\.[^@]{2,}$/,
      passwordRegexUpperCase: /^(?=.*?[A-Z]).*$/, //minimum 1 Upper Case Letter
      passwordRegexLowerCase: /^(?=.*?[a-z]).*$/, //minimum 1 Lower Case Letter
      passwordRegexDigit: /^(?=.*?\d).*$/, //minimum 1 Digit
      passwordRegexSpecialCharacter: /^(?=.*?[!?@#$%^&*()\-_=+{};:,<.>]).*$/, //minimum 1 Special Character
      passwordRegexRepeatCharacter: /^(?:(.)(?!\1{2}))+$/, //not more than 2 repeated Letters
      passwordRegexWrongSpecials: /[`´€µ°²³§[\]'"|,/ß~]/, // special Characters that are not allowed
      passwordRegexLength: /^.{8,}$/, //minimum 8 Chars
      customerIdRegex: /^[0-9]*$/, //only digits
      contractNumberRegex: /^[\u00C0-\u017Fa-zA-Z0-9_.-]*$/, //only letters, digits, -_.
      cityNameRegex: /^[\u00C0-\u017Fa-zA-Z0-9- ]*$/,
      configNumberRegex: /^[\u00C0-\u017Fa-zA-Z0-9_.]*$/,
      configNameRegex: /^[\u00C0-\u017Fa-zA-Z0-9_. ]*$/,
      expandedKeys: [],
      expandedOfferKeys: [],
      loading: false,
      openAccount: undefined,
      openOffer: [],
      openDomainUuid: undefined,
      resourceIdent: '',
      resourceName: '',
      resourceUuid: '',
      configuratorId: '',
      searchInput: '',
      domainUuid: undefined,
      showCreateAccount: false,
      showCreateUser: false,
      showEditAccount: false,
      showEditUser: false,
      showSaveConfig: false,
      showChangePassword: false,
      userId: '',
      accountid: this.getCookie('account'),
      specialChars: /^[\u00C0-\u017Fa-zA-Z0-9_.-]*$/,
      specialCharsMail: /[-_@.]/,
      reloadList: 1,
      statusList: [
        {
          id: 1,
          status: 'Neu',
          color: 'blue',
          spin: false,
        },
        {
          id: 2,
          status: 'Wartend',
          color: 'yellow',
          spin: true,
        },
        {
          id: 3,
          status: 'Beauftragt',
          color: 'green',
          spin: false,
        },
        {
          id: 4,
          status: 'Geschlossen',
          color: 'grey',
          spin: false,
        },
      ],
      tableBackup: [],
      tableOffersBackup: [],
      userCols: [
        {
          title: 'Benutzer',
          key: 'username',
          dataIndex:'username',
          width: "67.7%"
        },
        {
          title: 'Einstellungen (Benutzer)',
          key: 'edit',
          dataIndex:'edit',
          width: '12em'
        }
      ],
      configCols: [
        {
          title: 'Angebotsname',
          key: 'configName',
          dataIndex:'configName',
          sorter: (a, b) => a.configName.localeCompare(b.configName)
        },
        {
          title: 'Angebotsnummer',
          key: 'configNumber',
          dataIndex:'configNumber',
        },
        {
          title: 'Benutzerkonto',
          key: 'customer',
          dataIndex:'customer',
          width: '18%',
          sorter: (a, b) => a.customer.localeCompare(b.customer)
        },
        {
          title: 'Status',
          key: 'state',
          dataIndex:'state',
          sorter: (a, b) => this.sortState(a,b)
        },
        {
          title: 'Datum',
          key: 'created',
          dataIndex:'created',
        },
        {
          title: 'Aktion',
          key: 'edit',
          dataIndex:'edit',
          align: 'center',
          width: '30px'
        }
      ],
      configDetailsCols: [
        /* {
          title: 'Angebotsname',
          key: 'configName',
          dataIndex:'configName',
          width: "190px",
          sorter: (a, b) => a.configName.localeCompare(b.configName)
        }, */
        {
          title: 'Gesamt EK ohne Rabatt',
          key: 'totalEKnoDiscount',
          dataIndex:'totalEKnoDiscount'
        },
        {
          title: 'Gesamt Rabatt EK',
          key: 'totalDiscountValue',
          dataIndex:'totalDiscountValue'
        },
        {
          title: 'Gesamt EK',
          key: 'totalEK',
          dataIndex:'totalEK'
        },
        {
          title: 'Gesamt VK',
          key: 'totalVK',
          dataIndex:'totalVK'
        },
        {
          title: 'Marge',
          key: 'margin',
          dataIndex:'margin'
        },
      ],
      userData: [],
      userDomainId: '',
      userLoading: false,
      userObj: {
        username: undefined,
        firstname: undefined,
        lastname: undefined,
        email: undefined,
        userUuid: undefined,
        account: undefined,
        domainid: undefined
      },
      year: new Date().getFullYear(),
      copiedBill: [],
      copiedServerListString: ""
    }
  },
  async mounted () {
    await this.listAccountsForReseller()
  },
  created () {
    this.initForm()
  },
  methods: {
    parentRowClassName(record) {
      return this.expandedKeys.includes(record.id) ? 'expanded-parent-row' : '';
    },
    parentRowClassNameOffer(record) {
      return this.expandedOfferKeys.includes(record.record) ? 'expanded-parent-row' : '';
    },
    initForm () {
      this.formRefChangePassword = ref()
      this.formChangePassword = reactive({
        password: undefined,
        confirmpassword: undefined,
      })
      this.rulesChangePassword = reactive({
        password: [
          { required: true, message: 'Bitte geben Sie das neue Passwort ein.' },
          { validator: this.validateChangePassword }
        ],
        confirmpassword: [
          { required: true, message: 'Bitte wiederholen Sie das neue Passwort' },
          { validator: this.validateConfirmUserPassword }
        ]
      })
    },
    async loadConfigs (newActiveKey) {
      if (newActiveKey == '2') {
        this.accountLoading = true
        var params = new FormData()
        params.append('resellerUuid',this.accountuuid)
        await api.listConfigurator(params).then(response =>{
          this.currentConfigList = response.data
          this.tableOffersBackup = response.data
          this.accountLoading = false
        }).catch(error => {
          console.log("error account",error)
          this.accountLoading = false
        })
        this.disabledLoading = true
        await api.getCalculatorAccountPrices(this.accountuuid).then(response =>{
          this.myMap = new Map(Object.entries(response.data))
        }).catch(error => {
          console.log("error account",error)
        })
        // add Popover data and Text to each Resource that needs a popover manually!
        var rawMap = toRaw(this.myMap)
        this.$store.commit('setPriceList', await this.setManualParamsToPriceMap(rawMap))
        await api.listResellerPrices().then(response =>{
          this.$store.commit('setVkPriceList', response.data)
        }).catch(error => {
          console.log("error account",error)
        })
        this.disabledLoading = false
      }
      if (newActiveKey == 'fromSetStatus') {
        this.accountLoading = true
        var params = new FormData()
        params.append('resellerUuid',this.accountuuid)
        await api.listConfigurator(params).then(response =>{
          this.currentConfigList = response.data
          this.tableOffersBackup = response.data
          this.accountLoading = false
        }).catch(error => {
          console.log("error account",error)
          this.accountLoading = false
        })
      }
    },
    showCreateAccountModal () {
      this.showCreateAccount = true
    },
    async setManualParamsToPriceMap (hashMap) {
      for(let key of hashMap.keys()) {
        if (key.includes('NextGenerationFirewall')) {
          // add popover to NGF
          hashMap.get(key).popover = { title: 'Hinweis', content: 'Die Next-Generation-Firewall ist ein mehrstufiges Sicherheitssystem zum Schutz vor modernen Cyberattacken' }
        } else if (key.includes('IP_ADDRESS')) {
          // add popover to IP Address
          hashMap.get(key).popover = { title: 'Hinweis', content: 'Die erste IP-Adresse ist kostenlos.' }
        } else if (key.includes('MS_Exchange')) {
          // add popover and IMG to MS_Exchange licenses
          hashMap.get(key).image = 'exchange-new.svg'
        } else if (key.includes('MSSQL') && key.includes('Core_License')) {
          // add popover and IMG to MSSQL Core licenses
          hashMap.get(key).image = 'microsoft-sql-server.png'
        } else if (key.includes('windows') || key.includes('Windows')) {
          // add popover and IMG Windows licenses
          hashMap.get(key).image = 'windows_icon.png'
        }
      }
      hashMap.set('Linux', { data: { discount: 0 }, label: 'Linux', hoverLabel: 'Linux', price: 0, discount: "0.00", priceWithoutDiscount: 0, order: 0, popover: { title: null, content: null }, image: 'linus.svg', isLicense: true, isOS: true, isResource: false  })
      hashMap.set('libre', { data: { discount: 0 }, label: 'LibreOffice Lizenz', hoverLabel: 'LibreOffice', price: 0, discount: "0.00", priceWithoutDiscount: 0, order: 0, popover: { title: null, content: null }, isLicense: true, isOS: false, isResource: false  })
      return hashMap
    },
    async fetchUsers(expanded, record) {
      var callListUser = true
      if (this.openAccount === record.name) {
        callListUser = false
      }
      this.openAccount = record.name
      this.openDomainUuid = record.domainid
      var keys = []
      if(expanded){
        keys.push(record.id)
        if (callListUser) {
          await this.listUsersForAccounts(record.name,record.domainid)
        }
      }
      this.expandedKeys = keys
    },
    expandOfferTable (expanded, record) {
      var serverObj = JSON.parse(record.calculatorImportObject)
      var vkTotalSum = 0
      var ekTotalSum = 0
      var ekTotalSumWithDiscount = 0
      var ipIsExistent = false
      for (var server of record.calculatorObject) {
        for (var resource of server.bill) {
          if (resource.resourceId === 0) {
            continue
          }
          // container RAM and COntainer CPU
          if (resource.resourceId === 1005 || resource.resourceId === 1004) {
            var replicaValue = serverObj.filter(item => item.id === server.id)[0] === undefined ? 1 : serverObj.filter(item => item.id === server.id)[0].configuration["Replicas"]
            vkTotalSum += (resource.vksinglePrice * resource.count * replicaValue)
          } else {
            vkTotalSum += (resource.vksinglePrice * resource.count)
          }
          ekTotalSum += (resource.originalPrice)
          ekTotalSumWithDiscount += (resource.price)
          // subtract one ip adress if value is not 0 (first ip is free!)
          if (resource.resourceId === 3 && !ipIsExistent) {
            ipIsExistent = true
            if (resource.vksinglePrice !== 0) {
              vkTotalSum -= resource.vksinglePrice
            } else if (resource.originalPrice !== 0) {
              ekTotalSum -= (resource.originalPrice / resource.count)
            } else if (resource.price !== 0) {
              ekTotalSumWithDiscount -= (resource.price / resource.count)
            }
          }
        }
      }
      var offerDetailObject = {}
      offerDetailObject.totalEKnoDiscount = ekTotalSum
      offerDetailObject.totalDiscountValue = (ekTotalSum - ekTotalSumWithDiscount)
      offerDetailObject.totalEK = ekTotalSumWithDiscount
      offerDetailObject.totalVK = vkTotalSum
      offerDetailObject.margin = (vkTotalSum - ekTotalSumWithDiscount)
      this.openOffer = [offerDetailObject]
      var keys = []
      if(expanded){
        keys.push(record.record)
        /* if (callListUser) {
          await this.listUsersForAccounts(record.name,record.domainid)
        } */
      }
      this.expandedOfferKeys = keys
    },
    getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    getOffer(record, event){
      event.stopPropagation()
      if((record != undefined) && (record.calculatorObject != null)) {
        var params = new FormData()
        params.append('object', JSON.stringify(record.calculatorObject))
        params.append('account', this.getCookie('account'))
        params.append('customerId', record.customerId)
        params.append('offerName', record.configName)
        params.append('offerNumber', record.configNumber)
        params.append('customerName', record.customer)
        api.getPdf(params).then(response => {
          let filename = response.headers['content-disposition'].split("filename=")[1]
        let url = window.URL.createObjectURL(new Blob([response.data]));
        let link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename); //or any other extension
        document.body.appendChild(link);
        link.click();
          this.$notification.success({
            message: 'Angebot wurde erstellt!',
          })
        }).catch(error => {
          console.log(error)
        })
      }
      else{
        message.error("Kein Angebot vorhanden")
      }

    },
    getDetailedOffer (record, event) {
      event.stopPropagation()
      // TODO Call API to create Detailed Offer XLS File
      if (record !== undefined) {
        api.getExcelOffer(record.record).then(response => {
          let filename = response.headers['content-disposition'].split("filename=")[1]
          let url = window.URL.createObjectURL(new Blob([response.data]));
          let link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename); //or any other extension
          document.body.appendChild(link);
          link.click();
          this.$notification.success({
            message: 'Angebotsübersicht wurde erstellt!',
          })
          }).catch(error => {
            console.log(error)
          })
        } else {
          message.error("Kein Angebot vorhanden")
        }
    },
    async listAccountsForReseller(){
      this.accountLoading = true
      this.accountuuid = this.getCookie('account')
      await api.listAccounts(this.accountuuid).then(response =>{
        this.accountData = response.data
        this.tableBackup = response.data
        this.accountLoading = false
      }).catch(error => {
        console.log("error account",error)
        this.accountLoading = false
      })
    },
    async listUsersForAccounts(accountNameRecord,domainIdRecord){
      let params = new FormData()
      params.append('accountName', accountNameRecord)
      params.append('domainuuid',domainIdRecord)
      await api.listUsers(params).then(response =>{
        this.userData = []
        this.userData = response.data
      }).catch(error => {
        this.userData = []
        console.log("error users: ",error)
      })
    },
    showEditAccountModal (domainUuid, accountuuid, accountName, event) {
      event.stopPropagation()
      this.accountObj.domainuuid = domainUuid
      this.accountObj.accountuuid = accountuuid
      this.accountObj.accountname = accountName
      this.showEditAccount = true
    },
    showDeleteAccountOrUserModal (event, uuid, resourceIdent, name, userDomainId, accountName, configuratorId) {
      event.stopPropagation()
      this.deleteModalBoolean = true
      this.resourceIdent = resourceIdent
      this.resourceName = name
      this.resourceUuid = uuid
      this.userDomainId = userDomainId
      this.accountName = accountName
      this.configuratorId = configuratorId.toString()
    },
    showCreateUserModal(accountName, domainuuid) {
      this.openAccount = accountName
      this.openDomainUuid = domainuuid
      this.showCreateUser = true
    },
    showEditUserModal (userEmail, userFirstname, userLastname, userName, userUuid, userAccount, userDomainid) {
      this.userObj.email = userEmail
      this.userObj.firstname = userFirstname
      this.userObj.lastname = userLastname
      this.userObj.username = userName
      this.userObj.userUuid = userUuid
      this.userObj.account = userAccount
      this.userObj.domainid = userDomainid
      this.showEditUser = true
    },
    showChangePasswordModal(recordId){
      this.userId = recordId
      this.showChangePassword = true
    },
    stopProp (event) {
      event.stopPropagation()
    },
    async handleStatusChange (configId, e) {
      var statusParams =  new FormData()
      statusParams.append('id', configId)
      statusParams.append('status', e)
      await api.updateOfferStatus(statusParams).then(response => {
      }).catch(error => {
        this.$notification.error({
          message: 'Fehler beim Ändern des Angebotsstatus',
          description:
              'Der Status konnte nicht bearbeitet werden. Laden Sie die Seite neu und versuchen Sie es bitte erneut. (' + error.response.data + ')',
          duration: 0
        })
      })
      this.loadConfigs('fromSetStatus')
    },
    async fillCustomVKPrices (calcObj) {
      var pushToVkPrices = []
      for (var server of calcObj) {
        for (var resource of server.bill) {
          if (resource.resourceId === 0) {
            continue
          }
          if (pushToVkPrices.filter(vkPriceObject => vkPriceObject.id === resource.resourceId).length > 0) {
            continue
          }
          var tempobject = { id: resource.resourceId, customPrice: resource.vksinglePrice }
          pushToVkPrices.push(tempobject)
        }
      }
      this.$store.commit('overWriteCustomVkPrices', pushToVkPrices)
    },
    async setFireWallAndIps (recordInput) {
      for (var config of recordInput.calculatorObject) {
        if (config.id === 'firewall') {
          if (config.bill[0].count === 1) {
            this.$store.commit('setFirewall', true)
          } else if (config.bill[0].count === 2) {
            this.$store.commit('setFirewall', true)
            this.$store.commit('setFirewall2', true)
          }
        }
        if (config.id === 'ip') {
          this.$store.commit('setIp', config.bill[0].count)
        }
      }
    },
    setPartnerStatus (status) {
      var selectedDiscount
      var inputStatus
      if (status !== null && status !== undefined && status !== '' && status.length > 0) {
        inputStatus = status
      } else {
        inputStatus = this.$store.getters.originalDiscountType
      }
      this.$store.commit("setDiscountType", inputStatus)
        switch (inputStatus) {
          case 'Holz': selectedDiscount = 20
            break
          case 'Bronze': selectedDiscount = 30
            break
          case 'Silber': selectedDiscount = 35
            break
          case 'Gold': selectedDiscount = 40
            break
          case 'Platin': selectedDiscount = 47
            break
          default: selectedDiscount = 0
        }
        this.$store.commit("setPartnerDiscount", selectedDiscount)
    },
    async editOffer(recordInput, event) {
      this.setPartnerStatus(recordInput.partnerStatus)
      event.stopPropagation()
      await this.fillCustomVKPrices(toRaw(recordInput.calculatorObject))
      this.$store.commit('setConfigImportId', recordInput.record)
      this.$store.commit('setImportedConfigName', recordInput.configName)
      this.$store.commit('setImportedConfigNumber', recordInput.configNumber)
      this.$store.commit('setCustomerId', recordInput.customerId)
      this.$store.commit('setCustomerName', recordInput.customer)
      var myObj = JSON.parse(recordInput.calculatorImportObject)
      this.$store.commit('setServerList', myObj)
      await this.setFireWallAndIps(recordInput)
      // sett tabs to "calculator"
      this.$store.commit('setCurrentMenuTab', ['2'])
      this.$router.push({ path: '/prices'})
    },
    copyOffer (record, event) {
      event.stopPropagation()
      this.showSaveConfig = true
      this.accountid = record.resellerId,
      this.copiedBill = record.calculatorObject
      this.copiedServerListString = record.calculatorImportObject
    },
    async handleChangePasswordSubmit () {

      if (this.loading) return
       this.formRefChangePassword.value.validate().then(async () => {
        const values = toRaw(this.formChangePassword)
        this.loading = true

        let params = new FormData()
        params.append("useruuid",this.userId)
        params.append("password", values.password)
        params.append("confirmPassword", values.confirmpassword)
        await api.changeUserPassword(params).then(response =>{
          this.loading = false
          this.formChangePassword.password = ''
          this.formChangePassword.confirmpassword = ''
          this.$notification.success({
            message: 'Erfolg',
            description: 'Das Passwort wurde erfolgreich geändert.',
            duration: 6
          })
          this.closeModal()
        }).catch(error => {
          this.loading = false
          this.formChangePassword.password = ''
          this.formChangePassword.confirmpassword = ''
          this.$notification.error({
            message: "Fehler",
            description: error.response.data.toString(),
            duration: 6
          })
          this.closeModal()
        })
        }).catch(error => {
          this.formChangePassword.password = ''
          this.formChangePassword.confirmpassword = ''
          var errorString = "Unbekannter Fehler"
          if(error.errorFields.length > 0) {
            errorString = error.errorFields[0].errors
            if(errorString[0].includes("Sonderzeichen")){
              errorString += "Folgende Sonderzeichen sind erlaubt: ?=.*?[!?@#$%^&*()\-_=+{};:,<.>]"
            }
          }
          this.$notification.error({
            message: "Fehler",
            description: errorString,
            duration: 6
          })
          this.loading = false
          this.closeModal()
      })
    },
    handleSearch () {
      this.filteredList = this.tableBackup.filter( x => x.name.toString().toLowerCase().includes(this.searchInput.toString().toLowerCase()))
      this.current = 1
      this.expandedKeys = []
      this.accountData = this.filteredList
      if (this.searchInput === '') {
        this.accountData = this.tableBackup
      }
    },
    handleOffersSearch () {
      this.filteredList = this.tableOffersBackup.filter( x => x.configName.toString().toLowerCase().includes(this.searchInput.toString().toLowerCase())
        || x.customer.toString().toLowerCase().includes(this.searchInput.toString().toLowerCase()) || (x.configNumber!= undefined && x.configNumber.toString().toLowerCase().includes(this.searchInput.toString().toLowerCase())))
      this.current = 1
      this.reloadList += 1
      this.currentConfigList = this.filteredList
      if (this.searchInput === '') {
        this.currentConfigList = this.tableOffersBackup
      }
    },
    handleSearchReset() {
      this.searchInput = ''
      this.accountData = this.tableBackup
      this.currentConfigList = this.tableOffersBackup
    },
    closeModal () {
      this.showCreateAccount = false
      this.showEditAccount = false
      this.showEditUser = false
      this.showChangePassword = false
      this.showSaveConfig = false
      this.showCreateUser = false
      this.deleteModalBoolean = false
      this.resourceIdent = ''
      this.resourceName = ''
      this.resourceUuid = ''
      this.configuratorId = ''
      this.userDomainId = ''
      this.accountName = ''
      this.formChangePassword.confirmpassword = ''
      this.formChangePassword.password = ''
    },
    handleTableChange () {
      this.reloadList += 1
    },
    sortState(a, b){
      for (var i=0; i<this.statusList.length; i++){
        if(this.statusList[i].status == a.state){
          var stateA = this.statusList[i].id
        }
        if(this.statusList[i].status == b.state){
          var stateB = this.statusList[i].id
        }
      }
      return stateA < stateB ? -1 : (stateA == stateB ? 0 : 1)
    },
    async validateChangePassword (rule, value) {
      var errorMessage = 'Ihr Passwort erfüllt nicht alle Anforderungen!'
      var validated = true;
      if (!this.passwordRegexLength.test(value)) {
        validated = false;
        errorMessage += "\nMindestens 8 Zeichen"
      }
      if (!this.passwordRegexUpperCase.test(value)) {
        validated = false;
        errorMessage += "\nMindestens 1 Großbuchstaben"
      }
      if (!this.passwordRegexLowerCase.test(value)) {
        validated = false;
        errorMessage += "\nMindestens 1 Kleinbuchstaben"
      }
      if (!this.passwordRegexDigit.test(value)) {
        validated = false;
        errorMessage += "\nMindestens 1 Zahl"
      }
      if (!this.passwordRegexSpecialCharacter.test(value)) {
        validated = false;
        errorMessage += "\nMindestens 1 Sonderzeichen"
      }
      if (this.passwordRegexWrongSpecials.test(value)) {
        validated = false;
        errorMessage += "\nFalsche Sonderzeichen!"
      }
      if (!this.passwordRegexRepeatCharacter.test(value)) {
        validated = false;
        errorMessage += "\nKeine Buchstabe darf sich nicht mehr als zweimal wiederholen!"
      }
      if (validated){
        return Promise.resolve()
      } else {
        return Promise.reject(errorMessage)
      }
    },
  }
}
</script>

<style scoped>
  .settingsMenuBtn {
    font-size: 16px;
  }
  .search-container {
    display: flex;
    justify-content: flex-end;
  }
  .customerTableContainer {
    width: 1036px;
  }
  .customerHeaderContainer {
    width: 1036px;
  }
  .offersTableContainer {
    margin-top: 15px;
    width: 1036px;
  }
  .searchFieldInputOffers {
    width: 416px;
    padding-right: 16px;
  }
  @media (min-width: 1130px) {
    .customerMenuContentContainer {
      width: 1130px;
    }
  }  @media (max-width: 1036px) {
    .customerHeaderContainer {
      width: 80%;
      margin-left: 5%;
    }
    .customerTableContainer {
      width: 80%;
      margin-left: 5%;
    }
    .offersTableContainer {
      width: 80%;
      margin-left: 5%;
    }
    .search-container{
      width: 80%;
      margin-left: 5%;
    }
  }

  @media (max-width: 700px) {
    .tableButton {
      width: 45px;
    }
  }

  @media (max-width: 768px) {
    .searchFieldInputCustomers {
      width: 300px;
    }
    .searchFieldInputOffers {
      width: 200px;
    }
  }
  @media (max-width: 643px) {
    .createAccountButtontext {
      display: none;
    }
    .searchFieldInputCustomers {
      width: 350px;
    }
    .searchFieldInputOffers {
      width: 250px;
    }
  }
  @media (max-width: 524px) {
    .customerHeaderContainer {
      width: 90%;
      margin-left: 5%;
    }
    .customerTableContainer {
      width: 90%;
      margin-left: 5%;
    }
    .offersTableContainer {
      width: 90%;
      margin-left: 5%;
    }
    .search-container{
      width: 90%;
      margin-left: 5%;
    }
    .tableButton {
      width: 45px;
    }
    .editButton {
      margin-bottom : 5px;
    }
    .createAccountButton {
      margin-left: 7px;
    }
  }
  @media (max-width: 451px) {
    .searchFieldInputCustomers {
      width: 300px;
    }
    .searchFieldInputOffers {
      width: 200px;
    }
  }
</style>
