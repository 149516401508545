<template>
  <div>
    <div  style="font-size: 16px; text-align: left;" v-if="$store.getters.getImportedConfigName">
    <div style="font-weight: bold">{{ 'Angebot in Bearbeitung: ' }}</div>
    <div>Angebot: {{ $store.getters.getImportedConfigName }}</div>
    <div v-if="$store.getters.getImportedConfigNumber">Angebotsnummer: {{ $store.getters.getImportedConfigNumber }}</div>
    <div v-else>Angebotsnummer: -</div>
    </div>
    <div :key="updateIndex" class="kdz-sidebar-head px-2 py-4">
      <div>Kosten&shy;übersicht</div>
      <div style="display: flex; align-self: top; height: 100%;">
        <a-button
        
          @click="this.$emit('openCancelConfigModal')"
          class="exit-button"
          size="small"
          v-if="!isRoot"
          type="primary"
        >
          X
        </a-button>
      </div>
    </div>
    <div class="kdz-cost-list">
      <template v-for="(type, index) in serverTypes" >
        <div v-if="checkServerType(type) || (type=='iaas' && ($store.getters.firewall || $store.getters.ip > 0)) || (type=='wpCloud' && $store.getters.firewall2)" class="serverTypeHeader">{{ serverTypeNames[serverTypes[index]] }}</div>
        <template v-if="type== 'iaas'">
          <!-- iaas firewall active and ips selected -->
            <template v-if="$store.getters.firewall && $store.getters.ip > 0">
              <div
                style="justify-content: space-between;"
                v-if="bill[0].bill[0].type !== 'network'"
                :class="[ 'kdz-last-element', 'kdz-cost-border']"
              >
                <a-popover placement="left" :title="(bill[0].bill[0].UsageForm.replace('(','') + 'Netto')" v-if="this.windowWidth>790" >
                  <span class="hoverLabel">{{ bill[0].bill[0].label }}</span>
                </a-popover>
                <a-popover placement="top" :title="(bill[0].bill[0].UsageForm.replace('(','') + 'Netto')" v-if="this.windowWidth<=790" >
                  <span class="hoverLabel">{{ bill[0].bill[0].label }}</span>
                </a-popover>

                <span style="width: 30%; display: inline-flex; justify-content: right; float: right" cols="5" class="text-right" align-self="center">
                  <i>{{ ((typeof bill[0].bill[0].originalVkPrice === 'string' || bill[0].bill[0].originalVkPrice instanceof String) ? bill[0].bill[0].originalVkPrice : bill[0].bill[0].originalVkPrice.toFixed(2)).toString().replace("." , ",") }}</i> &euro;&nbsp;
                </span>
                <span v-if="bill[0].bill[0].resourceId === undefined" style="width: 30%; display: flex;" align-self="center">
                  <i>{{ bill[0].bill[0].vkSinglePrice.toFixed(2).toString().replace('.' , ',') }}</i> &euro;&nbsp;
                </span>
                <a-input-number v-else style="width: 50%;" :min="0" decimalSeparator="," :controls="false" v-model:value="bill[0].bill[0].vkSinglePrice" @change="handleEnter($event, bill, bill[0].bill[0].resourceId)" placeholder="Kein Preis verfügbar"  />
              </div>
              <div
                style="justify-content: space-between;"
                v-if="bill[1].bill[0].type !== 'network'"
                :class="[ 'kdz-last-element', 'kdz-cost-border']"
              >
                <a-popover placement="left" :title="(bill[1].bill[0].UsageForm.replace('(','') + 'Netto')" v-if="this.windowWidth>790" >
                  <span class="hoverLabel">{{ bill[1].bill[0].label.replace('x2' , 'x1') }}</span>
                </a-popover>
                <a-popover placement="top" :title="(bill[1].bill[0].UsageForm.replace('(','') + 'Netto')" v-if="this.windowWidth<=790" >
                  <span class="hoverLabel">{{ bill[1].bill[0].label.replace('x2' , 'x1') }}</span>
                </a-popover>
                <span style="width: 30%; display: inline-flex; justify-content: right; float: right" cols="5" class="text-right" align-self="center">
                  <i>{{ (typeof bill[1].bill[0].originalVkPrice === 'string' || bill[1].bill[0].originalVkPrice instanceof String) ? bill[1].bill[0].originalVkPrice : ($store.getters.firewall2 ? (bill[1].bill[0].originalVkPrice / 2).toFixed(2).toString().replace('.' , ',') : bill[1].bill[0].originalVkPrice.toFixed(2).toString().replace('.' , ',')) }}</i> &euro;&nbsp;
                </span>
                <span v-if="bill[1].bill[0].resourceId === undefined" style="width: 30%; display: flex;" align-self="center">
                  <i>{{ $store.getters.firewall2 ? (bill[1].bill[0].vkSinglePrice / 2).toFixed(3).toString().replace('.' , ',') : bill[1].bill[0].vkSinglePrice.toFixed(2).toString().replace('.' , ',') }}</i> &euro;&nbsp;
                </span>
                <a-input-number v-else style="width: 50%;" :min="0" decimalSeparator="," :controls="false" v-model:value="bill[1].bill[0].vkSinglePrice" @change="handleEnter($event, bill, bill[1].bill[0].resourceId)" placeholder="Kein Preis verfügbar"  />
              </div>
            </template>
            <!-- iaas either firewall or ips are selected -->
            <template v-else-if="$store.getters.ip > 0 || $store.getters.firewall">
              <div
                style="justify-content: space-between;"
                v-if="bill[0].bill[0].type !== 'network'"
                :class="[ 'kdz-last-element', 'kdz-cost-border']"
              >
                <a-popover placement="left" :title="(bill[0].bill[0].UsageForm.replace('(','') + 'Netto')" v-if="this.windowWidth>790" >
                  <span class="hoverLabel">{{ $store.getters.ip > 0 ? bill[0].bill[0].label : (bill[0].bill[0].label.replace('x2' , 'x1')) }}</span>
                </a-popover>
                <a-popover placement="top" :title="(bill[0].bill[0].UsageForm.replace('(','') + 'Netto')" v-if="this.windowWidth<=790" >
                  <span class="hoverLabel">{{ $store.getters.ip > 0 ? bill[0].bill[0].label : (bill[0].bill[0].label.replace('x2' , 'x1')) }}</span>
                </a-popover>
                <span style="width: 30%; display: inline-flex; justify-content: right; float: right" cols="5" class="text-right" align-self="center">
                  <i>{{ (typeof bill[0].bill[0].originalVkPrice === 'string' || bill[0].bill[0].originalVkPrice instanceof String) ? bill[0].bill[0].originalVkPrice : ($store.getters.firewall2 ? (bill[0].bill[0].originalVkPrice / 2).toFixed(2).toString().replace('.' , ',') : bill[0].bill[0].originalVkPrice.toFixed(2).toString().replace('.' , ','))  }}</i> &euro;&nbsp;
                </span>
                <span v-if="bill[0].bill[0].resourceId === undefined" style="width: 30%; display: flex;" align-self="center">
                  <i>{{ $store.getters.ip > 0 ? bill[0].bill[0].originalVkPrice.toFixed(2).toString().replace('.' , ',') : ($store.getters.firewall2 ? (bill[0].bill[0].originalVkPrice / 2).toFixed(2).toString().replace('.' , ',') : bill[0].bill[0].originalVkPrice.toFixed(2).toString().replace('.' , ',')) }}</i> &euro;&nbsp;
                </span>
                <a-input-number v-else style="width: 50%;" :min="0" decimalSeparator="," :controls="false" v-model:value="bill[0].bill[0].vkSinglePrice" @change="handleEnter($event, bill, bill[0].bill[0].resourceId)" placeholder="Kein Preis verfügbar"  />
              </div>
            </template>
          </template>
          <!-- special case regarding firewall wpCLoud -->
          <template v-if="type== 'wpCloud'">
            <!-- wpcloud firewall active and ips selected -->
            <template v-if="$store.getters.firewall2 && $store.getters.ip > 0">
              <div
                style="justify-content: space-between;"
                v-if="bill[1].bill[0].type !== 'network'"
                :class="[ 'kdz-last-element', 'kdz-cost-border']"
              >
                <a-popover placement="left" :title="(bill[1].bill[0].UsageForm.replace('(','') + 'Netto')" v-if="this.windowWidth>790" >
                  <span class="hoverLabel">{{ bill[1].bill[0].label.replace('x2' , 'x1') }}</span>
                </a-popover>
                <a-popover placement="top" :title="(bill[1].bill[0].UsageForm.replace('(','') + 'Netto')" v-if="this.windowWidth<=790" >
                  <span class="hoverLabel">{{ bill[1].bill[0].label.replace('x2' , 'x1') }}</span>
                </a-popover>
                <span style="width: 30%; display: inline-flex; justify-content: right; float: right" cols="5" class="text-right" align-self="center">
                  <i>{{ (typeof bill[1].bill[0].originalVkPrice === 'string' || bill[1].bill[0].originalVkPrice instanceof String) ? bill[1].bill[0].originalVkPrice : ($store.getters.firewall ? (bill[1].bill[0].originalVkPrice / 2).toFixed(2).toString().replace('.' , ',') : bill[1].bill[0].originalVkPrice.toFixed(2).toString().replace('.' , ','))  }}</i> &euro;&nbsp;
                </span>
                <span v-if="bill[1].bill[0].resourceId === undefined" style="width: 30%; display: flex;" align-self="center">
                  <i>{{ $store.getters.firewall ? (bill[1].bill[0].originalVkPrice / 2).toFixed(2).toString().replace('.' , ',') : bill[1].bill[0].originalVkPrice.toFixed(2).toString().replace('.' , ',') }}</i> &euro;&nbsp;
                </span>
                <a-input-number v-else style="width: 50%;" :min="0" decimalSeparator="," :controls="false" v-model:value="bill[1].bill[0].vkSinglePrice" @change="handleEnter($event, bill, bill[1].bill[0].resourceId)" placeholder="Kein Preis verfügbar"  />
              </div>
            </template>
            <!-- wpCloud firewall active and ips unselected -->
            <template v-else-if="$store.getters.firewall2">
              <div
                style="justify-content: space-between;"
                v-if="bill[0].bill[0].type !== 'network'"
                :class="['kdz-last-element','kdz-cost-border']"
              >
                <a-popover placement="left" :title="(bill[0].bill[0].UsageForm.replace('(','') + 'Netto')" v-if="this.windowWidth>790" >
                  <span class="hoverLabel">{{ bill[0].bill[0].label.replace('x2' , 'x1') }}</span>
                </a-popover>
                <a-popover placement="top" :title="(bill[0].bill[0].UsageForm.replace('(','') + 'Netto')" v-if="this.windowWidth<=790" >
                  <span class="hoverLabel">{{ bill[0].bill[0].label.replace('x2' , 'x1') }}</span>
                </a-popover>
                <span style="width: 30%; display: inline-flex; justify-content: right; float: right" cols="5" class="text-right" align-self="center">
                  <i>{{ (typeof bill[0].bill[0].originalVkPrice === 'string' || bill[0].bill[0].originalVkPrice instanceof String) ? bill[0].bill[0].originalVkPrice : ($store.getters.firewall ? (bill[0].bill[0].originalVkPrice / 2).toFixed(2).toString().replace('.' , ',') : bill[0].bill[0].originalVkPrice.toFixed(2).toString().replace('.' , ',')) }}</i> &euro;&nbsp;
                </span>
                <span v-if="bill[0].bill[0].resourceId === undefined" style="width: 30%; display: flex;" align-self="center">
                  <i>{{ $store.getters.firewall ? (bill[0].bill[0].originalVkPrice / 2).toFixed(2).toString().replace('.' , ',') :  bill[0].bill[0].originalVkPrice.toFixed(2).toString().replace('.' , ',') }}</i> &euro;&nbsp;
                </span>
                <a-input-number v-else style="width: 50%;" :min="0" decimalSeparator="," :controls="false" v-model:value="bill[0].bill[0].vkSinglePrice" @change="handleEnter($event, bill, bill[0].bill[0].resourceId)" placeholder="Kein Preis verfügbar"  />
              </div>
            </template>
          </template>
        <template v-for="(pos, index) in bill" :key="index + pos.serverName">
          <!-- sorter for servertypes-->
          <template v-if="pos.type === type  || (pos.type=='containerIaaS' && type == 'iaas')">
            <!-- serverHeader unfolded -->
            <template v-if="showCosts.includes(pos.id) || this.selectedServer.id == pos.id">
              <div class="serverHeader" style="display: flex; cursor: pointer;" @click="showCosts.splice(showCosts.indexOf(pos.id), 1)">
                <strong v-html="pos.serverName.includes('()') ? pos.serverName.replace(' <small>()</small>','') : pos.serverName"></strong>
                <div style="text-align: right; flex-grow: 4;">
                  <UpOutlined />
                </div>
              </div>
            </template>
            <!-- ServerHeader folded -->
            <template v-else>
              <div :class="['bottomBorderFolded', 'serverHeader']" style="display: flex; cursor: pointer;" @click="showCosts.push(pos.id)">
                <strong v-html="pos.serverName.includes('()') ? pos.serverName.replace(' <small>()</small>','') : pos.serverName"></strong>
                <div style="text-align: right; flex-grow: 4;">
                  <DownOutlined />
                </div>
              </div>
            </template>
            <!-- invitems unfolded-->
              <template v-for="(invItem, ind) in pos.bill">
                <template v-if="showCosts.includes(pos.id) || this.selectedServer.id == pos.id">
                  <div
                    style="justify-content: space-between;"
                    :key="ind + '_' + index + pos.serverName"
                    v-if="invItem.type !== 'network'"
                    :class="[{ 'kdz-last-element': ind === pos.bill.length - 1 }, 'kdz-cost-border']"
                  >
                    <a-popover placement="left" :title="(invItem.UsageForm.replace('(','') + 'Netto')" v-if="this.windowWidth>790" >
                      <span class="hoverLabel">{{ invItem.label }}</span>
                    </a-popover>
                    <a-popover placement="top" :title="invItem.hoverLabel + ' = ' + invItem.singlePrice.toString().replace('.' , ',') + '€ '" v-if="this.windowWidth<=790" >
                      <span class="hoverLabel">{{ invItem.label }}</span>
                    </a-popover>

                    <span style="width: 30%; display: inline-flex; justify-content: right; float: right" cols="5" class="text-right" align-self="center">
                      <i>{{ ((typeof invItem.originalVkPrice === 'string' || invItem.originalVkPrice instanceof String) ? invItem.originalVkPrice : invItem.originalVkPrice.toFixed(2)).toString().replace("." , ",") }}</i> &euro;&nbsp;
                    </span>
                    <span v-if="invItem.resourceId === undefined" style="width: 30%; display: flex;" align-self="center">
                      <i>{{ invItem.vkSinglePrice.toFixed(2).toString().replace('.' , ',') }}</i> &euro;&nbsp;
                    </span>
                    <a-input-number v-else style="width: 50%;" :min="0" decimalSeparator="," :controls="false" v-model:value="invItem.vkSinglePrice" @change="handleEnter($event, bill, invItem.resourceId)" placeholder="Kein Preis verfügbar"  />
                  </div>
                </template>
            </template>
            <!-- invtitem -->
          </template>
          <!-- servetype -->
        </template>
      </template>
    </div>
    <div :key="updateSum" class="kdz-cost-sum text-right">
      <span> Gesamtkosten Netto VK/ Monat<br/></span>
      <strong>Summe: {{  vkPriceSummary.toString().replace("." , ",") }} € </strong> <br />
      <span> Gesamtkosten Netto EK/ Monat<br/></span>
      <strong>Summe: {{ summary[0].toString().replace("." , ",") }} €</strong> <br />
      <strong>Netto Ertrag: {{ getDifferenz(vkPriceSummary, summary[0]).toString() }} €</strong> <br/>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import { DownOutlined, UpOutlined } from '@ant-design/icons-vue'
import TooltipLabel from '@/components/label/TooltipLabel.vue'
import { ref, reactive } from 'vue'


export default {
  name:'EK',
  components: {
      DownOutlined,
      UpOutlined,
      TooltipLabel
  },
  props: {

  },
  data () {
      return {
          serverTypes: ["container", "cloudServer", "iaas", "wpCloud", "cybercloudendpoint"],
          serverTypeNames: { 
              container: "Container Deployments",
              cloudServer: "Cloud-Server",
              iaas: "Infrastructure as a Service",
              wpCloud: "Online Office",
              cybercloudendpoint: "Cyber Cloud Endpoint"
          },
          showCosts: [],
          updateIndex: 3,
          updateSum: 0,
          total: 0,
          fromInput: false,
          windowWidth: window.innerHeight, 
          partnerValue: ref('Standard'),
          houseOptions: ref([
            {
              partnerValue: null,
              text: "Standard",
            },
            {
              partnerValue: "Holz",
              text: "Reseller (Holz)",
            },
            {
              partnerValue: "Bronze",
              text: "Reseller (Bronze)",
            },
            {
              partnerValue: "Silber",
              text: "Reseller (Silber)",
            },
            {
              partnerValue: "Gold",
              text: "Reseller (Gold)",
            },
            {
              partnerValue: "Platin",
              text: "Reseller (Platin)",
            },
          ]),
      }
  },
  mounted () {

  },
  computed: {
      ...mapGetters([
      'bill',
      'selectedServer',
      'serverList',
      'discount',
      'discountType',
      'isSidebarOpen',
      'partnerDiscount'
      ]),
      isRoot() {
          return this.$route.path.endsWith("/");
      },
      houseSelect: {
          get() {
              return this.discountType;
          },
          set(value) {
              var selectedDiscount
              this.setDiscountType(value);
              switch (value) {
              case 'Holz': selectedDiscount = 20
                  break
              case 'Bronze': selectedDiscount = 30
                  break
              case 'Silber': selectedDiscount = 35
                  break
              case 'Gold': selectedDiscount = 40
                  break
              case 'Platin': selectedDiscount = 47
                  break
              default: selectedDiscount = 0
              }
              this.$store.commit("setPartnerDiscount", selectedDiscount)
              if (this.serverList.length > 0) {
              for (var server of this.serverList) {
                  if (Object.keys(server.configuration).length > 0) {
                  var before = server.configuration.vCPU
                  server.configuration.vCPU = 1
                  server.configuration.vCPU = before
                  }
              }
              }
          },
      },
      isCloudOrIaas() {
          return (
              this.$store.getters['selectedServer'].type === "iaas" ||
              this.$store.getters['selectedServer'].type === "cloudServer" ||
              (this.$store.getters['serverList'].length > 0 &&
              (this.$store.getters['serverList'][0].type === "cloudServer" ||
              this.$store.getters['serverList'][0].type === "iaas"))
          );
      },
      summary() {
          let sum = 0.0
          let originalSum = 0.0
          this.bill.forEach((el) => {
              for (let pos of el.bill) {
              sum += Number(pos.price)
              if (pos.originalPrice !== undefined) {
                  originalSum += Number(pos.originalPrice)
              }
              }
          });
          return [sum.toFixed(2), originalSum.toFixed(2)];
      },
      costPerHour() {
          return ((this.summary[0]) / 730).toFixed(2);
      },
      vkPriceSummary() {
      if(!this.fromInput){
        let sum = 0.0
        this.bill.forEach((el) => {
          for (let pos of el.bill) {
            if(pos.originalVkPrice != undefined){
              sum += Number(pos.originalVkPrice)
            }
          }
        });
        return sum.toFixed(2)
      }
      else{
        return this.total.toFixed(2)
      }
      
    },
  },
  methods : {
      ...mapMutations(["setSidebarVisibility", "clearServer", "setDiscountType"]),
      checkServerType(type){
          for (let i = 0; i < this.bill.length; i++) {
              if (this.bill[i].type != undefined && (this.bill[i].type ==type || (this.bill[i].type === "containerIaaS" && type === "iaas"))) {
              return true
              }
          }
          return false
      },
      handleEnter(event, bill, resourceId) {
      this.total = 0
      this.fromInput = true
      var firstIp = false
      var specialCase = false
      for (var server of bill) {
        specialCase = false
        var resource = server.bill.filter(x => x.resourceId === resourceId)[0]
        if (resource !== undefined) {
          resource.vkSinglePrice = event
          var tempvkSinglePrice = resource.vkSinglePrice
          // logic to make sure that allways only one IP per OFFer is free
          if (resourceId == 3) {
            if(bill.length == 1) {
              if(resource.count == 1) {
              resource.originalVkPrice = 0
              specialCase = true
              }
              else{
              resource.originalVkPrice = tempvkSinglePrice * ((parseInt(resource.count)) - 1)
              specialCase = true
              }
            } else{
              if(resource.count == "1" && !firstIp) {
                firstIp = true
                specialCase = true
                resource.originalVkPrice = 0
              }
            }
          }
          
          //Multiply CPU or RAM in containers with replicas
          if(resourceId == 1004 || resourceId == 1005) {
            var filteredList = this.serverList.filter(x=>x.id == server.id)
            var replicas = filteredList.length > 0 ? filteredList[0].configuration.Replicas : null
            if(replicas != null){
              resource.originalVkPrice = tempvkSinglePrice * parseInt(resource.count) * replicas
              specialCase = true
            }
          }

          // MSSQL set total to 0 if no Cpus are configured
          if (resourceId == 5 && resource.count == 0){
            tempvkSinglePrice = 0
          } 
          if(specialCase == false){
            resource.originalVkPrice = tempvkSinglePrice * parseInt(resource.count)
          }     
          this.$store.commit('setCustomVkPrices', {id:resourceId, customPrice: resource.vkSinglePrice})

          for(let i = 0; i<server.bill.length; i++) {
            if(server.bill[i].originalVkPrice != undefined){
              this.total += Number(server.bill[i].originalVkPrice)
            }  
          }
    
          this.updateSum += 1
          this.updateIndex += 1
          this.fromInput = false
        }
      }
    },
    getDifferenz(original, discount) {
      var diff = original - discount
      return diff.toFixed(2)
    },
  }
}
</script>