<template>
  <div class="mainNetwork">
    <div class="network-connection deactivated"></div>
    <div id="networkCloudAndConnections" style="position: absolute;">
      <div class="network-connection horizontal" ></div>
      <div class="network-connection vertical"></div>
      <div class="network-cloud">
        <div class="title">
          Netzwerk {{ network }}<br />
          192.168.{{ network }}.0/24
        </div>
      </div>
    </div>
    <div class="kdz-bg-grey network-container" style=" display: flex;">
      <div class="iaas-server-height" style=" width: 220px;">
        <div :class="['panel panel-iaas clickable text-center flexBetween']" name="createInstancesModal" key="" @click="openModal">
            <span class="clickable">
              <img width="20" src='../../assets/add_cross.svg'/>
            </span>
            <span class="createNewServerText">Neuen Server erstellen</span>
        </div>
      </div>
      <div class="iaasServerContainer">
          <div v-for="(server, i) in servers" :key="i">
            <IaaSServer :server="server" />
          </div>
      </div>
      <div class="deleteButton clickable" @click="$emit('deleteNetworkModal', network)" >
        <img src='../../assets/trash.svg' class="trashBinImage"/>
      </div>
      <a-card v-if="!isSidebarOpen" class="deployButtons" :id="'network' + this.network">
        <div style="display: flex; height: 120px;">
          <div class="deployVmButton" @click="handleOpenCreateServer()">
            <img  src="../../assets/Icons_Serverschrank_ohne_Schatten.svg" style='height: 90px' />
            <span style="color: white; font-weight: bold;">Server</span>
          </div>
          <div class="deployContainerButton" @click="handleOpenCreateContainer()">
            <img src="../../assets/container_single.jpeg" style='height: 90px' />
            <span style="color: white; font-weight: bold;">Container</span>
          </div>
        </div>
      </a-card>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { imageUrl } from "@/mixins/mixins.js";
import IaaSServer from "@/components/server/IaaSServer.vue";

export default {
  name: "IaaSNetwork",
  components: {
    IaaSServer,
  },
  mixins: [imageUrl],
  props: {
    network: {
      type: Number,
      required: true,
    },
    last: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data () {
    return {
      eventListenerActive: false
    }
  },
  computed: {
    ...mapGetters(["serversByType", "selectedServer", "send", "getPriceList", "isSidebarOpen"]),
    priceList() {
      return this.getPriceList
    },
    servers() {
      var allServers = this.serversByType("iaas").filter(
        (el) => el.configuration.network === this.network
      )
      var allContainers = this.serversByType("containerIaaS").filter(
        (el) => el.configuration.network === this.network
      )
      return allServers.concat(allContainers);
    },
    isSmallScreen() {
      return window.screen.width < 992;
    },
  },
  methods: {
    ...mapMutations(["setSelectedServer", "setSidebarVisibility", "addServer"]),
    openModal() {   
      if (!this.eventListenerActive) {
        var network = this.network
        document.addEventListener('click', function(e){
          var openThisPopover = document.getElementById('network' + network)
          var createInstanceButtons = document.getElementsByName('createInstancesModal')
          if (openThisPopover !== null && !openThisPopover.contains(e.target)){
            for (let i = 0; i < createInstanceButtons.length; i++) {
              if (createInstanceButtons[i].contains(e.target)){
                var popoverList = document.getElementsByClassName('deployButtons')
                if (popoverList.length !== 0) {
                  for (var openPopover of popoverList) {
                    if (openPopover.style.display === 'inline' && openPopover.id !== network) {
                      openPopover.style.setProperty('display', 'none')
                    }
                  }
                }
                var openThisPopover = document.getElementById('network' + network) 
                if (openThisPopover.style.display === 'inline') {
                  openThisPopover.style.setProperty('display', 'none')
                } else {
                  openThisPopover.style.setProperty('display', 'inline')
                }
                return
              }
            }
            if (openThisPopover.style.display === 'inline') {
              openThisPopover.style.setProperty('display', 'none')
            }
          } 
        })
        this.eventListenerActive = true
      }   
      
    },
    handleOpenCreateServer () {
      document.getElementById('productSelectionContainer') !== null ? document.getElementById('productSelectionContainer').scrollIntoView({ behavior: "smooth" }) : false
      if (this.selectedServer.type === 'iaas' && Object.keys(this.selectedServer).length !== 0) {
        return
      }
      let server = {
        type: "iaas",
        name: "Server",
        configuration: {},
        id: Date.now(),
      };
      for (var item of this.priceList) {
        server.configuration[item[0]] = 0
      }
      server.configuration.network = this.network
      server.configuration.os = ""
      this.setSelectedServer(server);
      this.addServer();
      this.setSidebarVisibility(true);
      var openThisPopover = document.getElementById('network' + this.network)
      if (openThisPopover.style.display === 'inline') {
        openThisPopover.style.setProperty('display', 'none')
      }
    },
    handleOpenCreateContainer () {
      document.getElementById('productSelectionContainer') !== null ? document.getElementById('productSelectionContainer').scrollIntoView({ behavior: "smooth" }) : false
      if (this.selectedServer.type === 'containerIaaS' && Object.keys(this.selectedServer).length !== 0) {
        return
      }
      let server = {
        type: "containerIaaS",
        name: "Container",
        configuration: {},
        id: Date.now(),
      };
      for (var item of this.priceList) {
        server.configuration[item[0]] = 0
      }
      server.configuration.network = this.network
      server.configuration.os = ""
      this.setSelectedServer(server);
      this.addServer();
      this.setSidebarVisibility(true);
      var openThisPopover = document.getElementById('network' + this.network)
      if (openThisPopover.style.display === 'inline') {
        openThisPopover.style.setProperty('display', 'none')
      }
    }
  },
};
</script>


<style lang="scss" scoped>
.deployButtons {
  display: none;
  background-color: white;
  position: absolute;
  height: 160px;
  top: 20%;
  left: 38%;
  width: 400px;
  z-index: 10000;
  border-radius: 6px;
  box-shadow: 0 1px 14px rgba(0,0,0,.2);
}
.deployVmButton {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50%;
  height: 100%;
  background-color: #3D7CAF;
}
.deployVmButton:hover {
  cursor: pointer;
}
.deployContainerButton {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50%;
  height: 100%;
  background-color: #3D7CAF;
  margin-left: 10px;
}
.deployContainerButton:hover {
  cursor: pointer;
}
.iaasServerContainer {
  width: 450px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 10px;
  margin-left: 10px;
  padding: 35px 0;
}
.clickable {
    cursor: pointer;
  
    &:hover {
      color: #d02c2a;
    }
  }
.flexBetween {
  display: flex;
  flex-direction: column;
}
/* .mouse {
  position: absolute;
  z-index: 9999;
  animation: mouseMove 4s linear 1s 6;
  width: 50px;
  opacity: 0;
  bottom: 0;
  right: 0;
}

@keyframes mouseMove {
  0% {
    opacity: 1;
    bottom: 0;
    right: 0;
  }

  100% {
    bottom: 25%;
    right: 80%;
    opacity: 0;
  }
} */
.mainNetwork {
    height: auto; 
    display: flex; 
  }

.network-container {
  width: auto;
  min-height: 200px;
  overflow: auto;
  position: relative;
  background: linear-gradient(rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)),
    url("../../assets/wp-logo.svg") no-repeat center right 40px;
  background-size: 35%;
  background-color: #f2f2f2;
}

.network-cloud {
  position: absolute;
  top: -20px;
  left: -15px;
  width: 150px;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  z-index: 5;
}

.kdz-bg-grey {
  margin-left: 50px;
}

.iaas-server-height {
  height: 220px;
}

.network-connection {
  position: relative;
  background-color: #f39200;
  // display: none;
  z-index: 4;

  &.horizontal {
    top: 100px;
    height: 10px;
    width: 55px;
  }

  &.vertical {
    top: 10px;
    height: 100px;
    width: 10px;
    left: -6px;
  }

  &.deactivated {
    left: -6px;
    height: 100%;
    width: 10.79px;
    //background-color: #f9c87f;
    // border: 1px solid black;
  }
}

.deleteButton {
  position: absolute;
  width: 40px;
  top: 10px;
  right: 10px;
}

.title {
  text-align: center;
  color: white;
  font-weight: bold;
  padding-bottom: 20px;
  font-size: 90%;
}
.panel {
  background: url("../../assets/panel.svg") no-repeat;
  position: absolute;
  width: 200px;
  height: 115px;
  padding: 30px 10px;
}
.panel-iaas {
  bottom: 5px;
}

@media (min-width: 1161px) {
  .kdz-bg-grey {
    margin-left: 0;
  }

  .iaas-server-height {
    height: 350px;
  }

  .network-cloud {
    top: 30px;
    left: 55px;
    width: 200px;
    height: 150px;
    background: url('../../assets/cloud.png') no-repeat; background-size:contain;
  }
  .network-container {
    width: 94.5%;
    min-height: 250px;
    overflow: auto;
    margin: 0 3rem;
  }
}

@media (max-width: 1200px) {
  .kdz-bg-grey {
    margin-left: 0;
  }

  .iaas-server-height {
    height: 300px;
  }

  .network-cloud {
    top: 50px;
    left: 55px;
    width: 160px;
    height: 120px;
    background: url('../../assets/cloud.png') no-repeat; background-size:contain;
  }
  .iaasServerContainer {
  justify-content: left;
  }
  .network-container {
    width: 100%;
    min-height: 250px;
    overflow: auto;
    //margin: 0 3rem;
  }
  .mainNetwork {
    width: 600px;
  }
  
  .createNewServerText {
    font-size: 90%;
  }

  .network-connection {

    &.deactivated {
      width: 10.18px;
      //background-color: #f9c87f;
      // border: 1px solid black;
    }
  }
  .panel {
    width: 140px;
    height: 84px;
    padding: 21px 7px;
    left: 40px;
  }
  .trashBinImage {
    width: 30px;
  }
}

@media (max-width: 930px) {
  .network-connection {

    &.deactivated {
      width: 10.25px;
      //background-color: #f9c87f;
      // border: 1px solid black;
    }
  }
  .mainNetwork {
    width: 450px;
  }
  .iaasServerContainer {
    justify-content: center;
    }
  }
  @media (max-width: 790px) {
  .network-connection {
    &.deactivated {
      height: auto;
      //background-color: #f9c87f;
      // border: 1px solid black;
    }
  }
  }
  @media (max-width: 490px) {
    .iaasServerContainer {
      justify-content: center;
      width: 365px;
      margin-left: 0px;
      padding: 0px;
    }
    .mainNetwork {
      width: 370px;
    }
    .panel {
      left: 5px;
    }
    .network-cloud {
      left: 10px;
    }
    .deleteButton {
      right: 0px;
    }
  }
</style>