<template>
  <div>
    <div
      fluid
      :class="[
        'kdz-costs text-white',
        ,
        (discountType === 'Holz' ? 'woodPartner' : (discountType === 'Bronze' ? 'bronzePartner' : (discountType === 'Silber' ? 'silverPartner' : (discountType === 'Gold' ? 'goldPartner' : (discountType === 'Platin' ? 'platinumPartner' : 'standardPartner'))))),
      ]"
      style="text-align: right;"
    >
    <a-tabs v-model:activeKey="activeKey" type="card" class="costoverview" :tabBarGutter="0">
      <a-tab-pane :key="'1'">
        <template #tab>
          <a-popover>
              <span>Einkaufspreis</span>
              <template #content>
                <span>{{ 'Kalkulation' }}</span>
              </template>      
          </a-popover>       
        </template>
        <!-- EK -->
        <EK @openCancelConfigModal="openCancelConfigModal"/>
        <!--  -->
      </a-tab-pane>
      <a-tab-pane :key="'2'" style="color: white;">
        <template #tab>
          <a-popover>
            <span>Verkaufspreis</span>
            <template #content>
              <span>{{ 'Kundenangebot' }}</span>
            </template>      
          </a-popover>       
        </template>
        <!-- VK -->
        <VK @openCancelConfigModal="openCancelConfigModal"/>
        <!--  -->
      </a-tab-pane> 
    </a-tabs>
    <div style="display: flex;">
      <div>
        <a-popover v-if="isSidebarOpen || bill.length === 0 || (bill.length === 1 && $route.path === '/iaas')" placement="top">
          <template #content>
            <span>{{ 'Schließen Sie Ihre aktuelle Konfiguration ab. Zum Speichern eines Angebots muss min. ein Server konfiguriert sein!' }}</span>
          </template>
          <a-button id="saveConfBtn1" type="primary" size="large" style="width: 270px; margin-bottom: 10px; margin-right: 10px;" @click="saveConfig()" :disabled="isSidebarOpen || bill.length === 0 || (bill.length === 1 && $route.path === '/iaas')"><span style="margin-right: 5px;"><SaveOutlined :style="{fontSize: '24px'}"/></span><b>Angebot speichern</b></a-button>
        </a-popover>
        <a-button id="saveConfBtn2" v-else style="margin-bottom:5px; width: 270px; margin-right: 10px;" type="primary" size="large" @click="saveConfig()" :disabled="isSidebarOpen || bill.length === 0 || (bill.length === 1 && $route.path === '/iaas')"><span style="margin-right: 5px;"><SaveOutlined :style="{fontSize: '24px'}"/></span><b>Angebot speichern</b></a-button>
        <a-popover v-if="!configButton" placement="left">
          <template #content>
            <span>{{ 'Bitte speichern Sie das Angebot ab, bevor es herunterladen.' }}</span>
          </template>
          <a-button id="downloadOfferBtn2" style="margin-bottom:5px; width: 270px; margin-right: 10px;" type="primary" size="large" @click="getOffer()" :disabled="!configButton"><span style="margin-right: 5px;"><SaveOutlined :style="{fontSize: '24px'}"/></span><b>Angebot herunterladen</b></a-button>
        </a-popover>
        <a-button v-else id="downloadOfferBtn" style="margin-bottom:5px; width: 270px; margin-right: 10px;" type="primary" size="large" @click="getOffer()" :disabled="!configButton"><span style="margin-right: 5px;"><SaveOutlined :style="{fontSize: '24px'}"/></span><b>Angebot herunterladen</b></a-button>
      </div>
    </div>    
      <a-modal
        :title="'Konfiguration abbrechen?'"
        v-model:open="cancelConf"
        :closable="true"
        @cancel="cancelConf = false"
        @ok="exitConfig()"
        okText="Ok"
        destroyOnClose
        cancelText="Abbrechen"
        centered>
        Wollen Sie die Konfiguration wirklich abbrechen? Alle bisher angegeben Daten werden komplett zurück gesetzt!
      </a-modal>
      <SaveConfig
        :showSaveConfig="showSaveConfig"
        :importObjectString="JSON.stringify(this.$store.getters.serverList)"
        :billObject="this.$store.getters.bill"
        :accountId="this.accountid"
        :allAccounts="this.accountData"
        @close-modal="closeModal"/>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import { dialog, os } from "@/mixins/mixins.js";
import TooltipLabel from '@/components/label/TooltipLabel.vue'
import { message, notification, Popover } from 'ant-design-vue'
import api from '@/apis/apis'
import { ref, reactive, toRaw } from 'vue'
import { SaveOutlined, DownloadOutlined, DownOutlined, UpOutlined } from '@ant-design/icons-vue'
import SaveConfig from '../components/forms/SaveConfig.vue'
import EK from "./forms/EK.vue";
import VK from "./forms/VK.vue";

export default {
  name: "CostOverview",
  mixins: [dialog, os],
  components: {
    TooltipLabel,
    notification,
    SaveOutlined,
    DownloadOutlined,
    DownOutlined,
    UpOutlined,
    SaveConfig,
    EK,
    VK
  },
  data() {
    return {
      accountid: this.getCookie('account'),
      email: '',
      activeKey: '1',
      windowWidth: window.innerHeight, 
      emailRegex: /^([.]{0,1}[a-zA-Z0-9_-])+@([.]{0,1}[a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,10}){1,2})$/,
      configNumberRegex: /^[\u00C0-\u017Fa-zA-Z0-9_.]*$/,
      configNameRegex: /^[\u00C0-\u017Fa-zA-Z0-9_. ]*$/,
      cancelConf: false,
      configButton: false,
      showSaveConfig: false,
      loading: false,
      accountData: [],
    };
  },
  computed: {
    ...mapGetters([
      'bill',
      'serverList',
      'discount',
      'discountType',
      'isSidebarOpen',
    ]),
  },
  created() {
    this.initForm()
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
    this.configButton = false;
    this.listAccountsForReseller()
  },
  beforeUnmount() { 
    window.removeEventListener('resize', this.onResize); 
  },
  methods: {
    ...mapMutations(["setSidebarVisibility", "clearServer", "setImportedConfigName", "setImportedConfigNumber", "setCustomerId"]),
    onResize() {
      this.windowWidth = window.innerWidth
    },
    initForm () {
      this.formRef = ref()
      this.form = reactive({})
      this.rules = reactive({
        checkbox: [{ required: true, message: "Erforderlich" }],
        email: [{ required: true, message: "Erforderlich" }, { validator: this.validateEmail }],
      })
    },
    saveConfig () {
      this.showSaveConfig = true
    },
    getOffer () {
        var params = new FormData()
        params.append('object', JSON.stringify(this.$store.getters.bill))
        params.append('account', this.accountid)
        params.append('customerId', this.$store.getters.getCustomerId)
        params.append('offerName', this.$store.getters.getImportedConfigName)
        params.append('offerNumber', this.$store.getters.getImportedConfigNumber)
        params.append('customerName', this.$store.getters.getCustomerName)
        api.getPdf(params).then(response => {
        let filename = response.headers['content-disposition'].split("filename=")[1]
        let url = window.URL.createObjectURL(new Blob([response.data]));
        let link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename); //or any other extension
        document.body.appendChild(link);
        link.click();
          this.$notification.success({
            message: 'Angebot wurde erstellt!',
          })
        }).catch(error => {
          console.log(error)
        })
    },
    async listAccountsForReseller(){
      this.accountLoading = true
      this.accountid = this.getCookie('account')
      await api.listAccounts(this.accountid).then(response =>{
        this.accountData = response.data
        this.accountLoading = false
      }).catch(error => {
        console.log("error account",error)
        this.accountLoading = false
      })
    },
    getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    /* submit(e) {
      e.preventDefault()
      if (this.$store.getters.bill.length === 0) {
        return
      }
      this.formRef.value.validate().then(() => {
        const values = toRaw(this.form)
        let params = new FormData()
        params.append('object', JSON.stringify(this.$store.getters.bill))
        params.append('account', this.getCookie('account'))
        api.getPdf(params).then(response => {
          this.$notification.success({
            message: 'Angebot versendet!',
            description: 'Das von Ihnen konfigurierte Angebot wurde erfolgreich an ' + values.email + ' versendet.'
          })
          // console.log(response.data)
          // let filename = response.headers['content-disposition'].split("filename=")[1]
          // let url = window.URL.createObjectURL(new Blob([response.data]));
          // let link = document.createElement('a');
          // link.href = url;
          // link.setAttribute('download', filename); //or any other extension
          // document.body.appendChild(link);
          // link.click();
        }).catch(error => {
          console.log(error)
        })
      }).catch(error => {
          this.formRef.value.scrollToField(error.errorFields[0].name)
      })
    }, */
    validateEmail (rule, value) {
      const errorMessage = 'Bitte geben Sie eine valide E-Mail Adresse an!'
      if (this.emailRegex.test(value)) {
        return Promise.resolve()
      }
      return Promise.reject(errorMessage)
    },
    closeModal () {
      this.showSaveConfig = false
    },
    exitConfig() {
      this.setSidebarVisibility(false);
      this.setCustomerId(undefined)
      this.setImportedConfigNumber(undefined)
      this.setImportedConfigName(undefined)
      this.clearServer();
      this.$router.push("/prices")
      this.cancelConf = false
      this.configButton = false
    },
    openCancelConfigModal () {
      this.cancelConf = true
    }
  },
};
</script>

<style lang="scss">
.hoverLabel {
  width: 60%;
}
.hoverLabel:hover {
  font-weight: bold;
} 
.text-white {
  color: white;
}
.standardPartner {
  background-color: #3d7caf;
}
.woodPartner {
  background-color: #9a7b4f;
}
.bronzePartner {
  background-color: #cd7f32;
}
.silverPartner {
  background-color: #afafaf;
}
.goldPartner {
  background-color: #edc006;
}
.platinumPartner {
  background-color: #a0b2c6;
}

.exit-button {
  color: #fff !important;
  font-weight: bold;
  margin-left: 10px;
}
.kdz-costs {
  height: 550px;
  width: 310px;
  overflow: auto;
  transition: 0.8s all ease-in-out;
}
.kdz-cost-border {
  border-bottom: 1px dotted #fff;
}

.kdz-sidebar-head {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid white;
  font-size: 25px;
  color: white;
}

.kdz-cancel {
  display: flex;
  align-items: center;
}

.kdz-cost-list {
  max-height: 600px;
  font-size: 16px;
  overflow-y: auto;
  text-align: left;
  padding: 0px 3px 0px 3px;
  color: white;
}
.kdz-cost-sum {
  color: white;
}
.kdz-last-element {
  border-bottom: 1px solid #fff;
}

.kdz-cost-sum {
  padding: 0.5rem 1rem;
  border-top: 2px solid #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .big {
    font-size: 25px;
    font-weight: bold;
    text-decoration: underline double;
  }
  .vkbig {
    font-size: 18px;
    font-weight: bold;
  }
}
.costoverview.ant-tabs-card >.ant-tabs-nav .ant-tabs-tab{
  border-radius: 0px;
  width: 155px;
  font-size: large;
  color: black;
  background: #EEEEEE;
}

.costoverview.ant-tabs-card >.ant-tabs-nav .ant-tabs-tab-active{
  background: white
}

.serverTypeHeader {
  font-weight: bold;
  font-size: 18px;
  color: #4d4d4d;
}
.serverHeader{
  white-space: pre-wrap;
  //background-color: rgba(255, 255, 255, 0.3);
  color: rgb(255, 255, 255);
  line-height: 1.25;
  padding-bottom: 3px;
}
.serverHeader:hover {
  background-color: rgba(100,100,100,0.1);
}
.bottomBorderFolded {
  border-bottom: 1px solid #fff;
}
@media (min-width: 769px) {
  .kdz-costs {
    min-height: calc(500px + 2.5rem);
    height: 100%;
  }
  .kdz-sidebar-head {
    font-size: 20px;
  }
}

@media (min-width: 992px) {
  .kdz-sidebar-head {
    font-size: 25px;
  }
}

@media (max-width: 768px) {
  .kdz-costs {
    height: 100%;
    width: 100%;
    padding: 12px 12px 12px 12px;
    display: flex;
    flex-direction: column;
  }
  .kdz-sidebar-head {
    font-size: 20px;
  }

  .kdz-cost-sum {
  display: flex;
  flex-direction: column;
  align-items: center;
  }
}
</style>
