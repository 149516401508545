<template>
  <div>
    <div class="panel-server">
      <div align-self="center">
        <img class="imageMargin" width="30" :src="getImgUrl(iconUrl)"/>
      </div>
      <div class="editAndDeleteServer">
        <div class="serverName"> {{ server.name }} </div>
        <div class="clickable" @click="editServer" style="width: 90px; display: flex; align-items: center;">
          <img class="buttonSize" src='../../assets/button_square.svg'/>&nbsp;
          <span>Bearbeiten</span>
        </div>
        <div class="clickable" @click="deleteServerModal = true" style="width: 90px; display: flex; align-items: center;">
            <img class="buttonSize" src='../../assets/trash.svg'/>&nbsp;
          <span>Löschen</span>
        </div>
      </div>
      <a-modal
        :title="'Server löschen?'"
        v-model:open="deleteServerModal"
        :closable="true"
        @cancel="deleteServerModal = false"
        @ok="this.deleteConfig()"
        okText="Ok"
        cancelText="Abbrechen"
        centered>
        Wollen Sie den Server wirklich löschen?
    </a-modal>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import { dialog, imageUrl } from "@/mixins/mixins.js";

export default {
  name: "IaaSServer",
  props: {
    server: {
      type: Object,
      required: true,
    },
  },
  mixins: [dialog, imageUrl],
  data() {
    return {
      deleteServerModal: false,
    };
  },
  computed: {
    ...mapGetters(["isSidebarOpen"]),
    iconUrl() {
      let iconUrl = ''
      if (this.server.configuration.os.includes('windows') || this.server.configuration.os.includes('Windows')) {
        iconUrl = "/windows_icon.png";
      } else if (this.server.configuration.os === 'Linux') {
        iconUrl = "/linus.svg";
      } else { iconUrl = "/mitteldeutsche_logo_circle.svg" }
      if (this.server.configuration.MS_Exchange > 0) {
        iconUrl = "/exchange-new.svg";
      } else if (this.server.configuration.MSSQL_Standard_Core_License > 0 || this.server.configuration.MSSQL_Standard_User_License > 0) {
        iconUrl = "/microsoft-sql-server.png";
      }
      if (this.server.type === "containerIaaS") {
        iconUrl = "/container_single.jpeg"
      }
      return iconUrl;
    },

    serverTitle() {
      let title = "";
      let os = this.server.configuration.os;
      if (os === "linux") {
        title = "Linux ";
      } else if (os.includes("win")) {
        title = "Win ";
      }
      return title + this.server.name;
    },
  },
  methods: {
    ...mapMutations([
      "setSidebarVisibility",
      "setSelectedServer",
      "deleteServer",
      "resetServer",
    ]),
    editServer() {
      if (this.isSidebarOpen) {
        return
      }
      this.setSelectedServer(this.server);
      this.setSidebarVisibility(true);
    },
    getImgUrl(pic) {
      return require('../../assets'+pic)
    },
    deleteConfig() {
      this.deleteServer(this.server.id);

      if (this.isSidebarOpen === true) {
        this.setSidebarVisibility(false);
        this.resetServer();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.clickable {
  cursor: pointer;

  &:hover {
    color: #d02c2a;
  }
}
.panel-server {
  font-size: 14px;
  display: flex;
  background: url("../../assets/panel.svg") no-repeat;
  width: 170px;
  height: 100px;
  /* padding: 20px 20px; */
}

.serverName {
  word-break: break-word; 
  width: 120px; 
  height: auto; 
  margin-bottom: 5px; 
  margin-top: 5px;
}
.editAndDeleteServer {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  text-align:left;

}

.buttonSize {
  width: 20px;
}

.imageMargin {
  margin-top: 5px;
  margin-left: 5px;
}

@media (max-width: 1200px) {
  .panel-server {
    font-size: 13px;
    width: 150px;
    height: 100px;
    margin-left: 15px;
  }
  .editAndDeleteServer {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    text-align:left;
  }
  .serverName {
    width: 100px;
    margin-top: 10px;
  }
  .buttonSize {
    width: 16px;
  }
}

@media (max-width: 490px) {
  .panel-server {
    margin-left: 0px;
  }
}
</style>