<template>
  <div class="backup-container kdz-height">
    <div class="backup-img-container">
    <img class="kdz-config-img pipe-long" src='../assets/pipe_long.svg'/> 
     <img class="kdz-config-img pipe" src='../assets/pipe.svg'/>
      <img class="kdz-config-img server-farm" src='../assets/localserver.svg'/>
      <img class="kdz-config-img cloud" src='../assets/backup_cloud.svg'/>
    </div>
    <div class="image-row">
      <div style="display: flex; flex-direction: column;">
        <!-- <div @click="deleteConfigurationModal=true" v-if="!isEmpty" style="width: 235px;">
          <div class="text-left clickable">
            <img src='../assets/trash.svg' width="25" alt="" style="margin-right: 5px;"/>
            Gesamte Konfiguration löschen
          </div>
        </div> -->
      <div @click="createBackup" v-if="!isEmpty && !isSidebarOpen">
        <div class="text-left clickable"  style="width: 200px; margin-top: 3px;">
          <img src='../assets/button_square.svg' width="25" style="margin-right: 5px;" />
          Neuen Endpoint erstellen
        </div>
      </div>
      </div>
    </div>

    <div class="backups">
      <div class="backupContainer">
          <div v-for="(backup, i) in backups" :key="i" style="display: flex; width: 200px; margin-top: 5px;">
            <BackUpServer :server="backup" />
          </div>
        <img src='../assets/wp-logo.svg' class="kdz-config-img logo"/>
      </div>
      <div class="panel clickable" key="" @click="createBackup" v-if="isEmpty">
        <div>
          <img width="20" src='../assets/add_cross.svg'/>
        </div>
        <div>
          <div >Neuen Endpoint erstellen</div>
        </div>
      </div>
    </div>
    <!-- <a-modal
      :title="'Komplette Konfiguration löschen?'"
      v-model:open="deleteConfigurationModal"
      :closable="true"
      @cancel="deleteServerModal = false"
      @ok="deleteBackups()"
      okText="Ok"
      cancelText="Abbrechen"
      centered>
      Wollen Sie die komplette Konfiguration wirklich löschen?
    </a-modal> -->
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import BackUpServer from "@/components/server/BackUpServer.vue";
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'


export default {
  name: "BackUpView",
  components: {
    BackUpServer,
    ExclamationCircleOutlined

  },
  data() {
    return {
      deleteConfigurationModal: false,
    };
  },
  computed: {
    ...mapGetters(["serversByType", "send", "isSidebarOpen"]),
    backups() {
      return this.serversByType("cybercloudendpoint");
    },
    isEmpty() {
      return this.backups.length === 0;
    },
  },
  methods: {
    ...mapMutations([
      "setSelectedServer",
      "setSidebarVisibility",
      "resetServer",
      "addServer"
    ]),
    createBackup() {
      let server = {
        id: Date.now(),
        type: "cybercloudendpoint",
        name: "Cyber Cloud Endpoint",
        endpointType: "Einzeln",
        configuration: {
          'Cyber_Cloud_Endpoint':0,
          'AcronisRBS':0,
        },
      };
      
      this.setSelectedServer(server);
      this.addServer(server);
      this.setSidebarVisibility(true);
    },
    deleteBackups() {
      this.resetServer();
      this.deleteConfigurationModal = false

      if (this.isSidebarOpen === true) {
        this.setSidebarVisibility(false);
      }
    },
  },
mounted() {
    window.onresize = () => {
      this.screenWidth = window.screen.width;
    };
  },
  async beforeRouteLeave (to, from) {
    if (to.path === '/cloudServer' || to.path === '/iaas' || to.path === '/collaboration' || to.path === '/container') {
      if (this.$store.getters.isSidebarOpen) {
        const confirm = window.confirm('Wollen Sie die momentane Konfiguration wirklich abbrechen? Nicht gespeicherte Daten gehen verloren!')
        if (!confirm) {
          return false
        }
      }
      this.$store.commit("resetServer");
      this.$store.commit("setSidebarVisibility", false);
    }
  },
beforeDestroy() {
    window.onresize = null;
  },
};
</script>

<style lang="scss" scoped>

.backupContainer {
  width: 460px;
  display: flex; 
  flex-wrap: wrap;
  margin-left: 165px;
  justify-content: space-around;
}
.text-left {
  display: flex;
  align-items: left;
  font-size: 16px;
  align-items: center;
}
.kdz-config-img {
  position: absolute;
}
.panel {
  background: url("../assets/panel.svg") no-repeat;
  width: 180px;
  height: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 30px;
}
.backup-container {
  position: relative;
}

.image-row {
  height: 200px;
  display: flex;
  justify-content: right;
}

.backups {
  background-color: rgba(181,181,181,0.11);
  width: 100%;
}

.logo {
  display: block;
  width: 200px;
}


.pipe-long {
  top: 120px;
  width: 200px;
  left: 10%;
  display: none;
}

.add-cross {
  bottom: 30%;
  left: 40%;
  z-index: 4;
  display: none
}
.clickable {
  cursor: pointer;

  &:hover {
    color: #d02c2a;
  }
}

.cloud {
  z-index: 2;
  top: 70px;
  left: 160px;
  width: 160px;
  // padding: 10px 20px;
  border-radius: 20px;
}

.image-row {
  margin-bottom: 40px;
  height: 150px;
}


.server-farm {
  width: 150px;
  top: 5%;
  left: 0;
}


@media (min-width: 1200px) {
  .panel {
    background: url("../assets/panel.svg") no-repeat;
    width: 180px;
    height: 100px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 70px;
    margin-left: 200px;
  }
  .server-farm {
    width: 250px;
    z-index: 2;
    left: -40px;
  }
  .logo {
    top: 235px;
    right: 40px;
  }

  .mobil {
    display: none;
  }

  .backups {
    min-height: 270px;
    height: auto;
    width: 620px;
    margin-left: 230px;
  }

  .add-cross {
    display: block;
  }

  .pipe {
    width: 280px;
    top: 200px;
    left: 20px;
    height: auto;
    transform: none;
    z-index: 1;
  }

  .cloud {
    top: 240px;
    left: 150px;
    width: 250px;
    padding: 0;
  }
}
@media (max-width: 1199px) {
  .backupContainer {
    margin-left: 100px;
    width: 180px;
  }
  .panel {
    margin-left: 110px;
    z-index: 5;
    position: relative;
  }
  .server-farm {
    width: 200px;
    z-index: 2;
    left: -40px;
  }
  .logo {
    top: 258px;
  }

  .mobil {
    display: none;
  }

  .backups {
    min-height: 270px;
    height: auto;
    width: 360px;
    margin-left: 230px;
  }

  .add-cross {
    display: block;
  }

  .pipe {
    width: 226px;
    top: 200px;
    left: 20px;
    height: auto;
    transform: none;
    z-index: 1;
  }

  .cloud {
    top: 240px;
    left: 135px;
    width: 200px;
    padding: 0;
  }
}

@media (max-width: 930px) {
  .panel {
    margin-left: 135px;
  }
  .logo{
    top: 300px;
  }
  .server-farm {
    top: 0;
    left: -20px;
  }
  .pipe-long {
    top: 140px;
    width: 300px;
    left: 30px;
    display: flex;
  }

  .pipe {
    display: none;
  }
  .cloud {
    top: 90px;
    left: 240px;
    width: 200px;
    padding: 0;
  }
  .backups {
    background-color: rgba(181,181,181,0.11);
    width: 100%;
    margin-left: 0;
    margin-top: 100px;
  }
  .backupContainer{
    width: 100%;
    margin-left: 0;

  }
}

@media (max-width: 490px) {

  .server-farm {
    width: 160px;
    top: 10%;
    left: -10px;
  }
  .cloud {
    top: 110px;
    left: 200px;
    width: 170px;
    padding: 0;
  }
  .image-row {
  margin-bottom: 40px;
  height: 150px;
  justify-content: center;
}
}
</style>