<template>
  <a-spin :spinning="loading" size="large" tip="Der Angebotskalkulator wird eingerichtet...">
    <div v-if="!showCalculator" style="height: 500px; margin-top: 150px;"></div>
    <div id="cloud-konfigurator" class="cloudConfig" v-if="showCalculator">
      <div class="cloudSelect" >
        <div class="cloudSelectWidth"
          v-for="(cloud, index) in clouds"
          :key="index"
        >
          <CloudSelectItem
            class="selectItemMaxWidth"
            :title="cloud.title"
            :src="cloud.src"
            :linkName="cloud.link"
            :myID="index"
          />
        </div>
        <div style="display: flex; flex-direction: column;">
        </div>
      </div>
      <div class="kdz-main-field" style="display: flex;">
        <Main v-if="$route.path=== '/prices'"></Main>
        <router-view class="cloudServerMain"/>
        <div class="sidebar-costOverview">
          <Sidebar />
          <CostOverview />
        </div>
      </div>
      <!-- <div style="display: flex; width: 400px; justify-content: space-around; padding-top: 10px;">
        <a-button id="priceTableBtn" type="primary" size="large" @click="showPriceTable()" style="margin-bottom: 10px;"><b>Preistabelle</b></a-button>
      </div>
      <div class="centering" id="priceTable">
        <div class="priceTable">
          <div class="searchField">
            <div style="width: 50%;">
              <div style="width: 350px;">
                <a-input-search v-model:value="searchInput" placeholder="Suchtext..." @change="handleSearch()" @search="handleSearchReset()" size="large" style="border-radius: 5px;" >
                  <template #enterButton>
                    <a-button class="resetButton" type="primary">Zurücksetzen</a-button>
                  </template>
                </a-input-search>
              </div>
            </div>
            <div style="width: 49%;">
              <div style="display: flex; width: auto; align-items: center; justify-content: right;">
                <a-button id="hardwareButton" type="primary" size="large" :disabled="this.hardwareDisabled" style="width: 100px; margin-right: 10px;" @click="hardwareButton()"><b>Hardware</b></a-button>
                <a-button id="licenseButton" type="primary" size="large" :disabled="this.licenseDisabled" style="width: 100px;" @click="licenseButton()"><b>Lizenzen</b></a-button>
              </div>
            </div>
          </div>
          <div>
            <a-table :columns="priceCols" :dataSource="priceDataTable" bordered :pagination="{ current: current, onChange: onChange}"></a-table>
          </div>
          <div>
            *Alle Preise sind Netto und für ein 30 Tage Intervall.
          </div>
        </div>
      </div> -->
  </div>
</a-spin>
</template>

<script>
import api from '@/apis/apis'
import { mapGetters } from "vuex";
import CostOverview from "@/components/CostOverview.vue";
import CloudSelectItem from "@/components/CloudSelectItem";
import Sidebar from "@/components/Sidebar.vue";
import Main from '@/views/Main.vue'
import TooltipLabel from '@/components/label/TooltipLabel.vue'
import { SaveOutlined } from '@ant-design/icons-vue'
import { notification } from 'ant-design-vue'
import { toRaw, reactive, ref } from 'vue'

export default {
  name: 'Prices',
  components: {
    CostOverview,
    CloudSelectItem,
    Sidebar,
    Main,
    TooltipLabel,
    SaveOutlined,
    notification
  },
  data () {
    return {
      accountid: this.getCookie('account'),
      myMap: new Map(),
      displayPriceTable: false,
      hardwareDisabled: true,
      licenseDisabled: false,
      showCalculator: false,
      loading: false,
      priceData:[],
      priceDataTable:[],
      tableBackup: [],
      filteredList:[],
      licenseList:[],
      hardwareList:[],
      splitFilteredLicenses:[],
      selectedSubAccountForSave: undefined,
      selectedNameForConfigSave: '',
      current: 1,
      searchInput:'',
      priceCols: [
        {
          title: 'Name',
          dataIndex:'usageName',
          key: 'usageName', 
          sorter: (a, b) => a.usageName.localeCompare(b.usageName),
          sortDirections: ['ascend','descend'],
          width: '40%',
        },
        {
          title: 'Preis in €',
          dataIndex:'quotaTariff',
          width: '20%',
        },
        {
          title: 'Discount in %',
          dataIndex: 'discount',
          width: '20%',
        },
        {
          title: 'Discount Preis in €',
          dataIndex: 'discountCalculated',
          width: '20%',
        },
      ],
    }
  },
  computed: {
    clouds() {
      return [
        {
          title: "Container Deployments",
          link: "container",
          src: "container.png",
        }, 
        {
          title: "Desktop aus der Cloud/\n Cloud-Server",
          link: "cloudServer",
          src: "cloudserver.svg",
        },
        {
          title: "Infrastructure as a Service\n (IaaS)",
          link: "iaas",
          src: "IaaS.svg",
        },
         {
          title: "Online Office & Collaboration\n (DSGVO konform)",
          link: "collaboration",
          src: "wp_cloud.png",
        },
        {
          title: "Cyber Cloud Endpoint\n (Backup & Security)",
          link: "cybercloudendpoint",
          src: "backup.png",
        }, 
      ];
    },
    ...mapGetters([
      'bill',
      'isSidebarOpen',
      'getConfigImportId',
      'originalDiscountType'
    ]),
  },
  async created () {
    this.initForm()
    await this.displayPrices()
  },
  mounted () {
    this.$store.commit("setSidebarVisibility", false)
    if (!this.$store.getters.getConfigImportId) {
      this.$store.commit("clearServer")
    }
  },
  async beforeRouteLeave (to, from) {
    var pathList = ['/cloudServer', '/iaas', '/collaboration', '/container', '/prices']
    if ((this.bill.length > 0 && !(this.bill.length === 1 && this.bill[0].id === "ip") && !(JSON.stringify(toRaw(this.$store.getters.getBillBeforeEnter)) === JSON.stringify(this.bill)))) {
      if (!pathList.includes(to.path)) {
      const confirm = window.confirm('Wollen Sie die Konfiguration wirklich verlassen? Nicht gespeicherte Daten gehen verloren!')
        if (!confirm) {
          this.$store.commit('setCurrentMenuTab', ['2'])
          return false
        }
        if (this.isSidebarOpen) {
          this.$store.commit('setSidebarVisibility', false)
        }
        this.$store.commit('clearServer')
      }
    } 
  },
  async beforeRouteEnter (to, from, next) {
    next(pricesComponent => {
      if (pricesComponent.getConfigImportId === undefined) {
        var originalPartnerStatus
        var selectedDiscount
        originalPartnerStatus = pricesComponent.originalDiscountType
        pricesComponent.$store.commit("setDiscountType", originalPartnerStatus)
        switch (originalPartnerStatus) {
          case 'Holz': selectedDiscount = 20
            break
          case 'Bronze': selectedDiscount = 30
            break
          case 'Silber': selectedDiscount = 35
            break
          case 'Gold': selectedDiscount = 40
            break
          case 'Platin': selectedDiscount = 47
            break
          default: selectedDiscount = 0
        }
        pricesComponent.$store.commit("setPartnerDiscount", selectedDiscount)
      } else {
        pricesComponent.$store.commit("setBillBeforeEnter", pricesComponent.bill)
      }
    })
  },
  methods: {
    initForm () {
      this.formRef = ref()
      this.form = reactive({
        configName: undefined,
      })
      this.rules = reactive({
        configName: [{ required: true, message: 'Bitte geben Sie einen Namen für die Konfiguration an' }],
        selectedSubAccountForSave: [{ required: true, message: 'Bitte eine Option auswählen' }],
      })
    },
    getCookie(cname) {
      let name = cname + "="
      let decodedCookie = decodeURIComponent(document.cookie)
      let ca = decodedCookie.split(';')
      for(let i = 0; i <ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) == ' ') {
          c = c.substring(1)
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length)
        }
      }
      return "";
    },
    // we have to set Popover messages and images to display manually to the map
    async setManualParamsToPriceMap (hashMap) {
      for(let key of hashMap.keys()) {  
        if (key.includes('NextGenerationFirewall')) {
          // add popover to NGF
          hashMap.get(key).popover = { title: 'Hinweis', content: 'Die Next-Generation-Firewall ist ein mehrstufiges Sicherheitssystem zum Schutz vor modernen Cyberattacken' }
        } else if (key.includes('IP_ADDRESS')) {
          // add popover to IP Address
          hashMap.get(key).popover = { title: 'Hinweis', content: 'Die erste IP-Adresse ist kostenlos.' }
        } else if (key.includes('MS_Exchange')) {
          // add popover and IMG to MS_Exchange licenses
          hashMap.get(key).image = 'exchange-new.svg'
        } else if (key.includes('MSSQL') && key.includes('Core_License')) {
          // add popover and IMG to MSSQL Core licenses
          hashMap.get(key).image = 'microsoft-sql-server.png'
        } else if (key.includes('windows') || key.includes('Windows')) {
          // add popover and IMG Windows licenses
          hashMap.get(key).image = 'windows_icon.png'
        }
      }
      hashMap.set('Linux', { data: { discount: 0 }, label: 'Linux', hoverLabel: 'Linux', price: 0, discount: "0.00", priceWithoutDiscount: 0, order: 0, popover: { title: null, content: null }, image: 'linus.svg', isLicense: true, isOS: true, isResource: false  })
      hashMap.set('libre', { data: { discount: 0 }, label: 'LibreOffice Lizenz', hoverLabel: 'LibreOffice', price: 0, discount: "0.00", priceWithoutDiscount: 0, order: 0, popover: { title: null, content: null }, isLicense: true, isOS: false, isResource: false  })
      return hashMap
    },
    onChange (pageObj) {
      this.current = pageObj.current
    },
    handleSearch () {      
      this.filteredList = this.tableBackup.filter( x => x.usageName.toString().toLowerCase().includes(this.searchInput.toString().toLowerCase()))
      this.current = 1
      this.priceDataTable = this.filteredList
      if (this.searchInput === '') {
        this.priceDataTable = this.tableBackup
      }
    },
    handleSearchReset() {
      this.searchInput = ''
      this.priceDataTable = this.tableBackup
    },
    // insert pos1 below pos2
    async insertArrayPos (arr, pos1, pos2) {
      var itemToMove = arr[pos1]
      if (pos1 === -1 || pos2 === -1) {
        return arr
      }
      arr.splice(pos1, 1)
      arr.splice((pos2+1), 0, itemToMove)
      return arr
    },
    async displayPrices() {
      this.loading = true
      await api.getCalculatorAccountPrices(this.accountid).then(response =>{
        this.myMap = new Map(Object.entries(response.data))
      }).catch(error => {
        console.log("error account",error)
      })
      // add Popover data and Text to each Resource that needs a popover manually!
      var rawMap = toRaw(this.myMap)
      this.$store.commit('setPriceList', await this.setManualParamsToPriceMap(rawMap))
      await api.getAccountPrices(this.accountid).then(async response => {
        if (response.status === 200 ) {
          this.showCalculator = true
          this.loading = false
        }
        }).catch(error => {
            console.log(error)
        })
      await api.listResellerPrices().then(response =>{
          this.$store.commit('setVkPriceList', response.data)
        }).catch(error => {
          console.log("error account",error)
        })
    
    },
    showPriceTable() {
      if (this.displayPriceTable === false) {
        document.getElementById("priceTable").style.display = "flex"
        this.displayPriceTable = true
        document.getElementById('priceTable').scrollIntoView({ behavior: "smooth" });
      } else {
        document.getElementById("priceTable").style.display = "none"
        this.displayPriceTable = false
      }
    },
    licenseButton() {
      this.priceDataTable = this.licenseList
      this.tableBackup = this.licenseList
      this.searchInput = ''
      this.current = 1 
      this.licenseDisabled = true
      this.hardwareDisabled = false
    },
    hardwareButton() {
      this.priceDataTable = this.hardwareList
      this.tableBackup = this.hardwareList
      this.searchInput = ''
      this.current = 1 
      this.licenseDisabled = false
      this.hardwareDisabled = true
    },
  }
}
</script>

<style lang="scss" scoped>
.selectItemMaxWidth {
  max-width: 250px;
}
.cloudSelect {
  display: flex;
  margin-bottom: 7px;
}

.cloudConfig {
  width: 100%;
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
}
.kdz-main-field {
  //border: 1px solid blue;
  font-size: 16px;
}
.centering {
    display: none;
    justify-content: center;
    width: 100%; 
    align-items: center;
  }
.priceTable {
  height: 60%; 
  width: 900px; 
  display: flex; 
  flex-direction: column; 
  justify-content: center; 
  margin-top: 40px;
}
.searchField {
  display: flex;
  margin-bottom: 0.5%;
}
.resetButton {
  font-weight: bold; 
  width: 100%;
}
.cloudServerMain {
  padding: 12px 12px 12px 12px;
   width: 860px;
}
.cloudSelectWidth {
  white-space: pre-wrap;
}

@media (max-width: 1200px) {
  .cloudServerMain {
    width: 600px;
  }
}

@media (max-width: 930px) {
  .cloudServerMain {
    width: 450px;
  }
}

@media (max-width: 790px) {
  .cloudSelect {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .cloudSelectWidth {
    width: 50%;
  }
  .kdz-main-field {
  //border: 1px solid blue;
 // font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 450px;
  }
  .sidebar-costOverview {
    display: flex; 
    justify-content: center;  
    padding: 12px 12px 12px 12px; 
    width: 80%;
  }
}
@media (max-width: 490px) {
  .cloudServerMain {
    width: 360px;
  }
  .kdz-main-field {
  //border: 1px solid blue;
 // font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  }
  .sidebar-costOverview {
    width: 90%;
  }
}
</style>