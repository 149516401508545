<template>
    <div>
        <div v-for="(server, index) in container" :key="index">
            <Container :serverId="server.id" :isLast="index === container.length - 1" v-on:addServer="createNewContainer" :canCreateContainer="this.canCreateContainer" @changeCreateContainerButton="changeCreateContainerButton"/>
        </div>
    </div>
</template>

<script>
import Container from "@/components/server/Container.vue"
import { mapGetters, mapMutations } from "vuex";

export default {
    name: 'ContainerView',
    components:{
        Container
    },
    data () {
      return {
        canCreateContainer: true
      }
    },
    mounted () {
        this.$store.commit("setSidebarVisibility", false)
        // this.$store.commit("clearServer")
    },
    async beforeRouteLeave (to, from) {
    if (to.path === '/iaas' || to.path === '/collaboration' || to.path === '/cybercloudendpoint' || to.path === '/cloudServer') {
      if (this.$store.getters.isSidebarOpen) {
        const confirm = window.confirm('Wollen Sie die momentane Konfiguration wirklich abbrechen? Nicht gespeicherte Daten gehen verloren!')
        if (!confirm) {
          return false
        }
      }
      this.$store.commit("resetServer")
      this.$store.commit("setSidebarVisibility", false)
    }
  },
  computed: {
    ...mapGetters(["serversByType", "send"]),
    container() {
      let server = this.serversByType("container");
      return server;
    },
    isContainerEmpty() {
      let server = this.serversByType("container");
      if (Object.keys(server).length === 0) {
        return true;
      }
      return (
        Object.keys(this.serversByType("container")[0].configuration)
          .length === 0
      );
    },
  },
  methods: {
    ...mapMutations([
      "setSelectedServer",
      "addServer",
      "setSidebarVisibility",
      "setFirewall",
    ]),
    openModal () {
      this.changeRoute = true
    },
    createNewContainer() {
      this.setSelectedServer({
        id: Date.now(),
        name: "Container",
        type: "container",
        configuration: {},
      });
      this.addServer();
    },
    createContainerIfEmpty() {
      if (this.container.length === 0) {
        this.createNewContainer();
      }
    },
    changeCreateContainerButton(){
      var length = Object.keys(this.serversByType("container")).length-1
      if (length >= 0 && Object.keys(this.serversByType("container")[length].configuration).length === 0){
        this.canCreateContainer = false
      } else {
        this.canCreateContainer = true
      }
    },
  },
  beforeUpdate() {
      this.createContainerIfEmpty();
  },
  mounted() {
      this.createContainerIfEmpty();
      this.changeCreateContainerButton()
  },
  
}
</script>